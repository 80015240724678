import { BLACK_1, BLACK_5 } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 999999;
  overflow: auto;
`;

export const Container = styled.div`
  position: relative;
  top: 18rem;
  margin: auto;
  width: 80rem;
  background-color: white;
  border-radius: 0.5rem;
  padding: 3rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    top: 0;
    border-radius: 0rem;
    padding: 0;
    width: 100%;
  }
`;

export const MobileCloseBtn = styled.div`
  display: none;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    background-color: black;
    padding: 1.8rem 2rem;
    & > img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;
export const CloseBtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & > img {
    width: 2.4rem;
    height: 2.4rem;

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: none;
  }
`;

export const NewsBox = styled.div`
  width: 100%;
  background-color: white;
  margin-top: 3rem;
  border-radius: 0.5rem;
  color: black;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 5.4rem;
  }

  & > div:nth-child(2) {
    display: flex;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${BLACK_5};

    & > div:nth-child(2n) {
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & > div:nth-child(3) {
    margin-top: 1.3rem;
    width: 100%;
    height: 41.3rem;

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
    }
  }

  & > div:nth-child(4) {
    margin-top: 1.5rem;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: ${BLACK_1};
    word-wrap: break-word;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    margin-top: 0rem;
    border-radius: 0rem;
    padding: 4rem 2rem;
    /* height: 100vh; */

    & > div:nth-child(1) {
      font-size: 2.4rem;
      line-height: 4.2rem;
    }

    & > div:nth-child(2) {
      margin-top: 0.5rem;
      display: flex;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: ${BLACK_5};

      & > div:nth-child(2n) {
        width: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & > div:nth-child(3) {
      margin-top: 2rem;
      width: 100%;
      height: 18.8rem;

      & > img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
      }
    }

    & > div:nth-child(4) {
      margin-top: 2rem;
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: ${BLACK_1};
    }
  }
`;
