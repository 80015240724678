import { useSelector } from "react-redux";
import UploadSupport from "../UploadSupport";
import { selectSupportModalData } from "redux/reducers/supportModalReducer";

const ModifySupport = () => {
  const supportData = useSelector(selectSupportModalData);
  return <UploadSupport modifyData={supportData} />;
};

export default ModifySupport;
