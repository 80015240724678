const PageDRightSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2260_15342)">
        <g opacity="0.6">
          <path d="M10 15L14.6667 10L10 5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6 15L10.6667 10L6 5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2260_15342">
          <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PageDRightSvg;
