const useDeleteStyle = () => {
  function useDeleteStyle(htmlTag) {
    if (!htmlTag) return;
    const styleToDelete = ["color", "font-size"];

    styleToDelete.forEach((style) => {
      const string = `${style}:\s*;?`;
      const regex = new RegExp(string, "g");
      htmlTag = htmlTag.replaceAll(regex, "");
    });
    return htmlTag;
  }
  return useDeleteStyle;
};

export default useDeleteStyle;
