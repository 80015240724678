import { AOS_PAY_URL, IOS_PAY_URL, IS_IOS } from "constants/pathConstant";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useEffect } from "react";

const SketchPay = () => {
  const nav = useNavigatePage();

  useEffect(() => {
    nav("/");
    if (IS_IOS) window.location.href = IOS_PAY_URL;
    else window.location.href = AOS_PAY_URL;
  }, []);
};

export default SketchPay;
