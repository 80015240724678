import styled from "styled-components";

export const Wrapper = styled.div`
  width: 120rem;
  height: fit-content;
  margin: auto;
  padding: 20rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 83rem;
  background-color: white;
  padding-top: 5rem;
`;

export const ContentBox1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
`;
export const TextBox = styled.div`
  font-size: 2.6rem;
  font-weight: 900;
`;
export const SearchBox = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SearchSelect = styled.select`
  width: 11rem;
  height: 4rem;
  border-radius: 0.6rem;
  border: 1px solid #d1d1d1;
  background: #f0f0f0;
  padding: 0 1rem;
  font-size: 1.3rem;
`;

export const SearchInput = styled.input`
  width: 21rem;
  height: 4rem;
  border-radius: 0.6rem;
  border: 1px solid #d1d1d1;
  background: #f0f0f0;
  padding: 0 1rem;
`;

export const SearchBtn = styled.div`
  width: 6rem;
  height: 4rem;
  border-radius: 0.6rem;
  border: 1px solid #d1d1d1;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
`;

export const ContentBox2 = styled.div`
  margin-top: 2rem;
  width: 100%;
`;
export const ContentNav = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  background: #f1f1f4;
  & > span {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    justify-content: center;
    &:nth-child(1) {
      width: 10rem;
      & > input {
        width: 2rem;
        height: 2rem;
        border-radius: 0.6rem;
        border: 1px solid #c8c8c8;
        background: #fff;
      }
    }
    &:nth-child(2) {
      width: 5rem;
    }
    &:nth-child(3) {
      padding-left: 10rem;
      justify-content: left;
      width: 30rem;
    }
    &:nth-child(4) {
      width: 15rem;
    }
    &:nth-child(5) {
      width: 15rem;
    }
    &:nth-child(6) {
      width: 15rem;
    }
    &:nth-child(7) {
      width: 15rem;
    }
    &:nth-child(8) {
      width: 10rem;
    }
    &:nth-child(9) {
      width: 10rem;
    }
    &:nth-child(10) {
      width: 10rem;
    }
  }
`;
export const MainContent = styled.div<IStyle>`
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid rgba(241, 241, 244, 1);
  display: flex;
  background-color: ${(props) => (props.checked ? "gray" : "white")};
  color: ${(props) => (props.checked ? "white" : "black")};
  font-weight: ${(props) => (props.checked ? "700" : "")};
  & > span {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    justify-content: center;
    &:nth-child(1) {
      width: 10rem;
      & > input {
        width: 2rem;
        height: 2rem;
        border-radius: 0.6rem;
        border: 1px solid #c8c8c8;
        background: #fff;
      }
    }
    &:nth-child(2) {
      width: 5rem;
    }
    &:nth-child(3) {
      padding-left: 3rem;
      justify-content: left;
      width: 30rem;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
    &:nth-child(4) {
      width: 15rem;
    }
    &:nth-child(5) {
      width: 15rem;
    }
    &:nth-child(6) {
      width: 15rem;
    }
    &:nth-child(7) {
      width: 15rem;
    }
    &:nth-child(8) {
      width: 10rem;
    }
    &:nth-child(9) {
      width: 10rem;
      &:hover {
        background-color: black;
        color: white;
        cursor: pointer;
      }
    }
    &:nth-child(10) {
      width: 10rem;
    }
  }
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  background-color: white;
`;

export const DeleteContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  font-size: 1.5rem;
`;

export const DeleteTextBox = styled.select`
  width: 17rem;
  height: 4rem;
  border-radius: 0.6rem;
  border: 1px solid #d1d1d1;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  font-size: 1.3rem;
`;
export const BottomBtn = styled.div`
  width: 10rem;
  height: 4rem;
  border-radius: 0.5rem;
  border: 1px solid #d1d1d1;
  background: #3d4359;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
`;

interface IStyle {
  checked: boolean;
}
