import { useQuery } from "react-query";
import Com1 from "./components/Com1";
import Com2 from "./components/Com2";
import Com4 from "./components/Com4";
import { Wrapper } from "./style";
import { axiosInstance } from "api";
import NoticeModal from "components/Modal/NoticeModal";
import { useCookies } from "react-cookie";

const Home = () => {
  const [cookies] = useCookies();

  const CheckCookieById = (id: string) => {
    return cookies["sketch_htm" + id];
  };

  const { data: notice, isSuccess } = useQuery<any>("notices", () => axiosInstance.get("/notices"));

  return (
    <Wrapper>
      {isSuccess && notice?.reverse().map((v: any) => !CheckCookieById(v.id) && <NoticeModal data={v} key={v.id} />)}
      <Com1 />
      <Com2 />
      <Com4 />
    </Wrapper>
  );
};

export default Home;
