import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18n";

export const languageSlice = createSlice({
  name: "language",

  initialState: {
    language: "en",
  },

  reducers: {
    changeLanguage: (state) => {
      let toChangeLang = state.language === "en" ? "ko" : "en";
      state.language = toChangeLang;
      i18n.changeLanguage(toChangeLang);
      localStorage.setItem("language", toChangeLang);
    },
    setLanguage: (state, action) => {
      let language = action.payload;
      state.language = language;
      i18n.changeLanguage(language);
    },
  },
});

export const { changeLanguage, setLanguage } = languageSlice.actions;

export const selectLanguage = (state: { language: { language: string } }) => state.language.language;

export default languageSlice.reducer;
