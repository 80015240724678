import { IMG_CDN_URL } from "constants/pathConstant";
import { InputBox, Wrapper } from "./style";
import { useState } from "react";
import { axiosInstance } from "api";
import { useTranslation } from "react-i18next";

const Com2 = () => {
  const { t } = useTranslation("languageNewVer");

  const [from, setFrom] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    const data = {
      from,
      message,
    };

    try {
      await axiosInstance.post("/mail/get", data);
      setMessage("");
      setFrom("");
      alert("메일이 성공적으로 전송되었습니다.");
    } catch {}
  };

  return (
    <Wrapper>
      <p>{t("S_500490")}</p>
      <InputBox>
        <input placeholder="me@acme.com" type="email" value={from} onChange={(e) => setFrom(e.target.value)} />
        <div onClick={() => setFrom("")}>
          <img src={`${IMG_CDN_URL}/file/e950e84fx_bottom.webp`} alt="input_remove_btn" />
        </div>
        <span>Your Email</span>
      </InputBox>
      <textarea onChange={(e) => setMessage(e.target.value)} value={message} placeholder="Your message" />
      <div onClick={handleSubmit}>{t("S_500500")}</div>
    </Wrapper>
  );
};

export default Com2;
