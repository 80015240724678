import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",

  initialState: {
    isLogin: false,
    role: "",
    name: "",
    id: 0,
  },

  reducers: {
    loginAndSetAdmin: (state, action) => {
      state.isLogin = true;
      sessionStorage.setItem("accessToken", action.payload.accessToken);
      state.name = action.payload.name;
      state.role = action.payload.role;
      state.id = action.payload.id;
    },
    logoutAdmin: (state) => {
      state.isLogin = false;
      sessionStorage.removeItem("accessToken");
      state.name = "";
      state.role = "";
      state.id = 0;
    },
  },
});

export const { loginAndSetAdmin, logoutAdmin } = adminSlice.actions;

export const selectAdminIsLogin = (state: { admin: { isLogin: boolean } }) => state.admin.isLogin;
export const selectAdminRole = (state: { admin: { role: string } }) => state.admin.role;
export const selectAdminName = (state: { admin: { name: string } }) => state.admin.name;

export default adminSlice.reducer;
