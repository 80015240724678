import Com1 from "./Com1";

const Support = () => {
  return (
    <div>
      <Com1></Com1>
    </div>
  );
};

export default Support;
