import { BLACK_9, BLACK_c } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH, PC_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 5rem;
  }
`;

export const TextBox1 = styled.div`
  font-weight: 700;
  font-size: 5.2rem;
  line-height: 9.2rem;
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #363636;
  padding-bottom: 4rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    flex-direction: column;
    border-bottom: none;
    height: auto;
  }
`;

export const TopFirstBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  cursor: pointer;
  width: 87rem;

  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) and (max-width: ${PC_LAYOUT_LENGTH}) {
    width: 80rem;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    border-bottom: 1px solid #363636;
    padding-bottom: 3rem;
  }
`;

export const TopImgBox = styled.div`
  width: 100%;
  height: 49.938rem;

  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) and (max-width: ${PC_LAYOUT_LENGTH}) {
    height: 45rem;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    height: 18.8rem;
  }
`;

export const TextBox = styled.div`
  width: 100%;
  height: 12.2rem;
  display: flex;
  flex-direction: column;

  & > :nth-child(1) {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & > :nth-child(2) {
    font-weight: 300;
    line-height: 2.8rem;
    color: ${BLACK_c};
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.6rem !important;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1; // 원하는 라인수
    -webkit-box-orient: vertical;
  }

  & > :nth-child(3) {
    margin-top: 2rem;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.4rem;
    display: flex;
    color: ${BLACK_9};

    > div {
      &:nth-child(2n) {
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    /* height: auto; */
    padding-right: 2.2rem;

    & > :nth-child(1) {
      font-size: 2.4rem;
      line-height: 4.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 원하는 라인수
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    & > :nth-child(2) {
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: ${BLACK_c};
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 원하는 라인수
      -webkit-box-orient: vertical;
      white-space: normal;

      & > :not(:nth-child(1)) {
        display: none;
      }
    }

    & > :nth-child(3) {
      margin-top: 1.5rem;
      font-size: 1.2rem;
      line-height: 2.2rem;
      display: flex;
      color: ${BLACK_9};

      > div {
        &:nth-child(2n) {
          width: 1.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

export const TopSecondBox = styled.div`
  display: flex;
  gap: 5rem;
  flex-direction: column;
  // wrapper

  & > div {
    width: 28.3rem;
    height: 29.2rem;
    border: 1px solid #363636;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 3rem;

    & > div {
      width: 100%;
      height: auto;
      padding-bottom: 2rem;
    }

    & > div > div:nth-child(1) {
      height: 18.8rem;
    }
  }
`;
export const BottomContainer = styled.div`
  margin-top: 7.7rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    flex-direction: column;
    margin-top: 2rem;
  }
`;

export const ContentBox = styled.div`
  width: calc((100% - 4rem) / 3);
  height: 35rem;
  border: 1px solid #363636;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    height: auto;
    padding-bottom: 2rem;
  }
`;

export const ImgBox1 = styled.div`
  width: 100%;
  aspect-ratio: 16/9;

  & > img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
`;

export const TextBox2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  & > :nth-child(1) {
    font-weight: 700;
    font-size: 2rem;
    line-height: 3.6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > :nth-child(2) {
    font-weight: 300;
    line-height: 2.4rem;
    color: ${BLACK_c};
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95%;
    font-size: 1.4rem;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1; // 원하는 라인수
    -webkit-box-orient: vertical;
  }

  & > :nth-child(3) {
    margin-top: 1.5rem;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 2.2rem;
    display: flex;
    color: ${BLACK_9};

    > div {
      &:nth-child(2n) {
        width: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    /* height: auto; */
    padding-right: 2.2rem;

    & > :nth-child(1) {
      font-size: 2.4rem;
      line-height: 4.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 원하는 라인수
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    & > :nth-child(2) {
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: ${BLACK_c};
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 원하는 라인수
      -webkit-box-orient: vertical;
      white-space: normal;

      & > :not(:nth-child(1)) {
        display: none;
      }
    }

    & > :nth-child(3) {
      margin-top: 1.5rem;
      font-size: 1.2rem;
      line-height: 2.2rem;
      display: flex;
      color: ${BLACK_9};

      > div {
        &:nth-child(2n) {
          width: 1.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

export const Title = styled.p`
  font-size: 5.2rem;
  font-weight: 700;
  margin: 3rem 0 2.8rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 2.8rem;
  }
`;
