import { useDispatch, useSelector } from "react-redux";
import { AdminName, Btn, Content } from "./style";
import { logoutAdmin, selectAdminIsLogin, selectAdminName } from "redux/reducers/adminReducer";
import { useNavigatePage } from "hooks/useNavigatePage";

const AdminPcMenuCom = () => {
  const nav = useNavigatePage();
  const dispatch = useDispatch();

  const name = useSelector(selectAdminName);
  const isLogin = useSelector(selectAdminIsLogin);

  const loginHandler = () => {
    nav("/admin-sketch/login");
  };

  const logoutHandler = () => {
    dispatch(logoutAdmin());
    nav("/admin-sketch/login");
  };
  return (
    <Content>
      <AdminName>{name}</AdminName>
      {isLogin ? <Btn onClick={logoutHandler}>Logout</Btn> : <Btn onClick={loginHandler}>Login</Btn>}
    </Content>
  );
};

export default AdminPcMenuCom;
