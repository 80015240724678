import { useTranslation } from "react-i18next";
import { Background, Title, Wrapper, TextBox, TextBoxPhone, DownloadBtn } from "./style";
import { useSelector } from "react-redux";
import { selectLanguage } from "redux/reducers/languageReducer";

const Com2 = () => {
  const language = useSelector(selectLanguage);
  const { t } = useTranslation("languageNewVer");

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = language === "en" ? "/pdf/sketch_whitepaper_v.0.8.1.pdf" : "/pdf/sketch_whitepaper_ko_v.0.8.1.pdf"; // PDF 파일 경로 설정
    link.download = language === "en" ? "sketch_whitepaper_v.0.8.1.pdf" : "sketch_whitepaper_ko_v.0.8.1.pdf"; // 다운로드될 파일 이름 설정
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Wrapper>
      <Title $isKor={language === "ko"}>
        <p>{t("S_500060")}</p>
        &nbsp;
        <p>{t("S_500061")}</p>
      </Title>
      <TextBoxPhone>
        <p>{t("S_500070")}</p>
        <div onClick={handleDownload}>{t("S_500080")}</div>
      </TextBoxPhone>
      <Background lang={language === "en"}>
        <div>
          <TextBox lang={language === "en"}>{t("S_500070")}</TextBox>
          <DownloadBtn onClick={handleDownload}>{t("S_500080")}</DownloadBtn>
        </div>
      </Background>
    </Wrapper>
  );
};

export default Com2;
