import { useEffect, useRef, useState } from "react";
import { Container, UploadBtn, UploadLabel, Wrapper } from "./style";
import { useMutation } from "react-query";
import { axiosInstanceAdmin } from "api";
import { useLoginCheck } from "hooks/useLoginCheck";
import { IMG_CDN_URL } from "constants/pathConstant";
const UploadImage = () => {
  const imgRef = useRef();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(null);

  // 이미지 미리보기
  const imageHandler = (file) => {
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      imgRef.current.src = reader.result;
    };
  };

  // 로그인 체크
  const loginCheck = useLoginCheck();
  useEffect(() => {
    loginCheck();
  }, []);

  // 이미지 업로드 핸들러
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const { mutate } = useMutation((data) => axiosInstanceAdmin.post("/s3/resource", data, config), {
    onSuccess: (res) => {
      setUrl(`${IMG_CDN_URL}/${res.key}`);
    },
  });
  const uploadHandler = () => {
    const formData = new FormData();
    formData.append("file", file);
    mutate(formData);
  };

  return (
    <Wrapper>
      <Container>
        <input type="file" onChange={(e) => imageHandler(e.target.files[0])} id="image" />
        <UploadLabel htmlFor="image">이미지 선택</UploadLabel>
        <img loading="lazy" src="" alt="이미지 미리보기" ref={imgRef} />
        <UploadBtn onClick={uploadHandler}>S3 서버에 올리기</UploadBtn>
        {url !== null ? <div>이미지 url : {url}</div> : <></>}
      </Container>
    </Wrapper>
  );
};

export default UploadImage;
