import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 50rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  font-size: 2rem;
  padding: 30rem;

  & > input {
    display: none;
  }
`;

export const UploadBtn = styled.div`
  width: 20rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  border: 1px solid black;
  text-align: center;
  border-radius: 1rem;

  &:hover {
    color: white;
    background-color: black;
    cursor: pointer;
  }
`;

export const UploadLabel = styled.label`
  width: 20rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  border: 1px solid black;
  border-radius: 1rem;

  &:hover {
    color: white;
    background-color: black;
    cursor: pointer;
  }
`;
