import { axiosInstanceAdmin } from "api";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import { useLoginCheck } from "hooks/useLoginCheck";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useMutation, useQueryClient } from "react-query";

import PreviewNewsMobile from "../Preview/Mobile/PreviewNewsMobile";
import PreviewNewsPc from "../Preview/Pc/PreviewNewsPc";
import {
  BottomBtn,
  BottomWrapper,
  ContentWrapper,
  Modal,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  ThumbnailWrapper,
  Title,
  UploadImage,
  UploadImageLabel,
  Wrapper,
  SelectBoxWrapper,
} from "./style";
import { IMG_CDN_URL } from "constants/pathConstant";
import { dateFormat } from "components/dateFormat";
const UploadNews = ({ modifyData }) => {
  const loginCheck = useLoginCheck();
  const nav = useNavigatePage();
  const queryClient = useQueryClient();

  const regionRef = useRef();
  const regionInput = useRef();
  const dateRef = useRef();
  const dateInput = useRef();
  const imgRef = useRef(null);
  const imgRefKr = useRef(null);
  const activeRef = useRef();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateKr, setEditorStateKr] = useState(EditorState.createEmpty());
  const [htmlString, setHtmlString] = useState("");
  const [htmlStringKr, setHtmlStringKr] = useState("");
  const [title, setTitle] = useState("");
  const [titleKr, setTitleKr] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [imgSrcKr, setImgSrcKr] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [region, setRegion] = useState("Seoul");
  const [date, setDate] = useState(dateFormat(new Date(), "-", 0));
  const [modal, setModal] = useState(false);
  const [newsId, setNewsId] = useState();
  const [isDateDisabled, setIsDateDisabled] = useState(false);
  const [isRegionDisabled, setIsRegionDisabled] = useState(false);
  const [isImgEngChange, setIsImgEngChange] = useState(false);
  const [isImgKrChange, setIsImgKrChange] = useState(false);

  const thumbnailSrcKr = isImgKrChange ? imgSrcKr : `${IMG_CDN_URL}/${imgSrcKr}`;
  const thumbnailSrcEng = isImgEngChange ? imgSrc : `${IMG_CDN_URL}/${imgSrc}`;

  useEffect(() => {
    // 로그인 체크
    if (!loginCheck) return;
    loginCheck();
  }, []);

  useEffect(() => {
    if (!modifyData) {
      regionInput.current.style.display = "none";
      dateInput.current.style.display = "none";
    } else {
      if (!modifyData.id) return;
      setNewsId(modifyData.id);
      // 타이틀 초기값
      setTitleKr(modifyData.titleKr);
      setTitle(modifyData.titleEng);

      // 공개여부 초기값
      setIsActive(modifyData.isActive);
      activeRef.current.value = modifyData.isActive;

      // 날짜 초기값
      dateInput.current.style.display = "block";
      dateRef.current.value = dateFormat(modifyData.date);
      dateInput.current.value = dateFormat(modifyData.date);
      setDate(dateFormat(modifyData.date));

      setIsDateDisabled(true);

      // 지역 초기값
      if (modifyData.region !== "Seoul") {
        regionRef.current.value = modifyData.region;
        regionInput.current.style.display = "block";
        regionInput.current.value = modifyData.region;
        setRegion(modifyData.region);
        setIsRegionDisabled(true);
      }

      // 에디터 초기값
      setHtmlString(modifyData.contentEng);
      setHtmlStringKr(modifyData.contentKr);
      setEditorState(
        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(modifyData.contentEng))),
      );
      setEditorStateKr(
        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(modifyData.contentKr))),
      );

      // 이미지 초기값
      setImgSrc(modifyData.imgPathEng);
      setImgSrcKr(modifyData.imgPathKr);
      setIsImgEngChange(false);
      setIsImgKrChange(false);
    }
  }, [modifyData]);

  const updateTextDescription = async (state, isKorean) => {
    // 에디터에 글쓰기 위한 state
    isKorean ? setEditorStateKr(state) : setEditorState(state);

    let html = draftjsToHtml(
      convertToRaw(isKorean ? editorStateKr.getCurrentContent() : editorState.getCurrentContent()),
    );

    // 불완전한 <p></p> 태그 수정
    html = html.replace(/<p><\/p>/g, "<br />");

    // html 기본 폰트사이즈 1rem, px을 rem으로 바꾸는 정규식
    let matchWord = html.match(/font-size: \d{1,2}px;/gi);
    if (matchWord) {
      matchWord = matchWord.map((font) => {
        const int = font.match(/\d{1,2}/g);
        return `font-size: ${int / 10}rem`;
      });

      html = html.replace(/font-size: \d{1,2}px;/gi, matchWord);
    }
    // ---------------------이슈 : &nbsp; 가 임의로 사라져버려서 띄어쓰기는 연속 2번이상 불가
    isKorean ? setHtmlStringKr(html) : setHtmlString(html);
  };

  // 이미지 업로드 핸들러
  const imgUploadHandler = (isKo, imgRef) => {
    const file = imgRef.current.files[0];
    // input에 안담기면 return
    if (!file) return;
    // 5MB보다 큰경우 return
    if (file.size / 1000 / 1000 > 5) {
      alert("파일 크기는 최대 5MB입니다.");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (isKo) {
        setImgSrcKr(reader.result);
        setIsImgKrChange(true);
      } else {
        setImgSrc(reader.result);
        setIsImgEngChange(true);
      }
    };
  };

  // region 선택 select handler
  const regionHandler = () => {
    if (!regionInput.current.style.display || regionInput.current.style.display === "none") {
      regionInput.current.style.display = "block";
      setRegion(regionInput.current.value);
    } else {
      regionInput.current.style.display = "none";
      setRegion("Seoul");
      setIsRegionDisabled(false);
    }
  };
  // date 선택 select handler
  const dateHandler = () => {
    if (!dateInput.current.style.display || dateInput.current.style.display === "none") {
      dateInput.current.style.display = "block";
      setDate(new Date(dateInput.current.value));
    } else {
      dateInput.current.style.display = "none";
      setDate(new Date());
      setIsDateDisabled(false);
    }
  };

  // 게시글 업로드 handler
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const { mutate } = useMutation((data) => axiosInstanceAdmin.post("/news", data, config), {
    onSuccess: () => queryClient.invalidateQueries(["manage_news0"]),
  });

  const { mutate: updateMutate } = useMutation((data) =>
    axiosInstanceAdmin.put("/news", data, config, {
      onSuccess: () => queryClient.invalidateQueries(["manage_news_edit0"]),
    }),
  );

  const upLoadHandler = () => {
    if (!title || !htmlString || !region || !titleKr || !htmlStringKr) {
      alert("빠진 내용 없이 다 채워주세요");
      return;
    }

    const formData = new FormData();
    const content = htmlString;
    const contentKr = htmlStringKr;
    const data = {
      id: modifyData && newsId,
      titleEng: title,
      contentEng: content,
      region: regionRef.current.value,
      isActive,
      titleKr,
      contentKr,
      isImgEngChange,
      isImgKrChange,
      imgSrc,
      imgSrcKr,
    };

    const fixHourDate = dateFormat(date, "-", -9);

    isImgEngChange && formData.append("imgEng", imgRef.current.files[0]);
    isImgKrChange && formData.append("imgKr", imgRefKr.current.files[0]);
    formData.append("data", JSON.stringify(data));
    formData.append("date", fixHourDate);

    if (modifyData) {
      // 수정할 경우
      if (window.confirm("수정하시겠습니까?")) {
        updateMutate(formData);
        nav("/admin-sketch/news/manage/0");
      }
    } else {
      // 새 글을 작성하는 경우
      if (window.confirm("등록하시겠습니까?")) {
        mutate(formData);
        nav("/admin-sketch/news/manage/0");
      }
    }
  };

  const previewHandler = () => {
    setModal(true);
  };

  const modalCloseHandler = (e) => {
    if (e.target === e.currentTarget) {
      setModal(false);
    }
  };

  return (
    <Wrapper>
      <Name>News & Event</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          작성하기
        </span>
        <div>
          <SelectBoxWrapper>
            <div>작성일</div>
            <SelectWrapper>
              <select name="date" id="date" ref={dateRef} onChange={dateHandler}>
                <option value={new Date()}>{dateFormat(new Date(), "-", 0).substring(0, 16)}</option>
                <option value={date} disabled={isDateDisabled}>
                  직접입력
                </option>
              </select>
              <input
                type="datetime-local"
                ref={dateInput}
                onChange={(e) => {
                  setDate(dateFormat(e.target.value, "-", 0));
                }}
              />
            </SelectWrapper>
          </SelectBoxWrapper>
          <div>
            <SelectBoxWrapper>
              <div>위치</div>
              <SelectWrapper>
                <select name="region" id="region" ref={regionRef} onChange={regionHandler}>
                  <option value="Seoul">Seoul</option>
                  <option value={region} disabled={isRegionDisabled}>
                    직접입력
                  </option>
                </select>
                <input type="text" onChange={(e) => setRegion(e.target.value)} ref={regionInput} />
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>공개 여부</div>
              <SelectWrapper>
                <select onChange={(e) => setIsActive(e.target.value)} ref={activeRef}>
                  <option value={true}>공개</option>
                  <option value={false}>비공개</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>
        </div>
      </TableWrapper>
      <div>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(한국어)
          </span>
          <div>
            <Title
              onChange={(e) => setTitleKr(e.target.value)}
              defaultValue={modifyData?.titleKr || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorStateKr}
            onEditorStateChange={(state) => updateTextDescription(state, true)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "80rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(영어)
          </span>
          <div>
            <Title
              onChange={(e) => setTitle(e.target.value)}
              defaultValue={modifyData?.titleEng || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorState}
            onEditorStateChange={(state) => updateTextDescription(state, false)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "80rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
      </div>

      <div>
        <ThumbnailWrapper>
          <div>한국어 썸네일</div>
          <div>
            <img src={thumbnailSrcKr} alt="thumbnail" />
            <UploadImage
              type="file"
              id="imgKr"
              onChange={() => imgUploadHandler(true, imgRefKr)}
              accept="image/*"
              ref={imgRefKr}
            />
            <UploadImageLabel htmlFor="imgKr">파일 선택</UploadImageLabel>
          </div>
        </ThumbnailWrapper>
        <ThumbnailWrapper>
          <div>영어 썸네일</div>
          <div>
            <img src={thumbnailSrcEng} alt="thumbnail" />
            <UploadImage
              type="file"
              id="img"
              onChange={() => imgUploadHandler(false, imgRef)}
              accept="image/*"
              ref={imgRef}
            />
            <UploadImageLabel htmlFor="img">파일 선택</UploadImageLabel>
          </div>
        </ThumbnailWrapper>
      </div>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav(-1)}>이전</BottomBtn>
          <BottomBtn onClick={() => nav("/admin-sketch/news/manage/0")}>목록</BottomBtn>
        </div>
        <div>
          <BottomBtn onClick={previewHandler} bgColor={"#727582"} fontColor={"white"}>
            미리보기
          </BottomBtn>
          <BottomBtn onClick={upLoadHandler} bgColor={"#3D4359"} fontColor={"white"}>
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {/* 미리보기 모달 */}
      {modal && (
        <Modal onClick={modalCloseHandler}>
          <PreviewNewsPc
            title={title}
            date={date}
            region={region}
            imgSrc={thumbnailSrcEng}
            htmlString={htmlString}
            imgSrcKr={thumbnailSrcKr}
            htmlStringKr={htmlStringKr}
            titleKr={titleKr}
          />
          <PreviewNewsMobile
            title={title}
            date={date}
            region={region}
            imgSrc={thumbnailSrcEng}
            htmlString={htmlString}
            imgSrcKr={thumbnailSrcKr}
            htmlStringKr={htmlStringKr}
            titleKr={titleKr}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default UploadNews;
