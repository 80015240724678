import { BLACK_a, MAIN_GREEN } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const PaginationWrapper = styled.div`
  margin-top: 8rem;
  margin-bottom: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 6rem;
    margin-bottom: 10rem;
    gap: 1.5rem;
  }
`;

export const BtnWrapper = styled.div<IStyle>`
  display: ${(props) => (props.pageLength && props.pageLength <= 10 ? "none" : "flex")};

  & > div:nth-child(2) {
    margin-left: 1rem;
  }

  & > div > svg {
    cursor: pointer;

    &:hover {
      > g > g > path {
        stroke: ${MAIN_GREEN};
      }

      > g > path {
        stroke: ${MAIN_GREEN};
      }
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    & > img:nth-child(1) {
      margin-right: 1rem;
    }

    & > img:nth-child(2) {
      margin-left: 1rem;
    }
  }
`;

export const PageWrapper = styled.div`
  display: flex;
`;

export const Page = styled.span<IStyle>`
  width: 3.5rem;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: ${(props) => (props.isSelected ? MAIN_GREEN : BLACK_a)};
  font-weight: ${(props) => (props.isSelected ? 700 : 300)};
  text-align: center;
  cursor: ${(props) => (props.isSelected ? "defalut" : "pointer")};

  &:hover {
    color: ${MAIN_GREEN};
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 3.5rem;
    font-weight: 400;
    line-height: 130%;
    text-align: center;
  }
`;

interface IStyle {
  pageLength?: number;
  isSelected?: boolean;
}
