import { useTranslation } from "react-i18next";
import { FakeWrapper, Wrapper } from "./style";

const Banner = () => {
  const { t } = useTranslation("languageNewVer");

  return (
    <>
      <Wrapper>
        <p>
          <span>{t("S_500010")}</span>
          <a href="https://www.probit.com/app/exchange/SKETCH-USDT" target="_blank" rel="noreferrer">
            <span>{t("S_500011")}</span>
          </a>
          <span>{t("S_500012")}</span>
        </p>
      </Wrapper>
      <FakeWrapper></FakeWrapper>
    </>
  );
};

export default Banner;
