// Primary Colors
export const MAIN_GREEN = "#21F650";
export const DARK_GREEN = "#1ECB0F";
export const GREEN_90 = "#1EDD48";
export const GREEN_80 = "#1AC540 ";
export const GREEN_70 = "#17AC38";
export const GREEN_60 = "#149430";
export const GREEN_50 = "#117B28";
export const GREEN_40 = "#0D6220";
export const GREEN_30 = "#0A4A18";
export const GREEN_20 = "#073110";
export const GREEN_10 = "#031908";

// Black Colors
export const BLACK_0 = "#000000";
export const BLACK_1 = "#111111";
export const BLACK_3 = "#333333";
export const BLACK_5 = "#555555";
export const BLACK_7 = "#777777";
export const BLACK_9 = "#999999";
export const BLACK_a = "#aaaaaa";
export const BLACK_c = "#cccccc";
export const BLACK_d = "#dddddd";
export const BLACK_e = "#eeeeee";

// White Colors
export const WHITE = "#ffffff";

// Warning
export const RED = "#FF3E34";

// Sub Colors
export const ORANGE = "#FFC121";
export const PURPLE = "#4F5CD9";
export const GREEN = "#2AAE81";

export const FONTS = {
  M: "AppleSDGothicNeoM",
  B: "AppleSDGothicNeoB",
  EB: "AppleSDGothicNeoEB",
  H: "AppleSDGothicNeoH",
  RU: "Russo One",
  S8: "SCDream8",
  S9: "SCDream9",
};
