import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const PaginationWrapper = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 6rem;
    margin-bottom: 10rem;
    gap: 1.5rem;
  }
`;
export const BtnWrapper = styled.div<IStyle>`
  display: ${(props) => (props.pageLength !== undefined && props.pageLength <= 10 ? "none" : "flex")};
  gap: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  & > span:hover {
    color: gray;
    cursor: pointer;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
`;
export const Page = styled.span<IStyle>`
  width: 3.5rem;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: ${(props) => (props.isSelected ? "black" : "gray")};
  font-weight: ${(props) => (props.isSelected ? 700 : 300)};
  text-align: center;
  cursor: ${(props) => (props.isSelected ? "defalut" : "pointer")};

  &:hover {
    color: black;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 3.5rem;
    font-weight: 400;
    line-height: 130%;
    text-align: center;
  }
`;

interface IStyle {
  pageLength?: number;
  isSelected?: boolean;
}
