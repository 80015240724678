import NewsModal from "components/Modal/NewsModal";
import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { setLanguage } from "redux/reducers/languageReducer";
import { selectActiveNewsModal } from "redux/reducers/newsModalReducer";
import { selectActiveSupportModal } from "redux/reducers/supportModalReducer";
import SupportModal from "components/Modal/SupportModal";
import Router from "router";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dispatch) return;

    // set language
    (async () => {
      const language = localStorage.getItem("language");
      if (language) {
        dispatch(setLanguage(language));
      } else {
        dispatch(setLanguage("ko"));
      }
    })();
  }, [dispatch]);

  // news modal 띄우기
  const newsModalState = useSelector(selectActiveNewsModal);
  const supportModalState = useSelector(selectActiveSupportModal);

  // 리액트 쿼리 사용을 위한 client 정의
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <BrowserRouter>
          {newsModalState && <NewsModal></NewsModal>}
          {supportModalState && <SupportModal></SupportModal>}
          <Router />
        </BrowserRouter>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
