import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import { IMG_CDN_URL } from "constants/pathConstant";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Background = styled.div`
  width: 100%;
  height: 110rem;
  background: url(${IMG_CDN_URL}/file/70804ab7bg_pc.webp) no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    background: url(${IMG_CDN_URL}/file/dada7c87bg_phone.webp) no-repeat;

    background-size: contain;
    height: 62rem;
  }
`;

export const Slogan = styled.div`
  width: 100%;
  height: auto;
  font-size: 9.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18rem;
  letter-spacing: 0.1rem;
  line-height: 10.8rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 3.2rem;
    padding-top: 12rem;
    line-height: 4.2rem;

    > p {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  > p {
    font-family: ${FONTS.H};

    > span {
      font-family: ${FONTS.H};
    }

    > :nth-last-child(1) {
      color: #2aabee;
    }
  }

  > :nth-last-child(1) {
    color: #2aabee;
  }
`;

export const SubTitle = styled.div`
  margin-top: 2rem;
  font-size: 3.2rem;
  color: #d9d9d9;
  text-align: center;
  line-height: 3.4rem;

  > p {
    font-family: ${FONTS.B};
    letter-spacing: 0.1rem;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 1.4rem;
    margin-top: 5rem;

    > p {
      display: flex;
      flex-direction: column;
      line-height: 1.3;
    }
  }
`;

export const LearnMoreBtn = styled.div`
  border-radius: 10rem;
  font-size: 3.2rem;
  padding: 2rem;
  border: 1px solid #b1e5e2;
  font-weight: 500;
  color: #fff;
  margin-top: 6rem;
  cursor: pointer;
  font-family: ${FONTS.B};
  background: rgba(177, 229, 226, 0.3);

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 1.4rem;
    padding: 1rem 2rem;
    margin-top: 3rem;
  }
`;

export const QrContainer = styled.div`
  display: flex;
  margin-top: 5rem;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    & > p {
      font-size: 2.4rem;
      font-weight: 500;
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: none;
  }
`;

export const UrlContainer = styled.div`
  display: flex;
  background: #000;
  padding: 2rem;
  gap: 2rem;

  & > :nth-child(1) {
    width: 12rem;
    height: 12rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    > img {
      width: 90%;
      height: 90%;
    }
  }

  & > :nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      width: 18rem;
      height: 5.5rem;

      > a > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
  }
`;

export const UrlContainerPhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  gap: 1em;

  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 1rem;
  }

  & > :nth-child(1) {
    width: 3.2rem;
    height: 3.2rem;

    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: none;
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > :nth-child(2) {
    font-family: ${FONTS.B};
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      font-size: 2.4rem;
      color: #a4a3a6;
      font-weight: 500;
    }
  }

  > :nth-child(3) {
    display: flex;
    gap: 2rem;

    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: none;
    }

    > div {
      border-radius: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 1rem 2rem;
      color: #000;

      & > :nth-child(1) {
        font-family: ${FONTS.B};
        font-size: 1.4rem;
        color: white;
      }

      & > div img {
        width: 12rem;
      }
    }
  }
`;
