import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const SfuelLieStyle = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20rem;
    font-size: 5rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-top: 0rem;
      font-size: 1.5rem;
    }
  `,

  StartContainer: styled.div`
    display: flex;
    gap: 5rem;
    & > input {
      height: 5rem;
    }
    margin-bottom: 5rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      gap: 2rem;
      & > input {
        height: 1rem;
      }
      margin-bottom: 2rem;
    }
  `,

  ColumnHeader: styled.div`
    display: flex;
    gap: 4rem;
    margin-bottom: 3rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-bottom: 1rem;
    }
  `,

  ColumnContainer: styled.div`
    display: flex;
    gap: 5rem;
    & > input {
      width: 20rem;
      height: 3rem;
      font-size: 3rem;
    }

    margin-bottom: 3rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      gap: 1rem;
      & > input {
        width: 7rem;
        height: 3rem;
        font-size: 1.5rem;
      }
      margin-bottom: 1rem;
    }
  `,

  CalBtn: styled.button`
    width: 10rem;
    height: 4rem;
    border-radius: 2rem;
    margin-bottom: 10rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-bottom: 1rem;
    }
  `,
};
