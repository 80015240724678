import { Outlet } from "react-router";
import AdminHeader from "./AdminHeader/AdminHeader";
import { AdminLayOutContainer } from "../style";

const AdminLayout = () => {
  return (
    <div style={{ height: "100%" }}>
      <AdminHeader />
      <AdminLayOutContainer>
        {/* Pages content : Begin */}
        <Outlet />
        {/* Pages content : End */}
      </AdminLayOutContainer>
    </div>
  );
};

export default AdminLayout;
