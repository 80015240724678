import React, { useEffect, useRef, useState } from "react";
import { DetailLie } from "./DetailLie";
import { SfuelLieStyle } from "./SfuelLie.style";

export const SfuelLie = () => {
  const [peopleLen, setPeopleLen] = useState([]);
  const [totalInfo, setTotalInfo] = useState({});
  const [personalPrice, setPersonalPrice] = useState(0);
  const [personalCal, setPersonalCal] = useState([]);
  const [resultPayment, setResultPayment] = useState([]);
  const peopleLenRef = useRef(null);

  const startCal = () => {
    const peopleLen = Number(peopleLenRef.current.value);
    if (peopleLen === 0) {
      setPeopleLen([]);
      clearEverything();
    } else {
      setPeopleLen(new Array(peopleLen).fill(0));
      clearEverything();
    }
  };

  const calculate = () => {
    let totalSum = 0;
    const values = Object.values(totalInfo);
    values.forEach((value) => (totalSum += value[1]));

    setPersonalPrice(totalSum / peopleLen.length);
  };

  useEffect(() => {
    if (personalPrice === 0) return;

    const values = Object.values(totalInfo);

    const tempArr = [];
    values.forEach((person) => {
      tempArr.push([person[0], Number(person[1] - personalPrice)]);
    });

    const personalCal = [...tempArr];
    setPersonalCal(personalCal);

    const tempArr2 = [];
    tempArr.map((value, index) => {
      if (value[1] < 0) {
        let payAmount = Math.abs(value[1]);
        for (let i = 0; i < tempArr.length; i++) {
          if (tempArr[i][1] > 0) {
            const minus = tempArr[i][1] - payAmount;
            if (minus >= 0) {
              tempArr[i][1] = minus;
              tempArr[index][1] = 0;
              tempArr2.push([value[0], tempArr[i][0], payAmount]);
              break;
            } else {
              tempArr2.push([value[0], tempArr[i][0], tempArr[i][1]]);
              tempArr[i][1] = 0;
            }
          }
        }
      }
    });

    console.log(tempArr2);
    setResultPayment(tempArr2);
  }, [personalPrice]);

  const clearEverything = () => {
    setTotalInfo({});
    setPersonalPrice(0);
    setPersonalCal([]);
  };
  return (
    <SfuelLieStyle.Wrapper>
      <SfuelLieStyle.StartContainer>
        <div>몇명?</div>
        <input type="number" ref={peopleLenRef} />
        <button onClick={startCal}>정산 시작!</button>
      </SfuelLieStyle.StartContainer>
      <SfuelLieStyle.ColumnHeader>
        <div>이름(중복 불가)</div>
        <div>쓴 금액</div>
      </SfuelLieStyle.ColumnHeader>
      {peopleLen.map((v, index) => (
        <DetailLie key={index} totalInfo={totalInfo} setTotalInfo={setTotalInfo} />
      ))}

      <SfuelLieStyle.CalBtn onClick={calculate}>정산!</SfuelLieStyle.CalBtn>

      <div>
        {resultPayment.map((result, index) => (
          <div key={index}>
            {result[0]} {"->"} {result[1]} 에게 {result[2]}원 송금
          </div>
        ))}
      </div>
    </SfuelLieStyle.Wrapper>
  );
};
