import React, { useState } from "react";
import { OpenedUrl, SfuelContainer } from "./Sfuel.style";

export const SfuelDetail = ({ arr, index, isDoneArr }) => {
  const openWindow = (value) => {
    if (value.trim()) {
      window.open("https://www.sfuelstation.com/claim-sfuel/" + value);
      setIsClick(true);
    }
  };
  const [isClick, setIsClick] = useState(false);

  return (
    <SfuelContainer key={arr}>
      <input type="text" defaultValue={arr} />
      <button onClick={() => openWindow(arr)}>열기</button>
      <OpenedUrl clicked={isClick}></OpenedUrl>
      <div style={{ background: isDoneArr[index] ? "blue" : "red", width: "2rem", height: "2rem" }}></div>
    </SfuelContainer>
  );
};
