export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1";
export const ADMIN_BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1/admins";
export const SKETCH_SCAN_URL = "https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/";
export const IMG_CDN_URL = "https://cdn.sketchproject.io";
export const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const AOS_PAY_URL = "https://play.google.com/store/apps/details?id=com.sketch.pay";
export const AOS_WALLET_URL = "https://play.google.com/store/apps/details?id=com.sketch.wallet";
export const IOS_PAY_URL =
  "https://apps.apple.com/kr/app/%EC%8A%A4%EC%BC%80%EC%B9%98%ED%8E%98%EC%9D%B4-sketch-pay-bitsafe/id1599944896";
export const IOS_WALLET_URL =
  "https://apps.apple.com/kr/app/%EC%8A%A4%EC%BC%80%EC%B9%98%EC%9B%94%EB%A0%9B-sketch-wallet-bitsafe/id1597866658";
