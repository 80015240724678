import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 14rem 0 15rem;
  gap: 1rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin: 6rem 0 5rem;
  }

  & > :nth-child(2) {
    font-size: 2rem;
    color: #a4a3a6;
  }

  & > :nth-child(6) {
    font-size: 1.4rem;
    color: #a4a3a6;
  }
`;

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  & > :nth-child(1) {
    width: 4.4rem;
    height: 4.4rem;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  & > :nth-child(2) {
    width: 8.5rem;
    height: 2.4rem;

    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const SiteMapBox = styled.div`
  display: none;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    & > p {
      font-size: 1.4rem;
      font-family: ${FONTS.B};
      margin-top: 3rem;
    }

    span {
      font-size: 1.2rem;
      font-family: ${FONTS.B};
      color: #a4a3a6;
      cursor: pointer;
    }

    & > div {
      display: flex;
      gap: 16rem;

      > div {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }
  }
`;

export const Social = styled.p<IStyle>`
  display: none;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: block;
    font-size: 1.4rem;
    font-family: ${FONTS.B};
    margin-top: 3rem;
    margin-left: 0.5rem;
    letter-spacing: ${(props) => (props.lang ? "" : "0.5rem")};
  }
`;

interface IStyle {
  lang: boolean;
}
