import React, { useState } from "react";
import { Container, MobileCloseBtn, SupportBox, Wrapper, LanguageSelector, KOR, ENG } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { offSupportModal, selectActiveSupportModal, selectSupportModalData } from "redux/reducers/supportModalReducer";
import { IMG_CDN_URL } from "constants/pathConstant";
import { dateFormat } from "components/dateFormat";

const SupportModal = () => {
  const isActiveSupportModal = useSelector(selectActiveSupportModal);
  const supportModalData = useSelector(selectSupportModalData);
  const dispatch = useDispatch();

  const [isKor, setIsKor] = useState(true);

  const closeModalHandler = (e) => {
    if (e.target === e.currentTarget) dispatch(offSupportModal());
  };

  const closeBtnHandler = () => {
    dispatch(offSupportModal());
  };

  const changeLanguageHandler = () => {
    setIsKor((prev) => !prev);
  };

  // 카테고리
  const getCatName = (v) => {
    switch (v) {
      case "all":
        return !isKor ? "All" : "전체";
      case "sketch_wallet":
        return !isKor ? "Wallet" : "지갑";
      case "s_store":
        return !isKor ? "S-Store" : "가맹점";
      case "manual":
        return !isKor ? "Manual" : "사용법";
      case "pay":
        return !isKor ? "Pay" : "결제";
      case "etc":
        return !isKor ? "etc" : "기타";
      default:
        break;
    }
  };

  // 날짜 포맷팅
  function leftPad(value) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  /**
   *
   * @param {Date} source
   * @param {String} delimiter
   * @returns
   */
  function toStringByFormatting(source, delimiter = ".") {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());

    return [year, month, day].join(delimiter);
  }

  /**
   *
   * @param {Date} source
   * @param {String} delimiter
   */
  function toStringByHourFormatting(source, delimiter = ":") {
    const hour = leftPad(source.getHours());
    const min = leftPad(source.getMinutes());

    return [hour, min].join(delimiter);
  }

  return (
    <>
      {isActiveSupportModal && supportModalData && (
        <Wrapper onClick={closeModalHandler}>
          <Container>
            <MobileCloseBtn>
              <img
                loading="lazy"
                src={`${IMG_CDN_URL}/file/31456803x-min.webp`}
                alt="closeBtn"
                onClick={closeBtnHandler}
              />
            </MobileCloseBtn>
            <div>
              <LanguageSelector onClick={changeLanguageHandler}>
                <KOR isKor={isKor}>KOR</KOR>
                <ENG isKor={isKor}>ENG</ENG>
              </LanguageSelector>
            </div>
            <SupportBox>
              <div>{isKor ? supportModalData.titleKr : supportModalData.titleEng}</div>
              <div>
                <div>{dateFormat(supportModalData.updatedAt).slice(0, 10)}</div>
                <div>|</div>
                <div>{dateFormat(supportModalData.updatedAt).slice(11, 16)}</div>
                <div>|</div>
                <div>{isKor ? supportModalData.catCode.catNameKr : supportModalData.catCode.catNameEng}</div>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: isKor ? supportModalData.contentKr : supportModalData.contentEng }}
              ></div>
            </SupportBox>
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default SupportModal;
