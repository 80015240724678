import { useSelector } from "react-redux";
import { selectNewsModalData } from "redux/reducers/newsModalReducer";
import UploadNews from "../UploadNews";

const ModifyNews = () => {
  const newsData = useSelector(selectNewsModalData);
  return <UploadNews modifyData={newsData} />;
};

export default ModifyNews;
