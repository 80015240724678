import { IMG_CDN_URL } from "constants/pathConstant";
import { NavBox, Wrapper } from "./style";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useDispatch } from "react-redux";
import { changeLanguage } from "redux/reducers/languageReducer";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

const Com1 = ({ setIsClick }: { setIsClick: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation("languageNewVer");
  const nav = useNavigatePage();
  const dispatch = useDispatch();

  const navToUrl = (url: string) => {
    nav(url);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Wrapper>
      <div>
        <div
          onClick={() => {
            setIsClick(false);
          }}
        >
          <img src={`${IMG_CDN_URL}/file/5dfa9f22x_top.webp`} alt="close_btn" />
        </div>
      </div>
      <NavBox>
        <ul>
          <li onClick={() => navToUrl("/apps")}>{t("S_600020")}</li>
          <li onClick={() => navToUrl("/news_events/0")}>{t("S_600030")}</li>
          <li onClick={() => navToUrl("/support")}>{t("S_600040")}</li>
          <li onClick={() => dispatch(changeLanguage())}>{t("S_600050")}</li>
        </ul>
      </NavBox>
    </Wrapper>
  );
};

export default Com1;
