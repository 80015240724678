import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import { IMG_CDN_URL } from "constants/pathConstant";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Background = styled.div`
  width: 100vw;
  height: 108rem;
  background: url(${IMG_CDN_URL}/file/ded8076aapp_bg-min.png) no-repeat;
  background-size: contain;
  display: flex;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    height: 62rem;
    background: url(${IMG_CDN_URL}/file/9bc3807dapp_bg_phone-min_.png) no-repeat;
    background-size: cover;
  }

  & > div {
    margin: 23rem 0 0 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin: 9.4rem 5rem;
    }

    & > p {
      font-size: 6.4rem;
      font-family: ${FONTS.H};

      @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
        font-size: 3.6rem;
      }
    }
  }
`;

export const Slogan = styled.div`
  width: 51.6rem;
  height: 63rem;
  font-size: 9.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  border-radius: 3.2rem;
  background-color: rgba(0, 0, 0, 0.8);

  & > p {
    color: #70BBE5;
    font-size: 6.4rem;
    font-family: ${FONTS.H};
  }

  & > p > span {
    color: #fff;

    font-family: ${FONTS.RU};
    font-size: 14.4rem;
  }

  & > div {
    color: #d9d9d9;
    text-align: center;
    font-family: ${FONTS.EB};
    font-size: 2.4rem;
    margin-top: 6.5rem;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 2.4rem;
    background-color: rgba(0, 0, 0, 0.7);

    width: 27rem;
    height: 31.9rem;

    & > p {
      color: #70BBE5;
      text-align: center;
      font-family: ${FONTS.RU};
      font-size: 3.2rem;
      font-weight: 400;
    }

    & > p > span {
      color: #fff;

      text-align: center;
      font-family: "Russo One";
      font-size: 7.4rem;
      font-weight: 400;
    }
    & > div {
      color: #d9d9d9;

      text-align: center;
      font-family: AppleSDGothicNeoEB00;
      font-size: 1.4rem;

      margin-top: 3rem;
    }
  }
`;
