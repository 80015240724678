import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 6rem;

  & > :nth-child(1) {
    display: flex;
    justify-content: end;
    margin-right: 2rem;

    > div {
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const NavBox = styled.nav`
  font-size: 2rem;
  margin-top: 1rem;

  > ul {
    display: flex;
    flex-direction: column;
    gap: 0.1em;

    > li {
      background: #262626;
      padding: 1rem 2rem;
      cursor: pointer;
    }
  }
`;
