import { useState } from "react";
import { Wrapper, ContentWrapper, LanguageSelector, KOR, ENG } from "./sytle";

const PreviewSupportMobile = ({ title, htmlString, titleKr, htmlStringKr }) => {
  const [isKor, setIsKor] = useState(true);

  const changeLanguageHandler = () => {
    setIsKor((prev) => !prev);
  };

  return (
    <Wrapper>
      <div>
        <LanguageSelector onClick={changeLanguageHandler}>
          <KOR isKor={isKor}>KOR</KOR>
          <ENG isKor={isKor}>ENG</ENG>
        </LanguageSelector>
      </div>
      <div>
        {isKor ? (
          <ContentWrapper>
            <div>{titleKr}</div>
            <div dangerouslySetInnerHTML={{ __html: htmlStringKr }}></div>
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <div>{title}</div>
            <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
          </ContentWrapper>
        )}
      </div>
    </Wrapper>
  );
};

export default PreviewSupportMobile;
