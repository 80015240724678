import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 120rem;
  margin: 0 auto;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  gap: 20rem 5rem;
  justify-content: center;
  align-items: center;
  padding: 20rem 0;
`;

export const Content = styled.div`
  width: 30rem;
  height: 20rem;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 1rem;

  &:hover {
    color: white;
    background-color: black;
    cursor: pointer;
  }
`;
