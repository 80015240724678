import { useTranslation } from "react-i18next";
import { Background, Slogan, Wrapper } from "./style";

const Com1 = () => {
  const { t } = useTranslation("languageNewVer");

  return (
    <Wrapper>
      <Background>
        <div>
          <p>{t("S_500150")}</p>
          <Slogan>
            <p>Instant</p>
            <p>
              <span>50%</span>
            </p>
            <p>Sketch Back</p>
            <div>
              <p>{t("S_500160")}</p>
              <p>{t("S_500161")}</p>
              <p>{t("S_500162")}</p>
            </div>
          </Slogan>
        </div>
      </Background>
    </Wrapper>
  );
};

export default Com1;
