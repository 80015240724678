import { useNavigatePage } from "hooks/useNavigatePage";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { BtnWrapper, Page, PageWrapper, PaginationWrapper } from "./style";
import PageDLeftSvg from "components/Svg/pageDLeft";
import PageLeftSvg from "components/Svg/pageLeft";
import PageRightSvg from "components/Svg/pageRight";
import PageDRightSvg from "components/Svg/pageDRgiht";

const Pagination = ({ totalPages, pagingSize = 10, url }) => {
  const nav = useNavigatePage();
  const { page } = useParams<{ page: string }>();
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });

  // 10의자리 인덱스
  const [pageIndex, setPageIndex] = useState<number>(Math.floor(Number(page) / pagingSize));
  const [pageLength, setPageLength] = useState<number>(0);

  useEffect(() => {
    setPageLength(totalPages);
  }, [totalPages]);

  const navHandler = (to: number) => {
    if (Number(page) === to) {
      return;
    } else {
      nav(url + to);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // 페이지 이동 ( < | > 누르는 경우)
  const pageIndexHandler = (dir: string) => {
    if (dir === "up") {
      if (pageIndex + 1 > pageLength / 10) return;
      nav(url + (pageIndex + 1) * 10);
      setPageIndex(pageIndex + 1);
    } else {
      if (pageIndex - 1 < 0) return;
      nav(url + (pageIndex - 1) * 10);
      setPageIndex(pageIndex - 1);
    }
  };

  // 끝 페이지 이동 ( << | >> 누르는 경우)
  const endPageHandler = (dir: string) => {
    if (dir === "up") {
      if (pageIndex + 1 > pageLength / 10) return;
      nav(url + (pageLength - 1));
      setPageIndex(Math.floor(pageLength / 10));
    } else {
      if (pageIndex - 1 < 0) return;
      nav(url + 0);
      setPageIndex(0);
    }
  };

  const tempArr = new Array(pageLength).fill(0);

  return (
    <PaginationWrapper>
      <BtnWrapper pageLength={pageLength}>
        <div onClick={() => endPageHandler("down")}>
          <PageDLeftSvg />
        </div>
        <div onClick={() => pageIndexHandler("down")}>
          <PageLeftSvg />
        </div>
      </BtnWrapper>
      <PageWrapper>
        {isPc
          ? tempArr
              .map((v, idx) => (
                <Page key={idx} onClick={() => navHandler(idx)} isSelected={Number(page) * 1 === idx}>
                  {idx + 1}
                </Page>
              ))
              .slice(pageIndex * 10, (pageIndex + 1) * 10)
          : tempArr
              .map((v, idx) => (
                <Page key={idx} onClick={() => navHandler(idx)} isSelected={Number(page) * 1 === idx}>
                  {idx + 1}
                </Page>
              ))
              .slice(0, 5)}
      </PageWrapper>
      <BtnWrapper pageLength={pageLength}>
        <div onClick={() => pageIndexHandler("up")}>
          <PageRightSvg />
        </div>
        <div onClick={() => endPageHandler("up")}>
          <PageDRightSvg />
        </div>
      </BtnWrapper>
    </PaginationWrapper>
  );
};

export default Pagination;
