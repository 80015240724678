import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { selectActiveNewsModal } from "redux/reducers/newsModalReducer";
import { offSideBar, selectSideBar, switchSideBar } from "redux/reducers/sideBarReducer";
import AdminPcMenuCom from "./AdminPcMenuCom";
import { Container, Logo, LogoImg, MenuBtn, SideWrap, Wrapper } from "./style";
const AdminHeader = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const newsModalState = useSelector(selectActiveNewsModal);
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });

  // 로고 클릭 관련
  const logoClickHandler = () => {
    dispatch(offSideBar());
    nav("/admin-sketch");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 모바일 사이드바 관련
  const sideBarHandler = () => dispatch(switchSideBar());

  // 모바일 버전에서 메뉴 클릭시 로고, 스케치 스캔 가리기
  const sideBarState = useSelector(selectSideBar);

  return (
    // mobile 버전에서 뉴스모달 나타나있으면 탑바 제거
    <Wrapper isActive={newsModalState && !isPc}>
      <Container>
        <Logo onClick={logoClickHandler} isActive={sideBarState && !isPc}>
          <LogoImg src="/assets/img/Header_3.svg" alt="logo" />
        </Logo>
        {/* Pc Menu */}
        <AdminPcMenuCom />

        <SideWrap isActive={sideBarState && !isPc}></SideWrap>

        {/* Mobile Menu Btn */}
        <MenuBtn onClick={sideBarHandler}>
          <img loading="lazy" src={sideBarState ? "/assets/img/Header_7.svg" : "/assets/img/Header_4.svg"} alt="menu" />
        </MenuBtn>
      </Container>
    </Wrapper>
  );
};

export default AdminHeader;
