import { IMG_CDN_URL } from "constants/pathConstant";
import { Wrapper, ImgBox, SiteMapBox, Social } from "./style";
import IconCom from "./IconCom";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLanguage } from "redux/reducers/languageReducer";

const Footer = () => {
  const nav = useNavigatePage();
  const language = useSelector(selectLanguage);
  const { t } = useTranslation("languageNewVer");

  return (
    <Wrapper>
      <ImgBox>
        <div>
          <img src={`${IMG_CDN_URL}/file/0a7a3a34white_logo.webp`} alt="logo_img" />
        </div>
        <div>
          <img src={`${IMG_CDN_URL}/file/88e8e94dSketch Scan.png`} alt="logo_text_img" />
        </div>
      </ImgBox>
      <p>support@sketchproject.io</p>
      {/* <SiteMapBox>
        <p>{t("S_600080")}</p>
        <div>
          <span onClick={() => nav("/apps")}>{t("S_600020")}</span>
          <div>
            <span onClick={() => nav("/news_events/0")}>{t("S_600030")}</span>
            <span onClick={() => nav("/support")}>{t("S_600040")}</span>
          </div>
        </div>
      </SiteMapBox> */}
      <Social lang={language === "en"}>{t("S_600090")}</Social>
      <IconCom />
      <p>Copyright (C) 2024 All Rights Reserved.</p>
    </Wrapper>
  );
};

export default Footer;
