import { createSlice } from "@reduxjs/toolkit";

export const sideBarSlice = createSlice({
  name: "sideBar",

  initialState: {
    activeSideBar: false,
    activeSideMenu: true,
  },

  reducers: {
    onSideBar: (state) => {
      state.activeSideBar = true;
    },
    offSideBar: (state) => {
      state.activeSideBar = false;
    },
    switchSideBar: (state) => {
      state.activeSideBar = !state.activeSideBar;
    },
    onSideMenu: (state) => {
      state.activeSideMenu = true;
    },
    offSideMenu: (state) => {
      state.activeSideMenu = false;
    },
    switchSideMenu: (state) => {
      state.activeSideMenu = !state.activeSideMenu;
    },
  },
});

export const { onSideBar, offSideBar, switchSideBar, onSideMenu, offSideMenu, switchSideMenu } = sideBarSlice.actions;

export const selectSideBar = (state: { sideBar: { activeSideBar: boolean } }) => state.sideBar.activeSideBar;
export const selectActiveSideMenu = (state: { sideBar: { activeSideMenu: boolean } }) => state.sideBar.activeSideMenu;
export default sideBarSlice.reducer;
