import React from "react";
import "./term.style.js";
import { Wrapper } from "./term.style.js";
export const TermBasicPay = () => {
  return (
    <Wrapper>
      <div class="head">이용약관</div>
      <div class="jang">제1장 총칙</div>
      <div class="jo">제1조 목적</div>
      <div>
        본 약관은 ㈜비트세이프를 '회사'라 칭하며, 회사가 제공하는 '스케치월렛 및 스케치페이 통합 플렛폼'(이하 ‘스케치
        플랫폼’)의 이용에 관련하여 회원과 회사 간의 권리, 의무, 책임, 서비스 이용 조건 및 절차 등을 정하는 것을 목적으로
        합니다.
      </div>

      <div class="jo">제2조 용어의 정의</div>
      <div>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</div>
      <ol>
        <li>“스케치 플랫폼”은 회사가 서비스 제공 목적으로 회원에게 제공하는 전용 어플리케이션을 말합니다.</li>
        <li>
          “스케치월렛 앱 서비스”는 회원이 모바일 기기, 스마트폰 등 다양한 무선 기기 및 프로그램을 이용하여 '스케치월렛
          앱'에서 SKET를 보내고 받으며, 보관할 수 있도록 회사가 제공하는 서비스를 의미합니다.
        </li>
        <li>
          “스케치페이 앱 서비스”는 회원이 모바일 기기, 스마트폰 등 다양한 무선 기기 및 프로그램을 이용하여 '스케치페이
          앱' 에서 SKETCH를 보내고 받으며, 결제할 수 있도록 회사가 제공하는 서비스를 의미합니다.
        </li>
        <li>
          “회원”은 ‘스케치 플랫폼’ 앱을 설치하여 가입신청하고, 회사의 승인을 받아 서비스 이용 계약을 체결한 사용자를
          지칭합니다.
        </li>
        <li>
          “S-Store 회원”은 회원이 판매를 위한 서비스를 신청하고, 회사의 승인을 받아 추가 서비스 이용 계약을 체결한
          사용자를 말합니다.
        </li>
        <li>
          “SKET”는 디지털자산 'SKETCH COIN'을 지칭합니다. '스케치월렛 앱 서비스'에서 보내고 받고 보관할 수 있으며,
          ERC-20 계열의 외부지갑으로 전송이 가능합니다.
        </li>
        <li>
          “SKETCH”는 디지털자산 'SKETCH COIN(메인넷)'을 지칭합니다. '스케치페이 앱 서비스'에서 보내고 받고 결제할 수
          있으며, 회원 간 서비스 내에서 거래 수단으로 사용할 수 있습니다.
        </li>
        <li>
          “보내기”는 회원이 보유한 디지털자산을 설정한 방식의 본인 확인 후 다른 회원 또는 다른 지갑주소로 이전하는
          행위를 의미합니다.
        </li>
        <li>“받기”는 다른 회원 또는 다른 지갑주소로부터 회원의 지갑주소로 디지털자산을 이전 받는 행위를 의미합니다.</li>
        <li>
          “결제”는 ‘SKETCH’ 이전 방법 중 하나로, 이전 받는 회원이 회사로부터 별도의 승인을 받고 '페이 앱 서비스' 내의
          특수 기능을 활용하여 SKETCH를 이전하는 경우의 전송 방법을 의미합니다. ‘S-Store 회원’이 판매하는 상품 및
          콘텐츠를 구입하고 지불하는 수단의 전송방법입니다.
        </li>
        <li>
          “자산”은 스케치 월렛 앱에 보유되어 있는 디지털자산의 가치를 의미합니다. 자산은 보유되어 있는 디지털자산의
          수량과 그에 따른 가치를 보여줍니다. 디지털자산의 수량은 보내고 받지 않는 한 동일하나, 가치는 디지털자산의
          시세의 변동에 따라 변동될 수 있습니다.
        </li>
        <li>
          “스왑”은 스케치월렛 앱을 이용하는 회원이 이용할 수 있는 ‘스케치페이 앱’과 연결되는 개념으로서, 스케치월렛
          앱에서 보유하고 있는 SKET을 ‘스케치페이 앱’의 SKETCH로 보내는 의미입니다.
        </li>
        <li>
          “전환”은 스케치페이 앱을 이용하는 회원이 이용할 수 있는 ‘스케치월렛 앱’과 연결되는 개념으로서, 스케치페이
          앱에서 보유하고 있는 SKETCH을 ‘스케치월렛 앱’의 SKET로 보내는 의미입니다.
        </li>
        <li>“비밀번호”는 회원 인증을 위해 숫자, 문자, 특수기호를 조합해 사용되는 암호입니다.</li>
        <li>“PIN 번호”는 서비스 이용을 위해 회원이 개별적으로 설정한 4자리~6자리 숫자로 구성된 암호를 말합니다.</li>
        <li>
          “생체정보”는 지문, 얼굴, 홍채, 음성, 서명 등의 신체적 또는 행동적 특징에 관한 정보로서, 회원이 등록하여 서비스
          이용 시 본인 인증에 활용할 수 있는 정보를 의미합 니다. '생체정보'는 '비밀번호'를 대체할 수도 있습니다.
        </li>
        <li>
          “인증정보”는 서비스 이용 시 회원 인증을 위한 수단으로, 비밀번호 등 회사가 인정한 본인확인 수단을 말합니다.
        </li>
        <li>“지갑주소”는 디지털자산를 주고 받을 수 있는 문자와 숫자로 구성된 회원의 고유 계정을 의미합니다.</li>
        <li>
          “QR코드”는 2차원 매트릭스 형태로 이루어진 정보 표시 방법으로, 디지털자산을 주고 받을 시 이용할 수 있습니다.
        </li>
        <li>
          “가스비”는 회원이 ‘보내기’ 서비스를 이용할 때 발생하는 블록체인 트랜잭션 제출을 위해 지불하는 수수료를
          의미합니다.
        </li>
        <li>
          “S-Store 수수료”는 S-Store에 등록한 회원이 자신이 판매하는 상품에 대해 결제금액의 일정 비율을 사용자에게
          지불하는 서비스 대가를 의미합니다. 수수료 설정은 S-Store 오너의 자율입니다.
        </li>
        <li>“스토어추천 보너스”는 S-Store 회원을 추천한 회원에게 제공되는 혜택을 의미합니다.</li>
        <li>“스케치백 보너스”는 스케치페이로 결제 서비스를 이용하는 회원에게 제공되는 혜택을 의미합니다.</li>
        <li>“스케치드랍”은 스케치페이를 이용하는 회원들에게 보유 및 사용기여도에 따라 제공되는 혜택을 의미합니다.</li>
        <li>
          “푸시(PUSH)알림” 메시지 서비스는 모바일 기기 알림 서비스의 데이터 통신 기능을 활용하여 회사가 회원의 단말기로
          메시지를 전송하는 서비스를 의미합니다.
        </li>
        <li>
          “접근매체”는 전자금융거래 등 본 서비스 이용에 있어 거래 지시를 하거나 이용자 및 거래 내용의 진실성과 정확성을
          확보하기 위한 수단으로, "전자금융거래법" 제2조 제 10호에서 정의하고 있습니다.
        </li>
        <li>
          “회원코드”는 회원이 본 약관 및 개인정보 처리 방침에 동의한 후 회사가 회원을 식별하고 서비스를 제공하기 위해
          부여한 문자 또는 숫자 조합을 의미합니다.
        </li>
        <li>“메인 스토어코드”는 스케치페이로 결제가 가능한 온/오프라인 S-Store회원의 코드를 의미합니다.</li>
        <li>“추천코드”는 ‘스케치 플랫폼’을 추천하여 사용할 수 있도록 한 회원의 코드를 의미합 니다.</li>
        <li>
          “지갑”은 디지털자산을 보관하기 위한 소프트웨어로서, 디지털자산을 주고받을 수 있는 서비스를 이용할 수 있습니다.
        </li>
        <li>
          “메인지갑”은 ‘스케치 플랫폼’ 앱 통합회원 가입 시 니모닉으로 생성된 첫 지갑을 의미 합니다. 해당 지갑은 ‘스케치
          플랫폼’에서 모두 확인 가능하나, ‘메인지갑’이라는 용어는 “스케치페이 앱”내에서 사용되는 개념입니다.
        </li>
        <li>
          “보너스지갑”은 ‘스케치페이 앱’ 내에서 사용되는 개념으로, 스토어추천 보너스, 스케치백 보너스, 스케치드랍을
          지급받기 위해 회원이 선택한 지갑을 의미합니다.
        </li>
        <li>
          “개인키(Private Key)”는 디지털자산 지갑을 생성할 때 만들어지는 보안 요소로서, 지갑에 접근하고 거래를
          서명하는데 사용되는 긴 문자열을 의미합니다.
        </li>
        <li>
          “니모닉(mnemonic)”은 디지털자산 지갑에서 사용되는 보안 기술 중 하나로, 디지털 자산 지갑을 복구하기 위한 12개의
          단어입니다. 이 순서에 따른 단어들을 ‘복구단어’ 또는 ‘복구문구’라고도 표현합니다. <br />
          니모닉은 단어 12개를 순서대로 맞추는 것으로 암호화된 보안 비밀번호 방식으로 이 니모닉의 문자 배열을 초기대로
          하지 못하면 어느 누구도 지갑을 열 수 없습니다.
        </li>
      </ol>

      <div class="jo">제3조 약관의 효력 및 변경</div>
      <div>
        ① 본 약관은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지하고, 이에 동의한 회원이 서비스에 가입함으로써
        효력이 발생합니다.
      </div>
      <div>
        ② 회사는 ‘전자상거래 등에서의 소비자보호에 관한 법률’, ‘약관의 규제에 관한 법 률’, ‘정보통신망 이용촉진 및
        정보보호 등에 관한 법률(이하 "정보통신망법")’, ‘개인 정보 보호법’, ‘소비자 보호법’ 등 관련법을 위배하지 않는
        범위에서 이 약관을 개정 할 수 있습니다.
      </div>
      <div>
        ③ 회사는 필요하다고 인정되는 경우 관련 법령을 위배하지 않는 범위에서 본 약관을 변경할 수 있으며, 변경 시 전항과
        같은 방법으로 서비스 화면 또는 홈페이지에 적용일자 15일 전부터 공지하고, 공지 외에 일정 기간 동안 회원의
        전자우편, 문자 메시지 등의 전자적 수단을 통해 통지 합니다. <br />
        <br />
        다만, 회원에게 불리한 약관의 개정의 경우에는 개정약관의 적용일자 30일 전부터 공지 외에 문자메세지, 일정기간
        서비스내 전자우편, 로그인 팝업창 등의 전자적 수단을 통해 별도로 통지합니다.
      </div>
      <div>
        ④ 회사는 전 항에 따른 공지 또는 통지에 회원에게 약관 변경 적용일까지 거부 의사를 표시하지 아니할 경우, 변경된
        약관에 동의하는 것으로 간주한다는 내용을 포함하 고 이에 대해 회원이 명시적으로 거부 의사를 표명하지 아니한
        경우에는 회원이 변경된 약관에 동의하는 것으로 간주합니다.
      </div>
      <div>
        ⑤ 회원은 변경된 약관에 동의하지 않는 경우 적용일자 전날까지 회사에 거부 의사를 표시하고 서비스 이용 계약을
        해지할 수 있습니다.
      </div>

      <div class="jo">제4조 약관 외 준칙</div>
      <div>① 회사는 이 약관 외에 별도의 운영 정책을 둘 수 있습니다.</div>
      <div>
        ② 본 약관에서 명시되지 않은 사항에 관하여는 관련 전 항의 별도 정책, 세부 이용 지침 및 관계 법령의 또는 회사가
        정한 서비스의 개별 이용약관과 일반 상관례에 의합니다.
      </div>
      <div>
        ③ 회사가 정한 개별 운영 정책 및 세부 이용 지침은 회원이 서비스 이용 과정에서 확인이 가능하며, 개별 운영 정책 및
        세부 이용 지침과 관련하여 변경이 있는 경우 회사는 이를 홈페이지 또는 앱 내에 고지합니다.
      </div>

      <div class="jang">제2장 서비스 이용 계약</div>
      <div class="jo">제5조 이용 계약의 성립</div>

      <div>
        ① 회원은 회사가 정한 가입 양식에 따라 회원 정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로서 회원가입을
        합니다.
      </div>
      <div>
        ② 회원이 전항의 신청 완료 후 제6조 제2항의 본인 확인 인증 절차를 마치면 '회사'가 이를 승인함으로써 성립합니다.
      </div>

      <div class="jo">제6조 이용신청에 대한 승낙의 제한</div>

      <div>① 회사는 다음 각 호에 해당하는 경우 회원의 가입 신청을 거절할 수 있습니다.</div>

      <ol>
        <li>
          가입 신청자가 이 약관에 의하여 이전에 회원 자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은
          경우에는 예외로 함.
        </li>
        <li>기술상 서비스 제공이 불가능한 경우</li>
        <li>휴대폰 명의자 및 이메일 본인 확인에 의한 이용 신청이 아닌 경우</li>
        <li>허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
        <li>
          회원가입 시 입력 사항을 누락하거나 오기하여 신청하는 등 신청자의 귀책 사유로 승인이 불가능하거나 기타 규정한
          제반 사항을 위반하며 신청하는 경우
        </li>
        <li>각 법률에 따라 본 서비스 이용 행위가 해당 법률의 위반을 구성하거나 구성할 위험이 있는 경우</li>
        <li>가입 신청자가 기존의 서비스 탈퇴 신청을 한 후 삼십(30)일이 경과하지 않은 경우</li>
        <li>가입 신청자가 기존 서비스에 가입된 정보로 재가입 또는 신규 가입을 시도하는 경우</li>
        <li>기타 본 약관에서 규정한 신청 요건에 미비한 경우</li>
        <li>부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우</li>
        <li>
          관련 법률과 하위 규정, 감독 기관 등의 내부 지침, 회사의 약관 등에 위배되거나 사회의 안녕, 질서, 미풍양속을
          저해하거나 저해할 목적으로 신청한 경우로 판단되어 이 용신청을 승낙하는 것이 적절하지 않다고 '회사'가 판단하는
          경우
        </li>
        <li>가입 신청자의 나이가 만 19세 미만인 경우</li>
      </ol>

      <div>
        ② 회사는 회원이 제공한 정보가 사실과 일치하는지 여부를 확인하기 위해 법령에 의해 허용된 범위에서 전문 기관을
        통한 실명 확인 및 본인 인증을 요청할 수 있습니다. 제공되는 본인 인증의 방식에는 휴대폰 인증, 이메일 인증,
        비밀번호 등이 있습니다.
      </div>
      <div>
        ③ 전항의 인증 방식 관리에 대한 일체의 책임은 회원 본인에게 있으며 회원은 자신의 정보를 제3자에게 대여 또는
        양도하거나 이와 유사한 행위를 할 수 없습니다. 이로 인해 발생하는 모든 책임은 회원 본인에게 있습니다.
      </div>

      <div class="jo">제7조 계약 해지 및 이용 제한</div>
      <div>
        ① 회원은 ‘스케치 플랫폼’ 또는 홈페이지상에서 회사가 정한 방법 및 절차에 따라 서비스 이용계약을 해지할 수
        있습니다.
      </div>

      <div>
        ② 회사는 회원에게 다음 각 호의 어느 하나에 해당하는 사유가 있는 경우 서비스 이용 계약을 해지하거나 서비스 이용을
        제한할 수 있습니다.
      </div>

      <ol>
        <li>해킹 및 사고가 의심되거나 명의 도용이 의심되는 경우</li>
        <li>국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우</li>
        <li>
          자금세탁, 불공정거래, 불법행위 등과 관련하여 회사의 자금세탁 방지 의무 이행에 필요한 정보를 제공하지 않는 경우
        </li>
        <li>
          회원이 관계 법령, 감독규정, 감독기관의 지침, 그리고 본 약관의 내용 등을 위반하여 더이상의 서비스 제공이 어려운
          경우
        </li>
        <li>타인의 휴대전화번호 등을 타인정보를 도용하는 등 비정상적인 방법으로 가입한 경우</li>
        <li>서비스의 이용 목적을 벗어난 불합리한 용도로 이용하였다고 객관적으로 판단되는 경우</li>
        <li>회원으로 가입된 이용자가 만 19세 미만인 경우</li>
        <li>컴퓨터 바이러스 프로그램 등을 유포한 경우</li>
        <li>가입 신청 시 허위 사실을 기재한 경우</li>
        <li>해킹을 시도하거나 해킹을 한 경우</li>
        <li>
          국가기관 또는 금융기관 등으로부터 회원의 계정이 불법행위에 이용된 것으로 의심되는 정보의 제공이 있는 경우
        </li>
        <li>회사의 정책에 반하여 복수의 계정을 등록한 경우</li>
        <li>기타 본 약관을 위반한 경우</li>
      </ol>

      <div>
        ③ 회원이 전항에 따른 회사의 조치에 불복이 있는 경우에는 회사가 정한 절차에 따라 이의 신청을 할 수 있습니다.
        회사는 이의 신청이 정당하다고 인정하는 경우에는 즉시 회원의 서비스 이용을 재개합니다.
      </div>
      <div>
        ④ 회사는 제2항에 따라 서비스 이용계약을 해지하거나 제한하는 경우 회원이 보유하고 있는 자산은 동결한 후 관련
        법령에 따라 처분합니다. <br />
        <br />
        단, 그러한 처분이 필요 없는 때에는 일정 기간 이후 회원의 요청에 의하여 합리적인 방법으로 처분됩니다.
      </div>

      <div class="jang">제3장 서비스 이용</div>
      <div class="jo">제8조 서비스 이용 개시</div>

      <div>① 회원의 서비스 이용 신청이 회사에 접수되고, 이를 회사가 승인하면 서비스 이용이 개시됩니다.</div>
      <div>
        ② 서비스 이용은 승인 즉시 이용 가능하며, 문제 발생 시 회사가 서비스를 중단할 수 있습니다. 또한, 비정상적인 회원
        신청을 반복적으로 시도할 경우 해당 회원의 회 원 가입 또는 활동을 제한할 수 있습니다.
      </div>
      <div>
        ③ 서비스가 중단되거나 원활하지 못하는 상황이 발생한 경우 회사는 해당 사유를 서비스 화면 또는 홈페이지에
        공지합니다.
      </div>

      <div class="jo">제9조 서비스 제공 및 이용</div>
      <div>
        ① '스케치 플랫폼'이 제공하는 서비스는 디지털자산의 보내기, 받기, 보관, 결제, 스왑, 전환, 거래내역확인 등으로
        구분됩니다.
      </div>
      <div>② 보내기 서비스는 다음의 규정에 따라 제공됩니다.</div>
      <ol>
        <li>
          디지털자산 보내기 시 회원이 제공한 정보의 사실 여부를 확인하기 위해 법령이 허용 하는 범위 내에서 본인인증을
          요청할 수 있습니다.
        </li>
        <li>
          디지털자산 보내기 시 회사가 정한 규정에 따라 횟수 또는 회당 최대/최저 한도가 적용될 수 있으며, 한도에 대한
          자세한 기준은 서비스 어플리케이션 또는 홈페이지에 별도로 게재됩니다.
        </li>
        <li>
          회원은 제1항의 본인인증 절차 후 ‘지갑주소’, ‘QR코드’, ‘주소록’ 중 전송할 주소를 설정한 후 서비스 내에서 타
          회원 혹은 타 지갑에게 디지털자산을 전송할 수 있습니다.
        </li>
      </ol>
      <div>③ 결제 서비스는 다음의 규정에 따라 제공됩니다.</div>
      <ol>
        <li>회사는 제휴 S-Store의 결제 서비스를 제공합니다.</li>
        <li>
          SKETCH 결제 시 회사가 정한 규정에 따라 횟수 또는 회당 최대/최저 한도가 적용 될 수 있으며, 한도에 대한 자세한
          기준은 서비스 어플리케이션 또는 홈페이지에 별도로 게재됩니다.
        </li>
      </ol>

      <div>
        ④ 시장의 중단 또는 불가항력 사건이 발생한 경우, 회사는 다음의 규정에 따라 하나 이상의 조치를 취할 수 있으며,
        이러한 조치로 인해 회원이 발생한 손실에 대해 회사는 책임지지 않습니다.
      </div>

      <ol>
        <li>‘스케치 플랫폼’에 대한 접근 중단</li>
        <li>‘스케치 플랫폼’ 내 모든 서비스 이용 중단</li>
      </ol>

      <div class="jo">제10조 서비스 수수료</div>
      <div>① 디지털자산을 이전하는 경우, 가스비가 발생합니다.</div>
      <ol>
        <li>SKET의 가스비는 ETH로 지불합니다.</li>
        <li>SKETCH의 가스비는 SKETCH로 지불합니다.</li>
      </ol>
      <div>② 디지털자산을 이전받는 경우, 가스비는 발생하지 않습니다.</div>
      <div>
        ③ 가스비는 블록체인 특성상 네트워크에 의해 설정되며 네트워크 트래픽 및 트랜잭션의 복잡성에 따라 변동될 수
        있습니다.
      </div>

      <div class="jo">제11조 서비스 이용시간</div>
      <div>
        ① 본 서비스는 연중무휴, 1일 24시간 제공됩니다. 단, 회사의 업무 운영 또는 기술상의 이유로 서비스가 일시 중지될 수
        있습니다.
      </div>
      <div>
        ② 회사는 필요한 경우 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있습니다.
      </div>
      <div>
        ③ 전 1항과 2항의 경우, 회사는 원칙적으로 회원에게 서비스 제공의 제한 사유 및 그 기간 등을 ‘스케치 플랫폼’ 앱 내
        서비스 제공 화면 또는 회사 홈페이지 등에 구체 적으로 공지합니다. 다만, 회사의 귀책 없는 예상치 못한 사정으로
        발생한 경우는 예외입니다.
      </div>
      <div>
        ④ 회사는 서비스의 원활한 제공을 위해 필요하다고 판단할 경우 점검을 실시할 수 있으며, 점검 시간은 서비스 제공
        화면에 공지합니다.
      </div>

      <div class="jo">제12조 서비스의 변경 및 중지</div>

      <div>
        ① 회사는 서비스 내용의 변경이 필요한 경우 변경 상세 내역 및 제공일자를 서비스 화면 또는 홈페이지 등에 사전
        공지합니다.
      </div>
      <div>② 회사는 다음 각 호에 해당하는 경우 서비스 전부 또는 일부를 제한하거나 중지할 수 있습니다.</div>

      <ol>
        <li>
          회사의 영업양도, 분할, 합병 등으로 인해 영업의 폐지, 중요 제휴사와의 계약 만료, 당해 서비스의 현저한 수익 악화
          등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우
        </li>
        <li>천재지변, 국가비상사태 등 불가항력적인 사유로 인해 서비스를 제공할 수 없음이 객관적으로 명백한 경우</li>
        <li>회사가 직접 제공하지 않는 서비스를 제휴업체 등의 제3자를 이용하여 제공하는 경우</li>
        <li>제휴업체 등의 제3자가 서비스를 중지한 경우</li>
        <li>기타 중대한 사유로 인해 회사가 서비스를 제공할 수 없음이 명확히 인정되는 경우</li>
      </ol>

      <div>③ 제2항에 따른 서비스 중단에 대한 회사의 공지 의무는 제11조 제3항에 따릅니다.</div>
      <div>
        ④ 회사는 고의 또는 중대한 과실이 아닌 서비스의 변경, 중지로 인해 발생하는 문제에 대해서는 어떠한 책임도 부담하지
        않습니다.
      </div>

      <div class="jo">제13조 마케팅</div>
      <div>① 회사는 자체 마케팅 정책에 따라 회원에게 쿠폰 등을 제공할 수 있습니다.</div>
      <div>
        ② 회원은 전항에 따라 제공받은 쿠폰 등을 명시된 기간 내에 사용할 수 있으며, 부당 또는 부정하게 취득한 경우 회사에
        의해 회수 조치가 진행될 수 있습니다.
      </div>
      <div>
        ③ 회사는 서비스를 이용하는 회원에게 자체 마케팅 정책에 따라 스토어보너스, 스케치백, 스케치드랍을 제공할 수
        있으며, 회원이 등록한 ‘보너스지갑’으로 제공합니다.
      </div>
      <div>
        ④ 회원이 보너스지갑 등의 마케팅 정보를 입력하지 아니한 경우, 혜택을 받지 못한 것에 대해 책임은 회원에게
        있습니다. 이후 회원이 보너스지갑 등의 마케팅 정보를 입력했다면 그 시점 이후의 혜택부터 제공됩니다.
      </div>
      <div>
        ⑤ 회원은 전항에 따라 제공받은 SKETCH를 사용할 수 있으며, 부당 또는 부정하게 취득한 경우 회사에 의해 제재조치가
        진행될 수 있습니다. 또한, 이미 모두 소진된 경우 ‘스케치페이 앱’ 서비스의 제재가 발생할 수 있습니다.
      </div>

      <div class="jo">제14조 정보의 제공</div>
      <div>
        ① 회사는 서비스 운영과 관련한 공지사항을 홈페이지 또는 ‘스케치 플랫폼’ 앱에 게재하거나 회원이 제공한
        휴대전화번호를 통해 SMS, PUSH 메시지 등으로 회원에게 통지합니다.
      </div>
      <div>
        ② 회사는 서비스 운영과 관련한 각종 정보를 홈페이지 또는 ‘스케치 플랫폼’ 앱에 게재하는 등의 방법으로 회원에게
        통지합니다.
      </div>
      <div>
        ③ 회원은 회사가 서비스의 일부로 프로모션 목적의 광고, 기타 콘텐츠 및 자료 등을 배치하거나 제시하는데 동의합니다.
      </div>

      <div class="jo">제15조 서비스 이용 관련 유의사항</div>

      <div>
        ① 회사는 제7조 제2항 및 제20조 각 항 등의 사유가 있는 경우 해당 거래의 처리를 거부하거나 거래 금액 및 기타 다른
        거래 조건에 제한을 둘 수 있습니다. 이로 인한 회원의 손실에 대해 회사는 책임을 지지 않습니다.
      </div>
      <div>
        ② 회사는 중요 제휴사의 파산, 해산 또는 서비스 제공의 합리적 중단 사유가 있거나 관련 법령, 감독 당국 등 정부의
        방침 또는 이와 비슷한 사유가 있는 경우, 서비스에서 회원이 이용할 수 있는 개별 디지털자산을 제외할 수 있습니다.
      </div>
      <div>
        ③ 회사는 전항에 따른 디지털자산 제외 여부에 대해 회원에게 사전 공지하기 위해 최선을 다하며, 회사는 거래 중단에
        대한 공지일로부터 적어도 30일 동안 타 서비스에서 발급된 개인 지갑 등으로 디지털자산을 이전하도록 지원합니다.
      </div>
      <div>
        ④ 서비스에서 제공하는 콘텐츠는 서비스 이용을 위한 보조 자료이며, 투자 또는 거래를 권유하거나 암시하는 것이
        아닙니다. 회사는 콘텐츠 및 타정보 제공자가 제공하는 정보의 정확성을 보증하지 않습니다.
      </div>
      <div>
        ⑤ SKET 및 SKETCH는 법정 통화가 아니며, 수요의 변화 및 규제 환경 변화 등으로 인해 시세가 변동될 수 있습니다.
        회사는 회원이 이러한 높은 변동성을 가지고 있는 자산임을 명확히 이해하고 취득한것으로 간주합니다. <br />
        SKET 및 SKETCH의 이용 또는 사용에 대한 책임은 회원에게 있으며, 발생 가능한 모든 이익 및 손실은 회원에게
        귀속됩니다.
      </div>
      <div>
        ⑥ 회사는 인정하지 않은(또는 회사로부터 허락받지 않은) 비인가 프로그램 및 지갑 내에서 부당한 이득을 취하거나
        ‘스케치 플랫폼’ 앱 내의 서비스를 악용하여 SKET 및 SKETCH의 시세에 영향을 주는 행위가 발견될 경우 서비스 이용
        제한 등 매우 강력한 제재는 물론이고, 관계 법령에 따라 사법기관에 수사를 의뢰할 수 있습니다.
        <br />
        회사는 안정적인 디지털자산 환경 조성을 위해 해당 행위의 조사를 진행할 수 있으며, 조사 진행 시 일정기간 동안
        ‘스케치 플랫폼’ 앱 서비스가 제한될 수 있습니다.
      </div>
      <div>
        ⑦ 부적절한 행위 및 부당한 행위로 인해 회사에 손해가 발생한 경우 회사는 고객에게 손해배상 청구 등 별도의 법률적인
        조치를 취할 수 있습니다.
      </div>

      <div class="jang">제4장 계약 당사자의 의무</div>

      <div class="jo">제16조 회사의 의무</div>
      <div>
        ① 회사는 관련 법과 이 약관이 금지하거나 일반 상식에 위배되는 행위를 하지 않으며, 지속적이고 안정적인 서비스를
        제공하기 위해 노력합니다.
      </div>
      <div>
        ② 회사는 회원의 개인정보를 본인의 동의 없이 제3자에게 누설하거나 배포하지 않으며, 관계 법령에 따라 적법한 절차에
        의해 개인정보를 처리합니다. 다만, 관련 법령에 따라 제공되어야 하는 경우에는 예외로 합니다.
      </div>
      <div>
        ③ 회사는 회원으로부터 제기된 의견이나 불만이 정당하다고 인정되는 경우 이를 적절히 처리하여야 합니다. 회원의
        의견이나 불만사항에 대해서는 전자우편, 문자메시지 등을 통해 회원에게 처리 과정과 결과를 전달합니다.
      </div>
      <div>
        ④ 회사는 회원 및 사용자의 불만 또는 피해구제 요청을 적절하게 처리할 수 있도록 필요한 인력과 시스템을 구비합니다.
      </div>
      <div>
        ⑤ 회사는 「정보통신망법」, 「위치정보보호법」, 「통신비밀보호법」, 「전기통신사업법」 등 서비스의 운영, 유지와
        관련하여 모든 법규를 성실히 준수합니다.
      </div>

      <div class="jo">제17조 회원의 의무</div>

      <div>
        ① 회원은 본 약관의 규정, 관계법령, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
        하며, 기타 회사 업무 또는 서비스에 방해되는 행위를 하지 않습니다.
      </div>
      <div>② 회원은 서비스 이용 시 다음 각 호의 행위를 하지 않습니다.</div>

      <ol>
        <li>
          서비스 이용신청 또는 변경 시 허위 사실을 기재하거나, 제3자의 정보를 도용하거나 부정하게 사용하는 행위, 또는
          휴대전화 명의자의 허락 없이 실사용자가 인증 등을 수행하는 행위
        </li>
        <li>제3자의 인증정보를 등록하여 서비스를 이용하는 행위</li>
        <li>부정한 방법으로 회원코드를 발급받거나 복제하여 서비스를 이용하는 행위</li>
        <li>회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제하거나 유포 하는 행위</li>
        <li>
          서비스와 관련된 설비의 오작동이나 정보 등의 파괴 및 혼란을 유발시키는 바이러스 감염 자료를 등록하거나 유포하는
          행위
        </li>
        <li>회사와 기타 제3자의 저작권 등 지식재산권을 침해하는 행위</li>
        <li>회사와 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
        <li>디지털자산의 시세에 부당한 영향을 주는 방법으로 건전한 거래 질서를 교란하는 행 위</li>
        <li>다른 회원의 개인정보를 수집, 저장, 공개하는 행위</li>
        <li>기타 회사가 게시한 정보를 변경하는 행위</li>
        <li>단시간 내의 상식범위를 넘어선 빈번한 거래, 명의 차용 거래 등 부정거래로 의심되거나 부정거래 관련 행위</li>
        <li>기타 불법적이거나 공서양속, 사회상규에 위배되는 행위</li>
      </ol>

      <div>
        ③ 회원은 제2항 각 호에 명시된 행위를 하면 회사가 진행하는 서비스의 일부 또는 전부에 대한 이용제한, 해지 등의
        조치를 이의 없이 수용합니다. 또한 회원은 그로 인해 회사가 입은 모든 손해를 배상해야 합니다.
      </div>
      <div>
        ④ 회원이 서비스를 이용함에 있어 불법행위나 이 약관의 위반으로 인하여 회사가 제 3자로부터 손해배상청구, 소송을
        비롯한 각종 이의 제기를 받는 경우, 해당 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 입은 모든
        손해를 배상해야 합니다.
      </div>
      <div>⑤ 회원은 서비스의 이용권한이나 기타 이용 계약상의 지위를 제3자에게 양도 또는 증여할 수 없습니다.</div>

      <div class="jo">제18조 회원의 인증정보 등 관리에 대한 책임</div>
      <div>
        ① 회원은 지갑주소, 니모닉, 개인키 및 비밀번호와 인증정보 등의 관리에 대한 책임을 부담하며, 관리 소홀로 인해
        발생하는 손해에 대한 모든 책임을 부담합니다.
      </div>
      <div>
        ② 회원은 니모닉, 개인키 및 비밀번호와 인증정보 등을 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시
        해당지갑에 보유중인 자산을 이전해두거나, 인증 정보 등을 변경하는 등의 조치를 취하여 자신의 자산과 정보를
        보호하여야 합니다.
      </div>

      <div class="jo">제19조 회원정보의 변경</div>
      <div>
        회원은 서비스 이용 신청 시 기재한 사항이 변경되었을 경우 회사가 정한 방법에 따라 수정하여야 합니다. 회사는
        회원이 변경 사항을 적시에 수정하지 않음으로 인하여 발생하는 문제에 대해서는 어떠한 책임도 지지 않습니다.
      </div>

      <div class="jo">제20조 서비스 이용 기기의 분실 및 도난 신고, 변경</div>

      <div>① 회원은 모바일 기기 또는 접근매체를 분실하거나 도난 당한 경우 즉시 이에 따른 조치를 취해야 합니다.</div>
      <div>
        ② ‘스케치 플랫폼’ 앱은 블록체인 탈중앙 서비스이므로 회원이 분실한 모바일 기기 또는 접근매체에 어떠한 조치를 취할
        수 없습니다.
      </div>
      <div>③ 회원은 다음 각 호에 해당할 경우 부정사용에 따른 모든 책임을 부담합니다.</div>

      <ol>
        <li>모바일 기기의 관리 소홀, 대여, 양도, 담보 제공, 제3자 보관 등으로 인한 부정 사용</li>
        <li>비밀번호 등 인증정보 누설에 의한 부정 사용</li>
        <li>회원의 가족, 동거인(사실상의 동거인 포함)에 의한 부정사용</li>
      </ol>

      <div>
        ④ 회원이 기기의 명의 또는 전화번호를 변경한 경우 전용 어플리케이션을 이용하여 다시 이용 신청을 하거나 서비스를
        해지하여야 합니다.
      </div>

      <div class="jo">제21조 회원에 대한 통지</div>

      <div>회사는 회원에 대하여 제14조 제1항 등에서 정한 방식으로 통지를 진행합니다.</div>

      <div class="jo">제22조 회원의 개인정보보호 등</div>
      <div>
        ① 회사는 관련 법령이 정하는 바에 따라 회원의 등록 정보를 포함한 개인정보를 보호하기 위해 노력합니다. 회원의
        개인정보보호에 관한 사항은 관련 법령 및 회사가 정하는 개인정보취급방침에 따릅니다.
      </div>
      <div>
        ② 회사는 서비스의 제공을 위해 회원의 동의를 받아 회원의 개인정보를 제휴사와 서비스 위탁 운영사에게 제공할 수
        있습니다.
      </div>
      <div>
        ③ 회사는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로 수행하는 것을 원칙으로 하나, 필요한 경우 회원의
        동의를 받아 위 업무의 일부 또는 전부를 회사가 선정한 회사에 위탁할 수 있습니다.
      </div>

      <div class="jang">제 5 장 분쟁의 해결</div>

      <div class="jo">제23조 회사의 면책</div>

      <div>
        ① 회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여
        일시적 또는 종국적으로 서비스를 제공할 수 없는 경우, 서비스 제공에 관한 책임이 면제됩니다. 이 경우 회사는 회사가
        제공하는 홈페이지에 게시하거나 기타 가능한 고지 방법으로 회원에게 통지합니다.
      </div>
      <div>② 회사는 회원의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여 책임을 지지 않습니다.</div>
      <div>
        ③ 회사와 피연결회사(회사의 서비스와 링크, 제휴 등으로 연결된 시스템을 운영하는 회사를 말함)는 독자적으로
        운영되며, 피연결회사와 회원 간에 이루어진 거래 또는 계 약은 거래 또는 계약의 당사자인 피연결회사와 회원 각각의
        책임 하에 이루어집니다. 회사는 피연결회사와 회원 사이의 거래 또는 계약에 관여하지 않으며, 이에 대하여 책임을
        부담하지 않습니다.
      </div>
      <div>
        ④ 회사는 회원 상호간의 거래 또는 회원과 제3자 상호간에 서비스를 매개로 인한 분쟁 발생 시, 분쟁해결 등 필요한
        사후처리는 거래 당사자들이 직접 수행하여야 하고 회사는 이에 대하여 관여할 의무가 없으며, 이로 인한 회원의 손해를
        배상할 책임을 부담하지 않습니다. 단, 그 상황이 심각하여 회원들이 중재를 요청할 경우 문제 해결 에 관여할 수
        있습니다.
      </div>
      <div>
        ⑤ 회사는 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는
        회사가 발행하거나 지급을 보증하지 아니한 디지털자산의 가치를 보증하지 않습니다.
      </div>

      <div class="jo">제24조 분쟁해결</div>

      <div>
        ① 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 불편사항을 처리하기 위해 고객센터를 운영합니다.
      </div>
      <div>
        ② 회사는 회원으로부터 제기되는 의견 및 불만사항을 즉시 처리함을 원칙으로 합니다. 다만, 신속한 처리가 곤란한 경우
        회원에게 그 사유와 처리 일정을 통보합니다.
      </div>
      <div>
        ③ 회사와 회원간 발생한 분쟁과 관련하여 회원의 피해구제신청이 있는 경우 분쟁조정기관의 조정에 따를 수 있습니다.
      </div>

      <div class="jo">제25조 재판권 및 준거법</div>

      <div>
        ① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히
        협의합니다.
      </div>
      <div>
        ② 제1항의 협의를 통해서도 분쟁이 해결되지 않는 경우, 양 당사자간 소송은 「민사 소송법」상의 관할법원 규정에
        따릅니다. 제소 당시 이용자의 주소 또는 거소가 분명 하지 않거나 외국 거주자의 경우 역시 동일합니다.
      </div>
      <div>③ 회사와 회원간 제기된 소송에는 대한민국법을 적용합니다.</div>

      <div>부칙</div>
      <div>이 약관은 2021년 08월 01일부터 시행됩니다.</div>
    </Wrapper>
  );
};
