const PageDLeftSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2260_15333)">
        <g opacity="0.6">
          <path d="M10 15L5.33333 10L10 5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14 15L9.33333 10L14 5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2260_15333">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PageDLeftSvg;
