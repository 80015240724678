import { axiosInstanceAdmin } from "api";
import Loading from "components/Loading";
import { useLoginCheck } from "hooks/useLoginCheck";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { logoutAdmin } from "redux/reducers/adminReducer";
import { onNoticeModal, setAdminNoticeData } from "redux/reducers/noticeModalReducer";
import AdminPagination from "../AdminPagination";
import {
  BottomBtn,
  BottomContainer,
  ContentBox1,
  ContentBox2,
  ContentNav,
  DeleteContainer,
  DeleteTextBox,
  MainContent,
  SearchBox,
  SearchBtn,
  SearchInput,
  SearchSelect,
  TextBox,
  TopContainer,
  Wrapper,
} from "./AdminNotice.style";
import NoticePreview from "./NoticePreview/NoticePreview";
import { dateFormat } from "components/dateFormat";

const AdminNotice = () => {
  const nav = useNavigatePage();
  const dispatch = useDispatch();
  const { page } = useParams();
  const queryClient = useQueryClient();
  const loginCheck = useLoginCheck();

  const [pageNum, setPageNum] = useState<number>(0);
  const [checked, setChecked] = useState<[number, boolean][]>(new Array(10).fill(0).map(() => [0, false]));
  const [rowId] = useState<number[]>([]);
  const allCheckRef = useRef<HTMLInputElement>(null);
  const actionRef = useRef<HTMLSelectElement>(null);

  // 로그인 체크
  useEffect(() => {
    loginCheck();
  }, []);

  // ------------------------ pagination

  useEffect(() => {
    if (Number(page) < 0) {
      nav("/admin-sketch/notices/manage/0");
      return;
    }
    setPageNum(Number(page));
  }, [page]);

  const { data, isLoading, isError } = useQuery<any>("manage_notices" + page, () =>
    axiosInstanceAdmin.get("notices", { params: { page, size: 10 } }),
  );

  const noticeData = data as Page<IAdminNotice>;

  useEffect(() => {
    if (!noticeData) return;
    if (parseInt(String(page)) + 1 > noticeData.totalPages && page !== "0") {
      nav(`/admin-sketch/notices/manage/${noticeData.totalPages - 1}`);
    }
  }, [noticeData]);

  if (isError) {
    dispatch(logoutAdmin());
    alert("새로 고침 후 로그인을 다시 해주세요");
    nav("/admin-sketch");
  }

  // ------------------------ check Handler

  const allCheckHandler = (e) => {
    for (let i = 0; i < checked.length; i++) {
      checked[i][1] = e.target.checked ? true : false;
      checked[i][0] = rowId[i];
    }
    setChecked([...checked]);
  };

  const checkHandler = (e, idx: number) => {
    if (allCheckRef.current && !e.target.checked) {
      allCheckRef.current.checked = false;
    }
    checked[idx][1] = e.target.checked ? true : false;
    checked[idx][0] = rowId[idx];
    setChecked([...checked]);
  };

  // --------------------- data query

  const { mutate } = useMutation(
    (data: { id: number; value: string }[]) => axiosInstanceAdmin.patch("/notices", { data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("manage_notices" + page);
        if (allCheckRef.current) allCheckRef.current.checked = false;
        setChecked(new Array(10).fill(0).map(() => [0, false]));
      },
      onError: () => alert("적용 실패 - 개발자에게 문의하세요."),
    },
  );

  const multiManageHandler = () => {
    const temp = [...checked]
      .filter((v) => v[1])
      .map((v) => {
        if (actionRef.current) return { id: v[0], value: actionRef.current.value };
        return undefined;
      })
      .filter((v): v is { id: number; value: string } => v !== undefined);

    manageHandler(...temp);
  };

  const manageHandler = (...args: { id: number; value: string }[]) => {
    if (window.confirm("적용 하시겠습니까?")) mutate(args);
  };

  const openNoticeModal = (id: number) => {
    const selectedData = noticeData.content.find((v) => v.id === id);
    dispatch(setAdminNoticeData(selectedData));
    dispatch(onNoticeModal());
  };

  const modifyHandler = (id: number) => {
    const selectedData = noticeData.content.find((v) => v.id === id);

    dispatch(setAdminNoticeData(selectedData));
    nav("/admin-sketch/notices/modify");
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <NoticePreview />
      <Wrapper>
        {/* 상단 */}
        <TopContainer>
          {/* 맨위 이름, 검색 */}
          <ContentBox1>
            <TextBox>Notice Manage</TextBox>
            <SearchBox>
              <SearchSelect>
                <option value="">제목</option>
                <option value="">작성자</option>
              </SearchSelect>
              <SearchInput />
              <SearchBtn>검색</SearchBtn>
            </SearchBox>
          </ContentBox1>
          {/* 내용 */}
          <ContentBox2>
            <ContentNav>
              <span>
                <input type="checkbox" ref={allCheckRef} onChange={(e) => allCheckHandler(e)} />
              </span>
              <span>번호</span>
              <span>제목</span>
              <span>작성자</span>
              <span>종료일</span>
              <span>종료 여부</span>
              <span>마지막 수정인</span>
              <span>게시 여부</span>
              <span>수정</span>
            </ContentNav>
            {noticeData.content?.map((notice, idx) => (
              <MainContent key={notice.id} checked={checked[idx][1]}>
                <span>
                  <input type="checkbox" checked={checked[idx][1]} onChange={(e) => checkHandler(e, idx)} />
                </span>
                <span>{pageNum * 10 + idx + 1}</span>
                <span onClick={() => openNoticeModal(notice.id)}>{notice.title}</span>
                <span>{notice.writer?.name}</span>
                <span>{dateFormat(notice.end_at).slice(0, 16)}</span>
                <span>{dateFormat(undefined, undefined, 0) > dateFormat(notice.end_at) ? "O" : "X"}</span>
                <span>{notice.modifier?.name}</span>
                <span>{notice.is_available ? "게시" : "미게시"}</span>
                <span onClick={() => modifyHandler(notice.id)}>수정</span>
                <div style={{ display: "none" }}>{(rowId[idx] = notice.id)}</div>
              </MainContent>
            ))}
          </ContentBox2>
        </TopContainer>
        {/* 페이지네이션 */}

        <AdminPagination baseUrl={"/admin-sketch/notices/manage/"} totalPages={noticeData.totalPages} />
        {/* 아래 부분 */}
        <BottomContainer>
          <DeleteContainer>
            <span>선택한 항목을</span>
            <DeleteTextBox ref={actionRef}>
              <option value="on">게시</option>
              <option value="off">미게시</option>
              <option value="delete">삭제</option>
            </DeleteTextBox>
            <BottomBtn onClick={() => multiManageHandler()}>적용</BottomBtn>
          </DeleteContainer>
          <BottomBtn onClick={() => nav("/admin-sketch/notices/upload")}>작성하기</BottomBtn>
        </BottomContainer>
      </Wrapper>
    </>
  );
};
export default AdminNotice;
