// @ts-ignore
import { combineReducers } from "@reduxjs/toolkit";
import languageReducer from "./languageReducer";
import newsModalReducer from "./newsModalReducer";
import noticeModalReducer from "./noticeModalReducer";
import sideBarReducer from "./sideBarReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";
import supportModalReducer from "./supportModalReducer";

const rootReducer = combineReducers({
  sideBar: sideBarReducer,
  language: languageReducer,
  user: userReducer,
  newsModal: newsModalReducer,
  noticeModal: noticeModalReducer,
  admin: adminReducer,
  supportModal: supportModalReducer,
});

export default rootReducer;
