import { axiosInstanceAdmin } from "api";
import { useDispatch } from "react-redux";
import { loginAndSetAdmin } from "redux/reducers/adminReducer";
import { useNavigatePage } from "./useNavigatePage";

interface IResponse {
  name: string;
  role: string;
  id: number;
}

export const useLoginCheck = () => {
  const dispatch = useDispatch();
  const nav = useNavigatePage();

  return async () => {
    const accessToken = sessionStorage.getItem("accessToken");

    const data = { accessToken, name: "", role: "", id: 0 };

    const isLogin = await axiosInstanceAdmin
      .get("/check", {
        headers: { Authorization: accessToken },
      })
      .then((res: any) => {
        const response = res as IResponse;
        data.name = response.name;
        data.role = response.role;
        data.id = response.id;
        return true;
      })
      .catch(() => console.log("login check 실패"));

    if (isLogin) dispatch(loginAndSetAdmin(data));
    else nav("/admin-sketch/login");
  };
};
