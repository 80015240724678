import { useLoginCheck } from "hooks/useLoginCheck";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useSelector } from "react-redux";
import { selectAdminRole } from "redux/reducers/adminReducer";
import { Container, Content, Wrapper } from "./style";
import { useEffect } from "react";
const AdminHome = () => {
  const nav = useNavigatePage();
  const loginCheck = useLoginCheck();
  const adminRole = useSelector(selectAdminRole);

  // 로그인 체크
  useEffect(() => {
    loginCheck();
  }, []);

  return (
    <Wrapper>
      <Container>
        {adminRole.includes("DEV") ? (
          <>
            <Content onClick={() => nav("admins/manage/0")}>사용자관리</Content>
            <Content onClick={() => nav("news/manage/0")}>뉴스</Content>
            <Content onClick={() => nav("notices/manage/0")}>공지</Content>
            <Content onClick={() => nav("support/manage")}>FAQ</Content>
            <Content onClick={() => nav("upload")}>이미지 업로드 (개발자 전용)</Content>
            {/* <Content onClick={() => nav("menu")}>메뉴 (개발자 전용)</Content> */}
            <Content onClick={() => nav("sfuel")}>sfuel 받기</Content>
          </>
        ) : adminRole.includes("SUPER") ? (
          <>
            <Content onClick={() => nav("admins/manage/0")}>사용자관리</Content>
            <Content onClick={() => nav("news/manage/0")}>뉴스</Content>
            <Content onClick={() => nav("notices/manage/0")}>공지</Content>
          </>
        ) : adminRole.includes("NEWS") ? (
          <>
            <Content onClick={() => nav("news/manage/0")}>뉴스</Content>
          </>
        ) : adminRole.includes("IMAGE") ? (
          <>
            <Content onClick={() => nav("upload")}>이미지 업로드 (개발자 전용)</Content>
          </>
        ) : adminRole.includes("NOTICE") ? (
          <>
            <Content onClick={() => nav("notices/manage/0")}>공지</Content>
          </>
        ) : (
          ""
        )}
      </Container>
    </Wrapper>
  );
};

export default AdminHome;
