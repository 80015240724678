import { Outlet, useLocation } from "react-router";
import { LayOutContainer } from "./style";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Banner from "components/Banner";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

const Layout = () => {
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });
  const location = useLocation();

  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    setIsClick(false);
  }, [isPc, location.key]);

  return (
    <div>
      <Banner />
      <Header setIsClick={setIsClick} />

      <LayOutContainer>
        {/* Pages content : Begin */}
        <Outlet />
        {/* Pages content : End */}
      </LayOutContainer>

      <Footer />
      {isClick && <Sidebar setIsClick={setIsClick} />}
    </div>
  );
};

export default Layout;
