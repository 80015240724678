import { axiosInstanceAdmin } from "api";
import { useLoginCheck } from "hooks/useLoginCheck";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { onNoticeModal, selectAdminNoticeData, setAdminNoticeData } from "redux/reducers/noticeModalReducer";
// import NoticePreview from "../NoticePreview/NoticePreview";
import {
  BottomBtn,
  BottomWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  ThumbnailWrapper,
  Title,
  UploadImage,
  UploadImageLabel,
  Wrapper,
} from "./ModifyNotice.style";
import { dateFormat } from "components/dateFormat";
import NoticePreview from "../NoticePreview/NoticePreview";
const ModifyNotice = () => {
  const nav = useNavigatePage();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const loginCheck = useLoginCheck();
  const noticeData = useSelector(selectAdminNoticeData);

  const [title, setTitle] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [noticeId, setNoticeId] = useState<string>("");
  const dateInput = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLInputElement>(null);
  const activeRef = useRef<HTMLSelectElement>(null);

  // 로그인 체크
  useEffect(() => {
    loginCheck();
  }, []);

  // 초기값 세팅
  useEffect(() => {
    if (!noticeData.id) return;
    // id
    setNoticeId(noticeData.id);

    // title
    setTitle(noticeData.title);

    // end time
    if (dateInput.current) {
      dateInput.current.defaultValue = dateFormat(noticeData.end_at, "-");
    }

    // 공개 여부
    if (!noticeData.is_available) {
      setIsActive(false);
      if (activeRef.current) {
        activeRef.current.value = "false"; // 문자열로 설정
      }
    }

    // url
    setUrl(noticeData.url);

    // image
    setImgSrc(noticeData.img_path);
  }, [noticeData]);

  // 이미지 업로드 핸들러
  const imgUploadHandler = () => {
    if (imgRef.current && imgRef.current.files) {
      const file = imgRef.current.files[0];
      // input에 안담기면 return
      if (!file) return;
      // 5MB보다 큰경우 return
      if (file.size / 1000 / 1000 > 5) {
        alert("파일 크기는 최대 5MB입니다.");
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (reader.result) {
          setImgSrc(reader.result.toString());
        }
      };
    }
  };

  // 게시글 업로드 handler
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const { mutate } = useMutation((data) => axiosInstanceAdmin.put("/notices", data, config), {
    onSuccess: () => queryClient.invalidateQueries(["manage_notices0"]),
    onError: () => alert("업로드에 실패하였습니다. 개발자에게 문의하세요."),
  });

  const upLoadHandler = () => {
    if (!title || !dateInput.current?.value) {
      alert("제목 또는 종료일을 설정하세요.");
      return;
    }

    const formData: any = new FormData();
    const data = { title, isActive, url, id: noticeId };
    const date = { endAt: dateFormat(new Date(dateInput.current.value), "-", -9) };

    formData.append(
      "file",
      imgRef.current && imgRef.current.files && imgRef.current.files[0]
        ? imgRef.current.files[0]
        : new File(["foo"], "foo.txt", {
            type: "text/plain",
          }),
    );
    formData.append("data", JSON.stringify(data));
    formData.append("date", date.endAt);

    if (window.confirm("정말로 수정하시겠습니까?")) {
      mutate(formData);
      nav("/admin-sketch/notices/manage/0");
    }
  };

  const previewHandler = () => {
    dispatch(setAdminNoticeData({ img_path: imgSrc, url }));
    dispatch(onNoticeModal());
  };

  return (
    <>
      <NoticePreview />
      <Wrapper>
        <Name>Notice</Name>
        <TableWrapper>
          <span>
            <Rectangle />
            작성하기
          </span>
          <table>
            <tbody>
              <tr>
                <td>제목</td>
                <td colSpan={3}>
                  <Title
                    defaultValue={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="제목을 입력해주세요"
                  />
                </td>
              </tr>
              <tr>
                <td>종료일</td>
                <td>
                  <SelectWrapper>
                    <input type="datetime-local" ref={dateInput} />
                  </SelectWrapper>
                </td>
                <td>게시 여부</td>
                <td>
                  <SelectWrapper>
                    <select ref={activeRef} onChange={(e) => setIsActive(e.target.value === "true")}>
                      <option value="true">게시</option>
                      <option value="false">미게시</option>
                    </select>
                  </SelectWrapper>
                </td>
              </tr>
              <tr>
                <td>하이퍼링크</td>
                <td>
                  <Title
                    defaultValue={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="공지 클릭시 이동할 링크(선택)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        {/* 이미지 고르기 */}
        <ThumbnailWrapper>
          <div>메인 이미지</div>
          <div>
            <img src={imgSrc} alt="파일을 선택하세요" />
            <UploadImage type="file" id="img" onChange={imgUploadHandler} accept="image/*" ref={imgRef} />
            <UploadImageLabel htmlFor="img">파일 선택</UploadImageLabel>
          </div>
        </ThumbnailWrapper>

        <BottomWrapper>
          <div>
            <BottomBtn onClick={() => nav(-1)}>이전</BottomBtn>
            <BottomBtn onClick={() => nav("/admin-sketch/news/manage/0")}>목록</BottomBtn>
          </div>
          <div>
            <BottomBtn onClick={previewHandler} bgColor={"#727582"} fontColor={"white"}>
              미리보기
            </BottomBtn>
            <BottomBtn onClick={upLoadHandler} bgColor={"#3D4359"} fontColor={"white"}>
              저장
            </BottomBtn>
          </div>
        </BottomWrapper>
      </Wrapper>
    </>
  );
};

export default ModifyNotice;
