import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding-left: 5rem;
  background-color: white;
  color: black;
  padding-bottom: 20rem;
  margin-top: -5rem;
  width: 100%;
  font-size: 1.6rem;
  line-height: 3rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    padding-left: 3rem;
  }

  .jang {
    font-size: 4rem;
    font-weight: bold;
    margin: 10rem 0 5rem;
  }

  .jo {
    font-size: 3rem;
    margin: 3rem 0;
    font-weight: 600;
  }

  .head {
    font-size: 5rem;
    text-align: center;
    padding-top: 10rem;
    font-weight: bold;
    margin-bottom: 5rem;
  }

  table {
    margin: auto;
    width: 100%;
  }

  table,
  th,
  td {
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
    vertical-align: middle;
  }

  th {
    font-weight: bold;
    background-color: lightgray;
  }

  th,
  td {
    padding: 1rem 2rem;
  }
  div,
  li {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  div {
    font-size: 2rem;
  }
  li {
    margin-left: 5rem;
  }
  ol {
    list-style: decimal;
  }

  ul {
    list-style: circle;
  }
`;
