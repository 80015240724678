import { useNavigate } from "react-router";

export const useNavigatePage = () => {
  const nav = useNavigate();

  return function Navigation(to: number | string) {
    if (typeof to === "number") {
      nav(to);
    } else {
      nav(to);
    }
    window.scrollTo({ top: 0, behavior: "auto" });
  };
};
