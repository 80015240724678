import { BLACK_0, BLACK_3, BLACK_5, BLACK_9, MAIN_GREEN, WHITE } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  z-index: 999;
  overflow: auto;
`;

export const Container = styled.div`
  position: relative;
  top: 18rem;
  margin: auto;
  width: 80rem;
  background-color: black;
  border-radius: 0.5rem;
  padding: 3rem;

  & > :nth-child(2) {
    display: flex;
    justify-content: end;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    top: 0rem;
    border-radius: 0rem;
    padding: 0;
    width: 100%;
  }
`;

export const MobileCloseBtn = styled.div`
  display: none;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    background-color: black;
    padding: 1.8rem 2rem;

    & > img {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

export const SupportBox = styled.div`
  width: 100%;
  background-color: black;
  margin-top: 3rem;
  border-radius: 0.5rem;
  color: white;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 5.4rem;
  }

  & > div:nth-child(2) {
    display: flex;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${BLACK_9};

    & > div:nth-child(2n) {
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & > div:nth-child(3) {
    margin-top: 1.3rem;
    width: 100%;
    height: 100%;
    color: ${MAIN_GREEN};
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    margin-top: 0rem;
    border-radius: 0rem;
    padding: 4rem 2rem;

    & > div:nth-child(1) {
      font-size: 2.4rem;
      line-height: 4.2rem;
    }

    & > div:nth-child(2) {
      margin-top: 0.5rem;
      display: flex;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: ${BLACK_5};

      & > div:nth-child(2n) {
        width: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & > div:nth-child(3) {
      margin-top: 2rem;
      width: 100%;
      height: 18.8rem;
    }
  }
`;

export const LanguageSelector = styled.div`
  width: 9.6rem;
  height: 3rem;
  border: 1px solid ${BLACK_3};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const KOR = styled.span`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${(props) => (props.isKor ? `${BLACK_0}` : `${WHITE}`)};
  background-color: ${(props) => (props.isKor ? `${MAIN_GREEN}` : "")};
  font-weight: ${(props) => (props.isKor ? 500 : 300)};

  &:hover {
    color: ${(props) => (props.isKor ? `black` : MAIN_GREEN)};
  }
`;
export const ENG = styled.span`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${(props) => (!props.isKor ? `${BLACK_0}` : `${WHITE}`)};
  background-color: ${(props) => (!props.isKor ? `${MAIN_GREEN}` : "")};
  font-weight: ${(props) => (!props.isKor ? 500 : 300)};

  &:hover {
    color: ${(props) => (!props.isKor ? `black` : MAIN_GREEN)};
  }
`;
