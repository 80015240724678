import { BLACK_d } from "baseStyles";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80rem;
  height: 80rem;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  font-size: 3rem;
  border: 1px solid black;
  border-radius: 1rem;
  margin-top: 20rem;
`;

export const InputContainer = styled.div`
  width: fit-content;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;

  & > div {
    width: 5rem;
    justify-content: center;
    align-items: center;
  }

  & > input {
    font-size: 3rem;
  }
`;

export const LoginBtn = styled.div`
  width: 30rem;
  height: 10rem;
  margin-top: 10rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${BLACK_d};
  font-size: 5rem;
  :hover {
    color: white;
    background-color: black;
    cursor: pointer;
  }
`;
