import NoticeModal from "components/Modal/NoticeModal";
import { useSelector } from "react-redux";
import { selectActiveNoticeModal, selectAdminNoticeData } from "redux/reducers/noticeModalReducer";

const NoticePreview = () => {
  const isActive = useSelector(selectActiveNoticeModal);
  const notice = useSelector(selectAdminNoticeData);

  return isActive ? <NoticeModal data={notice} /> : null;
};

export default NoticePreview;
