import React, { useEffect } from "react";
import { CloseBtnContainer, Container, MobileCloseBtn, NewsBox, Wrapper } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { offNewsModal, selectActiveNewsModal, selectNewsModalData } from "redux/reducers/newsModalReducer";
import { selectLanguage } from "redux/reducers/languageReducer";
import useDeleteStyle from "hooks/useDeleteStyle";
import { IMG_CDN_URL } from "constants/pathConstant";
import { dateFormat } from "components/dateFormat";
const NewsModal = () => {
  const isActiveNewsModal = useSelector(selectActiveNewsModal);
  const newsModalData = useSelector(selectNewsModalData);
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const deleteStyle = useDeleteStyle();

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.overflow = "unset";
    };
  }, []);

  const closeModalHandler = (e) => {
    if (e.target === e.currentTarget) dispatch(offNewsModal());
  };
  const closeBtnHandler = () => {
    dispatch(offNewsModal());
  };

  return (
    <>
      {isActiveNewsModal && newsModalData && (
        <Wrapper onClick={closeModalHandler}>
          <Container>
            <MobileCloseBtn>
              <img
                loading="lazy"
                src={`${IMG_CDN_URL}/file/20c6ee93arrow_left-min.webp`}
                alt=""
                onClick={closeBtnHandler}
              />
            </MobileCloseBtn>
            <CloseBtnContainer>
              <img
                loading="lazy"
                onClick={closeBtnHandler}
                src={`${IMG_CDN_URL}/file/31456803x-min.webp`}
                alt="closeBtn"
              />
            </CloseBtnContainer>
            <NewsBox>
              <div>{language === "en" ? newsModalData.titleEng : newsModalData.titleKr}</div>
              <div>
                <div>{dateFormat(newsModalData.date, ".").substring(0, 10)}</div>
                <div>|</div>
                <div>{dateFormat(newsModalData.date, ".").substring(11, 16)}</div>
                <div>|</div>
                <div>{newsModalData.region}</div>
              </div>
              <div>
                <img
                  loading="lazy"
                  src={`${IMG_CDN_URL}/${language === "en" ? newsModalData.imgPathEng : newsModalData.imgPathKr}`}
                  alt="newsImage"
                />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: deleteStyle(language === "en" ? newsModalData.contentEng : newsModalData.contentKr),
                }}
              ></div>
            </NewsBox>
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default NewsModal;
