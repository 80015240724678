import {
  BottomBtn,
  BottomContainer,
  ContentBox1,
  ContentBox2,
  ContentNav,
  DeleteContainer,
  DeleteTextBox,
  MainContent,
  SearchBox,
  SearchBtn,
  SearchInput,
  SearchSelect,
  TextBox,
  TopContainer,
  Wrapper,
} from "./style";
import { useEffect, useRef, useState } from "react";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useLoginCheck } from "hooks/useLoginCheck";
import { axiosInstanceAdmin } from "api";
import { logoutAdmin } from "redux/reducers/adminReducer";
import Loading from "components/Loading";
import { setSupportModal, setSupportModalOnlyData } from "redux/reducers/supportModalReducer";
import SupportPagination from "components/Pagination/SupportPagination";
import { dateFormat } from "components/dateFormat";

const SupportHome = () => {
  const actionRef = useRef(null);
  const nav = useNavigatePage();
  const dispatch = useDispatch();
  const loginCheck = useLoginCheck();
  const queryClient = useQueryClient();

  const [count, setCount] = useState("");
  const [contents, setContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [checked, setChecked] = useState([]);
  const [rowId] = useState([]);

  const { data, isSuccess, isLoading, isError } = useQuery("manage_support", () => axiosInstanceAdmin.get("/support"), {
    onSuccess: (res) => {
      setContents(pageResult(res[0].supports)[currentPage]);
      setCount(res[0].supports.length);
    },
  });

  const { mutate } = useMutation((data) => axiosInstanceAdmin.put("/support/batchupdate", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("manage_support");
      setChecked([]);
    },
    onError: () => alert("적용 실패 - 개발자에게 문의하세요."),
  });

  const { mutate: deleteMutate } = useMutation((data) => axiosInstanceAdmin.patch(`/support`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("manage_support");
      setChecked([]);
    },
    onError: () => alert("적용 실패 - 개발자에게 문의하세요."),
  });

  // 로그인 체크
  useEffect(() => {
    loginCheck();
  }, []);

  const pageResult = (data, size = 10) => {
    const result = [];
    for (let i = 0; i < data.length; i += size) {
      result.push(data.slice(i, i + size));
    }
    return result;
  };

  useEffect(() => {
    if (!data) return;
    setContents(pageResult(data[0].supports)[currentPage]);
    setCount(data[0].supports.length);
  }, [currentPage]);

  if (isError) {
    dispatch(logoutAdmin());
    alert("새로 고침 후 로그인을 다시 해주세요");
    nav("/admin-sketch");
  }

  if (isLoading) return <Loading />;
  if (!isSuccess) return <Loading />;

  const allCheckHandler = (e) => {
    if (e.target.checked) {
      const tempArr = [];
      contents.forEach((el) => tempArr.push(el.supportId));
      setChecked(tempArr);
    } else {
      setChecked([]);
    }
  };

  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  const multiManageHandler = () => {
    const temp = [...checked].map((v) => {
      if (actionRef.current.value === "delete") {
        return { supportId: v };
      } else {
        return { supportId: v, publicYn: actionRef.current.value };
      }
    });
    manageHandler(...temp);
  };

  const manageHandler = (...args) => {
    if (!checked.length) return alert("항목을 선택해주세요.");
    if (actionRef.current.value === "delete") {
      if (window.confirm("적용 하시겠습니까?")) deleteMutate(args);
    } else {
      if (window.confirm("적용 하시겠습니까?")) mutate(args);
    }
  };

  const openSupportModal = (id) => {
    const selectedData = data[0].supports.find((v) => v.supportId === id);
    dispatch(setSupportModal(selectedData));
  };

  const modifyHandler = (id) => {
    const selectedData = data[0].supports.find((v) => v.supportId === id);
    dispatch(setSupportModalOnlyData(selectedData));
    nav("/admin-sketch/support/modify");
  };

  return (
    <Wrapper>
      {/* 상단 */}
      <TopContainer>
        {/* 맨위 이름, 검색 */}
        <ContentBox1>
          <TextBox>FAQ Manage</TextBox>
          <SearchBox>
            <SearchSelect>
              <option value="">제목 + 내용</option>
              <option value="">작성자</option>
            </SearchSelect>
            <SearchInput />
            <SearchBtn>검색</SearchBtn>
          </SearchBox>
        </ContentBox1>
        {/* 내용 */}
        <ContentBox2>
          <ContentNav>
            <span>
              <input
                type="checkbox"
                checked={checked.length === contents?.length ? true : false}
                onChange={(e) => allCheckHandler(e)}
              />
            </span>
            <span>번호</span>
            <span>제목</span>
            <span>작성자</span>
            <span>카테고리</span>
            <span>작성일</span>
            <span>수정일</span>
            <span>마지막 수정인</span>
            <span>게시 여부</span>
            <span>수정</span>
          </ContentNav>
          {contents &&
            contents.map((support, idx) => (
              <MainContent key={support.supportId}>
                <span>
                  <input
                    type="checkbox"
                    checked={checked.includes(support.supportId) ? true : false}
                    onChange={(e) => checkHandler(e, support.supportId)}
                  />
                </span>
                <span>{idx + 1}</span>
                <span onClick={() => openSupportModal(support.supportId)}>
                  {support.titleKr} ({support.supportId})
                </span>
                <span>{support.createdName}</span>
                <span>{support.catCode.catNameKr}</span>
                <span>{dateFormat(support.createdAt).slice(0, 16)}</span>
                <span>
                  {support.updatedAt
                    ? dateFormat(support.updatedAt).slice(0, 16)
                    : dateFormat(support.createdAt).slice(0, 16)}
                </span>
                <span>{support.updatedName ? support.updatedName : support.createdName}</span>
                <span>{support.publicYn === "Y" ? "게시" : "미게시"}</span>
                <span onClick={() => modifyHandler(support.supportId)}>수정</span>
                <div style={{ display: "none" }}>{(rowId[idx] = support.supportId)}</div>
              </MainContent>
            ))}
        </ContentBox2>
      </TopContainer>

      {/* 페이지네이션 */}
      <SupportPagination count={count} setCurrentPage={setCurrentPage} currentPage={currentPage} admin={true} />

      {/* 아래 부분 */}
      <BottomContainer>
        <DeleteContainer>
          <span>선택한 항목을</span>
          <DeleteTextBox ref={actionRef}>
            <option value="Y">게시</option>
            <option value="N">미게시</option>
            <option value="delete">삭제</option>
          </DeleteTextBox>
          <BottomBtn onClick={() => multiManageHandler()}>적용</BottomBtn>
        </DeleteContainer>
        <BottomBtn onClick={() => nav("/admin-sketch/support/upload")}>작성하기</BottomBtn>
      </BottomContainer>
    </Wrapper>
  );
};

export default SupportHome;
