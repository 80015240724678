import { createSlice } from "@reduxjs/toolkit";

export const NewsModalSlice = createSlice({
  name: "newsModal",

  initialState: {
    activeNewsModal: false,
    newsModalData: {
      title: "",
      created_at: "",
      region: "",
      img_path: "",
      content: "",
    },
  },

  reducers: {
    setNewsModal: (state, action) => {
      state.newsModalData = action.payload;
      state.activeNewsModal = true;
    },
    onNewsModal: (state) => {
      state.activeNewsModal = true;
    },
    offNewsModal: (state) => {
      state.activeNewsModal = false;
    },
    setNewsModalOnlyData: (state, action) => {
      state.newsModalData = action.payload;
    },
  },
});

export const { setNewsModal, onNewsModal, offNewsModal, setNewsModalOnlyData } = NewsModalSlice.actions;
export const selectActiveNewsModal = (state: { newsModal: { activeNewsModal: boolean } }) =>
  state.newsModal.activeNewsModal;
export const selectNewsModalData = (state: { newsModal: { newsModalData: IData } }) => state.newsModal.newsModalData;

export default NewsModalSlice.reducer;

interface IData {
  title: string;
  created_at: string;
  region: string;
  img_path: string;
  content: string;
}
