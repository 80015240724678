import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 9rem;
  position: fixed;
  z-index: 9999;
`;

export const Container = styled.div`
  height: 100%;
  margin: 3.6rem 3rem 0;
  background: #0000004d;
  border-radius: 1.6rem;
  color: #fff;
  display: flex;
  font-size: 2rem;
  font-family: ${FONTS.B};
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem;

  & > div {
    display: flex;
    gap: 10rem;
  }

  p,
  h1 {
    cursor: pointer;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin: 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    padding: 2.3rem;
    height: 6.4rem;

    & > p {
      font-size: 1rem;
    }
  }
`;

export const Title = styled.h1<IStyle>`
  font-size: 3.2rem;
  margin-left: 26rem;
  font-weight: 700;
  letter-spacing: ${(props) => (props.lang ? "" : "1rem")};

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: block;
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`;

export const ImgBox = styled.div`
  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    min-width: 4rem;

    > div {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const LangBox = styled.div`
  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    min-width: 4rem;
    display: flex;
    justify-content: end;

    > p {
      font-size: 1rem;
    }
  }
`;

interface IStyle {
  lang: boolean;
}
