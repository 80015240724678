import { useState } from "react";
import { Viewer, LanguageSelector, KOR, ENG } from "./PreviewNewsMobile.style";
import { dateFormat } from "components/dateFormat";
/**
 *
 * @param {String} title
 * @param {Date} date
 * @param {String} region
 * @param {String} imgSrc
 * @param {String} htmlString
 * @returns
 */
const PreviewNewsMobile = ({ title, date, region, imgSrc, htmlString, imgSrcKr, htmlStringKr, titleKr }) => {
  const [isKor, setIsKor] = useState(true);

  const changeLanguageHandler = () => {
    setIsKor((prev) => !prev);
  };

  return (
    <Viewer>
      <div>
        <LanguageSelector onClick={changeLanguageHandler}>
          <KOR isKor={isKor}>KOR</KOR>
          <ENG isKor={isKor}>ENG</ENG>
        </LanguageSelector>
      </div>
      <div>{isKor ? titleKr : title}</div>
      <div>
        <div>{dateFormat(date, ".", 0).substring(0, 10)}</div>
        <div>|</div>
        <div>{dateFormat(date, ".", 0).substring(11, 16)}</div>
        <div>|</div>
        <div>{region}</div>
      </div>
      <div>
        <img loading="lazy" src={isKor ? imgSrcKr : imgSrc} alt="" id="preview" />
      </div>
      <div dangerouslySetInnerHTML={{ __html: isKor ? htmlStringKr : htmlString }}></div>
    </Viewer>
  );
};

export default PreviewNewsMobile;
