import React, { useEffect, useRef, useState } from "react";
import { SfuelContainer, SfuelExcelInput, SfuelWrapper, SfuelTableName, SfuelButtonBox } from "./Sfuel.style";
import { SfuelDetail } from "./SfuelDetail";
import axios from "axios";
import { BASE_URL } from "constants/pathConstant";
import { useNavigate } from "react-router";

export const Sfuel = () => {
  const [addressArr, setAddressArr] = useState([]);
  const [isDoneArr, setIsDoneArr] = useState([]);
  const [isFailArr, setIsFailArr] = useState([]);
  const [clicked, setClicked] = useState(0);
  const ref = useRef();

  const nav = useNavigate();
  const putExcelData = (excelRef) => {
    const excelStrings = excelRef.current.value.split(" ");
    setAddressArr(excelStrings);
    setIsDoneArr([]);
    setIsFailArr([]);
  };

  useEffect(() => {
    if (clicked === 5 && !ref.current.value.trim()) {
      nav("secret");
    }
  }, [clicked]);
  const balanceCheck = () => {
    if (addressArr.length === 0) {
      setClicked((prev) => prev + 1);
      return;
    }
    const requests = addressArr.map((add) => axios.get(`${BASE_URL}/sfuel/${add}`));

    Promise.all(requests)
      .then((res) => {
        const tmpDoneArr = [];
        const tmpFailArr = [];
        res.forEach((v) => {
          const balance = v.data.balances.calypso;
          if (balance === "0.005") {
            tmpDoneArr.push(true);
          } else {
            tmpDoneArr.push(false);
            tmpFailArr.push(v.data.address);
          }
        });
        setIsDoneArr(tmpDoneArr);
        setIsFailArr(tmpFailArr);
      })
      .catch(() => {});
  };

  const failCopy = () => {
    ref.current.value = isFailArr.join(" ");
    setAddressArr([...isFailArr]);
    setIsDoneArr([]);
  };

  return (
    <SfuelWrapper>
      <SfuelContainer>
        <SfuelExcelInput type="text" ref={ref} />
        <SfuelButtonBox>
          <button onClick={() => putExcelData(ref)}>액셀값 넣기!</button>
          <button onClick={balanceCheck}>밸런스 체크!</button>
          <button onClick={failCopy}>실패건 일괄 붙여넣기</button>
          <div>총: {addressArr.length}개</div>
          <div>미완료: {isFailArr.length}개</div>
        </SfuelButtonBox>
      </SfuelContainer>
      <SfuelTableName>
        <p>주소</p>
        <p>열기버튼</p>
        <p>처리</p>
        <p>밸런스체크</p>
      </SfuelTableName>
      {addressArr.length &&
        addressArr.map((arr, index) => <SfuelDetail arr={arr} index={index} isDoneArr={isDoneArr} />)}
    </SfuelWrapper>
  );
};
