import { BLACK_0, BLACK_1, BLACK_3, BLACK_5, MAIN_GREEN, WHITE } from "baseStyles";
import styled from "styled-components";

export const Viewer = styled.div`
  width: 37.5rem;
  height: fit-content;
  background-color: white;
  padding: 4rem 20px;
  margin-top: 20px;
  border: 2px solid gray;

  & > div:nth-child(2) {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 4.2rem;
  }

  & > div:nth-child(3) {
    margin-top: 0.5rem;
    display: flex;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${BLACK_5};

    & > div:nth-child(2n) {
      width: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & > div:nth-child(4) {
    width: 100%;
    height: 18.8rem;
    margin: 1.5rem auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
    }
  }

  & > div:nth-child(5) {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: ${BLACK_1};
  }
`;

export const LanguageSelector = styled.div`
  width: 9.6rem;
  height: 3rem;
  border: 1px solid ${BLACK_3};
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const KOR = styled.span`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${(props) => (props.isKor ? `${BLACK_0}` : `${WHITE}`)};
  background-color: ${(props) => (props.isKor ? `${MAIN_GREEN}` : `${BLACK_0}`)};
  font-weight: ${(props) => (props.isKor ? 500 : 300)};

  &:hover {
    color: ${(props) => (props.isKor ? `black` : MAIN_GREEN)};
  }
`;

export const ENG = styled.span`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${(props) => (!props.isKor ? `${BLACK_0}` : `${WHITE}`)};
  background-color: ${(props) => (!props.isKor ? `${MAIN_GREEN}` : `${BLACK_0}`)};
  font-weight: ${(props) => (!props.isKor ? 500 : 300)};

  &:hover {
    color: ${(props) => (!props.isKor ? `black` : MAIN_GREEN)};
  }
`;
