import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 6.4rem;
  background: #B5DFDA;
  color: #000;
  font-size: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    font-size: 1.3rem;
    height: 4.8rem;
  }

  & > p > :nth-child(2) {
    color: #000;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;
  }
`;

export const FakeWrapper = styled.div`
  width: 100%;
  height: 6.4rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    height: 4.8rem;
  }
`;
