import { IMG_CDN_URL } from "constants/pathConstant";
import { Arrow, Content, Title, Wrapper } from "./style";
import { useTranslation } from "react-i18next";

const Com2 = () => {
  const { t } = useTranslation("languageNewVer");

  return (
    <Wrapper>
      <Title>
        <p>{t("S_500170")}</p>
        <p>{t("S_500171")}</p>
      </Title>

      <Content.Wrapper>
        <Content.Container>
          <Content.Title>{t("S_500180")}</Content.Title>

          <Content.Box>
            <Content.BoxImg src={`${IMG_CDN_URL}/file/5cbb5102pig.svg`} alt="PoW Security" />
            <Content.BoxTitle>{t("S_500190")}</Content.BoxTitle>
            <Content.BoxContent>{t("S_500200")}</Content.BoxContent>
          </Content.Box>
        </Content.Container>

        <Arrow.Wrapper>
          {/* <Arrow.ArrowText forward={false}>{t("S_500210")}</Arrow.ArrowText> */}
          <Arrow.ArrowImg src={`${IMG_CDN_URL}/file/2c9b90f0arrow_l.svg`} alt="Swap Arrow" />
          {/* <Arrow.ArrowText forward={true}>{t("S_500220")}</Arrow.ArrowText> */}
        </Arrow.Wrapper>

        <Content.Container>
          <Content.Title>{t("S_500230")}</Content.Title>

          <Content.Box>
            <Content.BoxImg src={`${IMG_CDN_URL}/file/27a516d6time.svg`} alt="Fast Transaction" />
            <Content.BoxTitle>{t("S_500240")}</Content.BoxTitle>
            <Content.BoxContent>{t("S_500250")}</Content.BoxContent>
          </Content.Box>
        </Content.Container>
      </Content.Wrapper>
    </Wrapper>
  );
};

export default Com2;
