import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    padding: 0 20px;
    margin-top: 0;
  }

  & > div {
    width: 120rem;

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
    }
  }
`;
