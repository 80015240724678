import { axiosInstance } from "api";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { Container, InputContainer, LoginBtn, Wrapper } from "./style";
const Signup = () => {
  const [id, setId] = useState(null);
  const [pw, setPw] = useState(null);
  const [name, setName] = useState(null);
  const nav = useNavigate();

  const queryClient = useQueryClient();
  const { mutate } = useMutation((data) => axiosInstance.post("/admins/signup", data), {
    onSuccess: () => {
      alert("발급신청완료. 관리자에게 문의하세요.");
      queryClient.invalidateQueries("manage_admin");
      nav("/admin-sketch");
    },
    onError: () => alert("이미 존재하거나 사용 불가능한 ID 입니다."),
  });

  const loginHandler = () => {
    const data = { adminId: id, password: pw, name, roles: "ADMIN" };
    mutate(data);
  };

  return (
    <Wrapper>
      <Container>
        <InputContainer>
          <div>ID</div>
          <input type="text" onChange={(e) => setId(e.target.value)} />
        </InputContainer>
        <InputContainer>
          <div>PW</div>
          <input type="password" onChange={(e) => setPw(e.target.value)} />
        </InputContainer>
        <InputContainer>
          <div>이름(실명)</div>
          <input type="text" onChange={(e) => setName(e.target.value)} />
        </InputContainer>

        <LoginBtn onClick={loginHandler}>어드민 아이디 발급 신청</LoginBtn>
      </Container>
    </Wrapper>
  );
};

export default Signup;
