import { useNavigatePage } from "hooks/useNavigatePage";
import { Container, ImgBox, LangBox, Title, Wrapper } from "./style";
import { IMG_CDN_URL } from "constants/pathConstant";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeLanguage, selectLanguage } from "redux/reducers/languageReducer";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

const Header = ({ setIsClick }: { setIsClick: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation("languageNewVer");
  const nav = useNavigatePage();
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });

  return (
    <Wrapper>
      <Container>
        {isPc ? (
          <>
            <p onClick={() => nav("/apps")}>{t("S_600020")}</p>
            <Title lang={language === "en"} onClick={() => nav("/")}>
              {t("S_600010")}
            </Title>
            <div>
              <p onClick={() => nav("/news_events/0")}>{t("S_600030")}</p>
              <p onClick={() => nav("/support")}>{t("S_600040")}</p>
              <p onClick={() => dispatch(changeLanguage())}>{t("S_600050")}</p>
            </div>
          </>
        ) : (
          <>
            <ImgBox
              onClick={() => {
                setIsClick((prev) => !prev);
              }}
            >
              <div>
                <img src={`${IMG_CDN_URL}/file/a7e27c94menu-min.webp`} alt="hamberger_btn" />
              </div>
            </ImgBox>
            <Title lang={language === "en"} onClick={() => nav("/")}>
              {t("S_600010")}
            </Title>
            <LangBox>
              <p onClick={() => dispatch(changeLanguage())}>{t("S_600050")}</p>
            </LangBox>
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default Header;
