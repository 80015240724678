import {
  BottomBtn,
  BottomWrapper,
  ContentWrapper,
  Modal,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  Title,
  Wrapper,
  SelectBoxWrapper,
} from "./style";

import { useEffect, useRef, useState } from "react";
import { useNavigatePage } from "hooks/useNavigatePage";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor } from "react-draft-wysiwyg";
import { useMutation, useQueryClient } from "react-query";
import { axiosInstanceAdmin } from "api";
import PreviewSupportPc from "../Preview/Pc";
import PreviewSupportMobile from "../Preview/Mobile";
import { useLoginCheck } from "hooks/useLoginCheck";

const UploadSupport = ({ modifyData }) => {
  const nav = useNavigatePage();
  const catRef = useRef();
  const activeRef = useRef();
  const queryClient = useQueryClient();
  const loginCheck = useLoginCheck();

  const [category, setCategory] = useState("sketch_wallet");
  const [title, setTitle] = useState("");
  const [titleKr, setTitleKr] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateKr, setEditorStateKr] = useState(EditorState.createEmpty());
  const [htmlString, setHtmlString] = useState("");
  const [htmlStringKr, setHtmlStringKr] = useState("");
  const [isActive, setIsActive] = useState("Y");
  const [modal, setModal] = useState(false);
  const [supportId, setSupportId] = useState();

  useEffect(() => {
    // 로그인 체크
    if (!loginCheck) return;
    loginCheck();
  }, []);

  useEffect(() => {
    if (!modifyData) return;
    setSupportId(modifyData.supportId);
    setCategory(modifyData.catCode.id);
    catRef.current.value = modifyData.catCode.id;

    setIsActive(modifyData.publicYn);
    activeRef.current.value = modifyData.publicYn;

    setTitle(modifyData.titleEng);
    setTitleKr(modifyData.titleKr);
    setHtmlString(modifyData.contentEng);
    setHtmlStringKr(modifyData.contentKr);

    // 영어 contents 기본값 설정
    const blocksFromHtml = htmlToDraft(modifyData.contentEng);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)));
    }

    // 한국어 contents 기본값 설정
    const blocksFromHtmlKr = htmlToDraft(modifyData.contentKr);
    if (blocksFromHtmlKr) {
      const { contentBlocks, entityMap } = blocksFromHtmlKr;
      setEditorStateKr(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)));
    }
  }, [modifyData]);

  const catHandler = () => {
    setCategory(catRef.current.value);
  };

  const previewHandler = () => {
    setModal(true);
  };

  const modalCloseHandler = (e) => {
    if (e.target === e.currentTarget) {
      setModal(false);
    }
  };

  const updateTextDescription = async (state, isKorean) => {
    // 에디터에 글쓰기 위한 state
    isKorean ? setEditorStateKr(state) : setEditorState(state);

    let html = draftjsToHtml(
      convertToRaw(isKorean ? editorStateKr.getCurrentContent() : editorState.getCurrentContent()),
    );

    // 불완전한 <p></p> 태그 수정
    html = html.replace(/<p><\/p>/g, "<br />");

    // html 기본 폰트사이즈 1rem, px을 rem으로 바꾸는 정규식
    let matchWord = html.match(/font-size: \d{1,2}px;/gi);
    if (matchWord) {
      matchWord = matchWord.map((font) => {
        const int = font.match(/\d{1,2}/g);
        return `font-size: ${int / 10}rem`;
      });

      html = html.replace(/font-size: \d{1,2}px;/gi, matchWord);
    }
    // ---------------------이슈 : &nbsp; 가 임의로 사라져버려서 띄어쓰기는 연속 2번이상 불가
    isKorean ? setHtmlStringKr(html) : setHtmlString(html);
  };

  // 게시글 업로드 handler
  const { mutate } = useMutation((data) => axiosInstanceAdmin.post("/support", data), {
    onSuccess: () => queryClient.invalidateQueries("manage_support"),
  });

  const { mutate: updateMutate } = useMutation((data) => axiosInstanceAdmin.put("/support", data), {
    onSuccess: () => queryClient.invalidateQueries("manage_support"),
  });

  const upLoadHandler = () => {
    if (!title || !htmlString || !category || !titleKr || !htmlStringKr) {
      alert("빠진 내용 없이 다 채워주세요");
      return;
    }

    const data = {
      supportId: modifyData && supportId,
      titleKr,
      titleEng: title,
      contentKr: htmlStringKr,
      contentEng: htmlString,
      catCode: catRef.current.value,
      publicYn: isActive,
    };

    if (modifyData) {
      // 수정할 경우
      if (window.confirm("수정하시겠습니까?")) {
        updateMutate(data);
        nav("/admin-sketch/support/manage");
      }
    } else {
      // 새 글을 작성하는 경우
      if (window.confirm("등록하시겠습니까?")) {
        mutate(data);
        nav("/admin-sketch/support/manage");
      }
    }
  };

  return (
    <Wrapper>
      <Name>FAQ</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          작성하기
        </span>
        <div>
          <SelectBoxWrapper>
            <div>카테고리</div>
            <SelectWrapper>
              <select name="category" id="category" ref={catRef} onChange={catHandler}>
                <option value="sketch_wallet">sketch wallet</option>
                <option value="sketch_pay">sketch pay</option>
                <option value="sketch_store_wallet">sketch store wallet</option>
                <option value="use_to_earn">use to earn</option>
                <option value="etc">etc</option>
              </select>
            </SelectWrapper>
          </SelectBoxWrapper>
          <SelectBoxWrapper>
            <div>공개 여부</div>
            <SelectWrapper>
              <select onChange={(e) => setIsActive(e.target.value)} ref={activeRef}>
                <option value="Y">공개</option>
                <option value="N">비공개</option>
              </select>
            </SelectWrapper>
          </SelectBoxWrapper>
        </div>
      </TableWrapper>
      <div>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(한국어)
          </span>
          <div>
            <Title
              onChange={(e) => setTitleKr(e.target.value)}
              defaultValue={modifyData?.titleKr || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorStateKr}
            onEditorStateChange={(state) => updateTextDescription(state, true)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "80rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(영어)
          </span>
          <div>
            <Title
              onChange={(e) => setTitle(e.target.value)}
              defaultValue={modifyData?.titleEng || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorState}
            onEditorStateChange={(state) => updateTextDescription(state, false)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "80rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
      </div>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav(-1)}>이전</BottomBtn>
          <BottomBtn onClick={() => nav("/admin-sketch/support/manage/0")}>목록</BottomBtn>
        </div>
        <div>
          <BottomBtn onClick={previewHandler} bgColor={"#727582"} fontColor={"white"}>
            미리보기
          </BottomBtn>
          <BottomBtn onClick={upLoadHandler} bgColor={"#3D4359"} fontColor={"white"}>
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {/* 미리보기 모달 */}
      {modal && (
        <Modal onClick={modalCloseHandler}>
          <PreviewSupportPc title={title} htmlString={htmlString} titleKr={titleKr} htmlStringKr={htmlStringKr} />
          <PreviewSupportMobile title={title} htmlString={htmlString} titleKr={titleKr} htmlStringKr={htmlStringKr} />
        </Modal>
      )}
    </Wrapper>
  );
};

export default UploadSupport;
