import Com1 from "./components/Com1";
import Com2 from "./components/Com2";
import Com3 from "./components/Com3";

const Apps = () => {
  return (
    <div>
      <Com1></Com1>
      <Com2></Com2>
      <Com3></Com3>
    </div>
  );
};

export default Apps;
