import { FONTS, MAIN_GREEN } from "baseStyles";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 8rem 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  & > p {
    font-size: 1.4rem;
    font-family: ${FONTS.S8};
  }

  & > textarea {
    width: 100%;
    height: 20rem;
    resize: none;
    font-size: 1.4rem;
    padding: 1.5rem;
    background: #000;
    border-radius: 0.5rem;
    font-family: ${FONTS.B};
    border: 5px solid #787878;
    color: #fff;
    box-sizing: border-box;
  }

  & > :nth-last-child(1) {
    border: 1px solid #B1E5E2;
    color: #B1E5E2;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    border-radius: 10rem;
    background: rgba(177, 229, 226, 0.12);
    cursor: pointer;
    margin-bottom: 6rem;
  }
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  border: 5px solid #B1E5E2;
  border-radius: 0.5rem;
  align-items: center;
  padding-right: 1.5rem;
  justify-content: space-between;
  position: relative;

  > input {
    width: 100%;
    font-size: 1.6rem;
    padding: 1.5rem;
    background: #000;
    border: none;
    color: white;
    outline: none;
    border-radius: 0.5rem;
  }

  > div {
    min-width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  & > span {
    position: absolute;
    top: -0.65rem;
    left: 2.5rem;
    background: #000;
    padding: 0 0.5rem;
    color: #B1E5E2;
  }
`;
