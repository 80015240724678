import styled from "styled-components";

export const SfuelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 20rem;
`;

export const SfuelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
`;

export const SfuelButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;

  & > div {
    font-size: 2rem;
  }
`;

export const SfuelExcelInput = styled.input`
  width: 30rem;
  height: 40rem;
`;

export const OpenedUrl = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => (props.clicked ? "green" : "tomato")};
`;

export const SfuelTableName = styled.div`
  display: flex;
  font-size: 1.5rem;
  width: 100%;
  gap: 5rem;
  justify-content: center;

  & > :nth-child(1) {
    width: 18rem;
  }

  & > :nth-child(2) {
    width: 4rem;
  }

  & > :nth-child(3) {
    width: 2rem;
  }

  & > :nth-child(4) {
    width: 2rem;
  }
`;
