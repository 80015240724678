const { useRef, useEffect } = require("react");

export const useScrollFadeIn = (...refs) => {
  return useEffect(() => {
    refs.forEach((ref) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            ref.current.classList.add("fade-in-visible");
          }
        });
      });

      observer.observe(ref.current);

      return () => observer.unobserve(ref.current);
    });
  }, []);
};
