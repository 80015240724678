import { Wrapper, ImgBox, ListWrapper, IconBox } from "./style";
import data from "./linkData.json";
import { MouseEvent, useState } from "react";

const IconCom = () => {
  const [isClick, setIsClick] = useState<number | null>(null); // State to track expanded icon

  const handleClick = (idx: number, e: MouseEvent<HTMLDivElement>, url?: string) => {
    e.stopPropagation();

    if (data[idx].list) {
      setIsClick(isClick === idx ? null : idx);
    } else {
      window.open(url, "_blank");
      setIsClick(null);
    }
  };

  return (
    <Wrapper onClick={() => setIsClick(null)}>
      {data.map((v, idx) => (
        <IconBox key={idx}>
          <div onClick={(e) => handleClick(idx, e, v.url)}>
            <ImgBox src={v.icon_path} alt={`${v.title} icon`} icon={v.title} />
          </div>
          {isClick === idx && v.list && (
            <ListWrapper>
              {v.list.map((listV, listIdx) => (
                <p key={listIdx} onClick={() => window.open(listV.url, "_blank")}>
                  {listV.title}
                </p>
              ))}
            </ListWrapper>
          )}
        </IconBox>
      ))}
    </Wrapper>
  );
};

export default IconCom;
