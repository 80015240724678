import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    height: 80rem;
    margin: 15rem 22rem 0;
    display: flex;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    margin-top: 8rem;
  }
`;

export const Support = {
  Wrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 150rem;
      height: 80rem;
      border-radius: 2.4rem;
      background: rgba(62, 62, 62, 0.5);
      display: flex;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,

  Container: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
      padding: 6.4rem 6.4rem 0;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    }
  `,

  Title: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-bottom: 12.2rem;

      & > p {
        color: #eee;
        font-family: ${FONTS.S8};
        font-size: 4.8rem;
        line-height: 1.3;
      }
    }
    
    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      & > p {
        color: #eee;
        font-family: ${FONTS.S8};
        font-size: 3.6rem;
        text-align: center;
        line-height: 1.3;
      }
    }
  `,
};

export const Mail = {
  Wrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      gap: 1.6rem;
      margin-bottom: 1.8rem;
      align-items: center;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      gap: 1.6rem;
      margin-top: 3.4rem;
      align-items: center;
      justify-content: center;
    }
  `,

  Logo: styled.img`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 3.2rem;
      height: 3.2rem;
    }
    
    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 3.2rem;
      height: 3.2rem;
    }
  `,

  Content: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;
      font-family: ${FONTS.B};
      font-size: 2rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #8d8d8d;
      font-family: ${FONTS.B};
      font-size: 2rem;
    }
  `,
};

export const Address = {
  Wrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      gap: 1.6rem;
      align-items: center;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: none;
    }
  `,
  Logo: styled.img`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 3.2rem;
      height: 3.2rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    }
  `,
  Content: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      & > p {
        color: #fff;

        font-family: ${FONTS.B};
        font-size: 2rem;
      }
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    }
  `,
};

export const Contact = {
  Wrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 60rem;
      height: 100%;
      border-radius: 2.4rem 2.4rem 0 0;
      padding: 4.8rem 4.8rem 0;
      background: #eee;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-top: 5.6rem;
      width: 26rem;
    }
  `,
  Title: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #000;
      font-family: ${FONTS.B};
      font-size: 2rem;
    }
    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;
      font-family: ${FONTS.EB};
      font-size: 2.4rem;
      line-height: 1.2;
    }
  `,

  SubjectWrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      gap: 1.6rem;
      flex-wrap: wrap;
      margin-top: 2.4rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-top: 2.1rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  `,

  SubjectBtn: styled.div<IStyle>`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
      padding: 1.6rem;
      font-family: ${FONTS.M};
      font-size: 2rem;
      border-radius: 0.8rem;
      width: fit-content;
      border: 0.2rem solid rgba(120, 120, 120, 0.3);
      background-color: ${(props) => (props.selected ? "#B1E5E2" : "transparent")};
      color: ${(props) => (props.selected ? "black" : "#515151")};
      border: ${(props) => (props.selected ? "0.2rem solid #B1E5E2" : "0.2rem solid rgba(120, 120, 120, 0.3)")};
      background-color: ${(props) => (props.selected ? "#B1E5E2" : "transparent")};
      cursor: pointer;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      padding: 1.6rem;
      font-family: ${FONTS.M};
      font-size: 2rem;
      border-radius: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => (props.selected ? "black" : "#dcdcdc")};
      border: ${(props) => (props.selected ? "0.2rem solid #B1E5E2" : "0.2rem solid rgba(120, 120, 120, 0.3)")};
      background-color: ${(props) => (props.selected ? "#B1E5E2" : "transparent")};
      cursor: pointer;
    }
  `,

  TextWrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-top: 6.4rem;
      display: flex;
      flex-direction: column;
      gap: 6.4rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      flex-direction: column;
      margin-top: 10rem;
      gap: 4rem;
    }
  `,

  Input: styled.input<IStyle>`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #818181;
      font-family: ${FONTS.B};
      font-size: 2rem;
      border-width: 0 0 0.3rem;
      outline: none;
      background-color: transparent;
      border-color: ${(props) => (props.length === 0 ? "#CBCBCB" : "#787878")};

      &::placeholder {
        color: #818181;
      }
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #818181;
      font-family: ${FONTS.B};
      font-size: 2rem;
      border-width: 0 0 0.3rem;
      outline: none;
      background-color: transparent;
      border-color: ${(props) => (props.length === 0 ? "rgba(120, 120, 120, 0.30)" : "#DCDCDC")};

      &::placeholder {
        color: #818181;
      }
    }
  `,

  TextArea: styled.textarea<IStyle>`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #818181;
      font-family: ${FONTS.B};
      font-size: 2rem;
      border-width: 0.3rem;
      border-radius: 1rem;
      padding: 1rem;
      outline: none;
      background-color: transparent;
      height: 8rem;
      resize: none;
      border-color: ${(props) => (props.length === 0 ? "#CBCBCB" : "#787878")};
      &::placeholder {
        color: #818181;
      }
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #818181;
      font-family: ${FONTS.B};
      font-size: 2rem;
      border-width: 0.3rem;
      border-radius: 1rem;
      padding: 1rem;
      outline: none;
      background-color: transparent;
      height: 8rem;
      resize: none;
      border-color: ${(props) => (props.length === 0 ? "rgba(120, 120, 120, 0.30)" : "#dcdcdc")};

      &::placeholder {
        color: #818181;
      }
    }
  `,

  BtnWrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      margin-top: 5rem;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    }
  `,

  SendBtn: styled.div<IStyle>`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      padding: 2.4rem 6.4rem;
      border-radius: 1.6rem;
      background: ${(props) => (props.pass ? "#B1E5E2" : "rgba(177, 229, 226, 0.5)")};
      display: flex;
      gap: 1.6rem;
      align-items: center;
      /* cursor: pointer; */
      cursor: ${(props) => (props.pass ? "pointer" : "default")};
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      padding: 1rem 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3.4rem auto 15rem;
      border-radius: 10rem;
      border: 1px solid #B1E5E2;
      width: 15rem;
      cursor: pointer;
      background: ${(props) => (props.pass ? "#B1E5E2" : "rgba(177, 229, 226, 0.5)")};
      color: ${(props) => (props.pass ? "black" : "white")};
    }
  `,

  SendLogo: styled.img`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 2.4rem;
      height: 2.4rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: none;
    }
  `,

  SendText: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #000;
      font-family: ${FONTS.B};
      font-size: 2rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      font-family: ${FONTS.B};
      font-size: 1.4rem;
    }
  `,
};

interface IStyle {
  selected?: boolean;
  pass?: boolean;
  length?: number;
}
