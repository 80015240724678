import { PC_LAYOUT_LENGTH } from "constants/UIConstants";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const LayOutContainer = styled.div`
  margin: auto;
  background: #000;

  @media screen and (max-width: ${PC_LAYOUT_LENGTH}) {
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
  }
`;

export const AdminLayOutContainer = styled(LayOutContainer)`
  background: #fff;
  color: #000;
  padding-bottom: 10rem;
`;
