import { axiosInstanceAdmin } from "api";
import Loading from "components/Loading";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "redux/reducers/adminReducer";
import {
  BottomBtn,
  BottomContainer,
  ContentBox1,
  ContentBox2,
  ContentNav,
  DeleteContainer,
  DeleteTextBox,
  MainContent,
  SearchBox,
  SearchBtn,
  SearchInput,
  SearchSelect,
  TextBox,
  TopContainer,
  Wrapper,
} from "./style";

import { useLoginCheck } from "hooks/useLoginCheck";
import { useParams } from "react-router";
import AdminPagination from "pages/Admin/AdminPagination";
import { setNewsModal, setNewsModalOnlyData } from "redux/reducers/newsModalReducer";
import { dateFormat } from "components/dateFormat";

const ManageAdmin = () => {
  const nav = useNavigatePage();
  const dispatch = useDispatch();
  const actionRef = useRef(null);
  const loginCheck = useLoginCheck();
  const queryClient = useQueryClient();
  const { page } = useParams();

  const [pageNum, setPageNum] = useState(0);
  const [checked, setChecked] = useState([]);
  const [rowId] = useState([]);

  const { data, isSuccess, isLoading, isError } = useQuery("manage_news" + page, () =>
    axiosInstanceAdmin.get("news", { params: { page, size: 10 } }),
  );

  const { mutate } = useMutation((data) => axiosInstanceAdmin.patch("/news", { data }), {
    onSuccess: () => {
      queryClient.invalidateQueries("manage_news" + page);
      setChecked([]);
    },
    onError: () => alert("적용 실패 - 개발자에게 문의하세요."),
  });

  // 로그인 체크
  useEffect(() => {
    loginCheck();
  }, []);

  useEffect(() => {
    if (page < 0) {
      nav("/admin-sketch/news/manage/0");
      return;
    }
    setPageNum(page);
  }, [page]);

  useEffect(() => {
    if (!data) return;
    if (parseInt(page) + 1 > data.totalPages && page !== "0") {
      nav(`/admin-sketch/news/manage/${data.totalPages - 1}`);
    }
  }, [data]);

  if (isError) {
    dispatch(logoutAdmin());
    alert("새로 고침 후 로그인을 다시 해주세요");
    nav("/admin-sketch");
  }

  // 전체 선택
  const allCheckHandler = (e) => {
    if (e.target.checked) {
      const tempArr = [];
      data.content.forEach((el) => tempArr.push(el.id));
      setChecked(tempArr);
    } else {
      setChecked([]);
    }
  };

  // 단일 선택
  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  // 게시 상태 핸들러
  const multiManageHandler = () => {
    const temp = [...checked].map((v) => {
      return { id: v, value: actionRef.current.value };
    });
    manageHandler(...temp);
  };

  // 게시 상태 변경 API
  const manageHandler = (...args) => {
    if (!checked.length) return alert("항목을 선택해주세요.");
    if (window.confirm("적용 하시겠습니까?")) mutate(args);
  };

  const openNewsModal = (id) => {
    const selectedData = data.content.find((v) => v.id === id);
    dispatch(setNewsModal(selectedData));
  };

  // 수정 버튼 누를 경우 전역에 데이터 저장
  const modifyHandler = (id) => {
    const selectedData = data.content.find((v) => v.id === id);
    dispatch(setNewsModalOnlyData(selectedData));
    nav("/admin-sketch/news/modify");
  };

  if (isLoading) return <Loading />;
  if (!isSuccess) return <Loading />;

  return (
    <Wrapper>
      {/* 상단 */}
      <TopContainer>
        {/* 맨위 이름, 검색 */}
        <ContentBox1>
          <TextBox>News Manage</TextBox>
          <SearchBox>
            <SearchSelect>
              <option value="">제목 + 내용</option>
              <option value="">작성자</option>
            </SearchSelect>
            <SearchInput />
            <SearchBtn>검색</SearchBtn>
          </SearchBox>
        </ContentBox1>
        {/* 내용 */}
        <ContentBox2>
          <ContentNav>
            <span>
              <input
                type="checkbox"
                onChange={(e) => allCheckHandler(e)}
                checked={checked.length === data.content.length ? true : false}
              />
            </span>
            <span>번호</span>
            <span>제목</span>
            <span>작성자</span>
            <span>위치</span>
            <span>게시일</span>
            <span>수정일</span>
            <span>마지막 수정인</span>
            <span>게시 여부</span>
            <span>수정</span>
          </ContentNav>
          {data &&
            data.content.map((news, idx) => (
              <MainContent key={news.id}>
                <span>
                  <input
                    type="checkbox"
                    checked={checked.includes(news.id) ? true : false}
                    onChange={(e) => checkHandler(e, news.id)}
                  />
                </span>
                <span>{pageNum * 10 + idx + 1}</span>
                <span onClick={() => openNewsModal(news.id)}>{news.titleKr}</span>
                <span>{news.writer}</span>
                <span>{news.region}</span>
                <span>{dateFormat(news.date).slice(0, 16)}</span>
                <span>{news.lastModifier ? dateFormat(news.updated_at).slice(0, 16) : ""}</span>
                <span>{news.lastModifier}</span>
                <span>{news.isActive ? "게시" : "미게시"}</span>
                <span onClick={() => modifyHandler(news.id)}>수정</span>
                <div style={{ display: "none" }}>{(rowId[idx] = news.id)}</div>
              </MainContent>
            ))}
        </ContentBox2>
      </TopContainer>

      {/* 페이지네이션 */}
      <AdminPagination baseUrl={"/admin-sketch/news/manage/"} totalPages={data.totalPages} />

      {/* 아래 부분 */}
      <BottomContainer>
        <DeleteContainer>
          <span>선택한 항목을</span>
          <DeleteTextBox ref={actionRef}>
            <option value="on">게시</option>
            <option value="off">미게시</option>
            <option value="delete">삭제</option>
          </DeleteTextBox>
          <BottomBtn onClick={() => multiManageHandler()}>적용</BottomBtn>
        </DeleteContainer>
        <BottomBtn onClick={() => nav("/admin-sketch/news/upload")}>작성하기</BottomBtn>
      </BottomContainer>
    </Wrapper>
  );
};

export default ManageAdmin;
