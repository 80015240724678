import axios, { AxiosError, AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { ADMIN_BASE_URL, BASE_URL } from "constants/pathConstant";

// axios 기본 옵션 설정
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 3000,
});

axiosInstance.interceptors.request.use(
  function (config: InternalAxiosRequestConfig<any>) {
    // request 전 공통 config 설정
    // multipart 인 경우는 미리 지정
    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    config.headers["Authorization"] = "";
    return config;
  },
  function (error: AxiosError) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  },
);
axiosInstance.interceptors.response.use(
  function (response) {
    // success response callback

    return response.data;
  },
  function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.
    */

    return Promise.reject(error);
  },
);

// 어드민용
export const axiosInstanceAdmin = axios.create({
  baseURL: ADMIN_BASE_URL,
  timeout: 3000,
});

// react-query 기본 옵션 설정
export const defaultOption = {
  // 다시 창에 돌아왔을때 재실행 여부 옵션
  refetchOnWindowFocus: true,
  // 실패시 재호출 실행 횟수
  retry: 1,
};

axiosInstanceAdmin.interceptors.request.use(
  function (config: InternalAxiosRequestConfig<any>) {
    // 헤더가 존재하지 않을 경우 초기화
    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json; charset=utf-8";
    }

    const accessToken = sessionStorage.getItem("accessToken");
    config.headers["Authorization"] = accessToken;
    return config;
  },
  function (error: AxiosError) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  },
);

axiosInstanceAdmin.interceptors.response.use(
  function (response) {
    // success response callback

    return response.data;
  },
  function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.
    */

    return Promise.reject(error);
  },
);
