import { useScrollFadeIn } from "hooks/useScrollFadeIn";
import { useRef } from "react";
import { Wrapper } from "./style";
import Com2 from "./components/Com2";

const NewsEvents = () => {
  const ref = useRef<HTMLDivElement>(null);
  useScrollFadeIn(ref);

  return (
    <Wrapper ref={ref} className="fade-in">
      <div>
        <Com2 />
      </div>
    </Wrapper>
  );
};

export default NewsEvents;
