import React, { useEffect, useId, useState } from "react";
import { SfuelLieStyle } from "./SfuelLie.style";

export const DetailLie = ({ totalInfo, setTotalInfo }) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState(0);

  const id = useId();

  const changeName = (e) => {
    setName(e.target.value);
  };
  const changeNumber = (e) => {
    setNumber(Number(e.target.value));
  };

  useEffect(() => {
    setTotalInfo({ ...totalInfo, [id]: [name, number] });
  }, [name, number]);

  return (
    <SfuelLieStyle.ColumnContainer>
      <input type="text" onChange={changeName} />
      <input type="number" onChange={changeNumber} />
    </SfuelLieStyle.ColumnContainer>
  );
};
