import { MOBILE_LAYOUT_LENGTH, PC_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";
export const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.isActive ? "none" : "flex")};
  justify-content: center;
  background-color: black;
  height: auto;
  z-index: 999;

  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) and (max-width: ${PC_LAYOUT_LENGTH}) {
    padding: 0 20px;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding: 0 10rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  @media screen and (max-width: ${PC_LAYOUT_LENGTH}) {
    padding: 0;
  }
  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 100%;
    height: 6rem;
    display: flex;
    padding: 0 20px;

    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 999;
    background-color: black;
  }
`;

export const Logo = styled.div`
  cursor: pointer;
  display: ${(props) => (props.isActive ? "none" : "flex")};
`;
export const LogoImg = styled.img`
  width: 10.5rem;
  height: 3.6rem;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 8.7rem;
    height: 3rem;
  }
`;

export const SideWrap = styled.div`
  display: none;

  gap: 1.5rem;
  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.isActive ? "100%" : "40%")};
    justify-content: ${(props) => (props.isActive ? "space-between" : "center")};
  }
`;

export const MenuBtn = styled.div`
  padding-top: 0.3rem;
  & > img {
    width: 2.4rem;
    height: 2.4rem;
  }

  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: none;
  }
  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: block;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.1rem;
  gap: 4.2rem;
  font-size: 2rem;
  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    display: flex;
    gap: 2.5rem;
  }
`;

export const AdminName = styled.div``;

export const Btn = styled.div`
  width: max-content;
  height: max-content;
  padding: 1rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  cursor: pointer;
`;
