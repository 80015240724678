import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";

export default function createReduxStore() {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
  });

  return store;
}
