import { FONTS } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100rem;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    height: auto;
    margin-top: 4rem;
  }
`;

export const Title = styled.div`
  @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 172rem;
    height: 14.5rem;
    border-radius: 3.2rem 3.2rem 0 0;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    & > p {
      text-align: center;
      font-family: ${FONTS.H};
      font-size: 6.4rem;
    }
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 31.6rem;
    height: 4.5rem;
    border-radius: 1.2rem 1.2rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1.3rem;
    z-index: 2;

    & > p {
      color: #fff;
      text-align: center;
      font-family: ${FONTS.H};
      font-size: 2rem;
      line-height: 1.5;
    }
  }
`;

export const Content = {
  Wrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
      height: 85rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,

  Title: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;
      font-family: ${FONTS.H};
      font-size: 6.4rem;
      text-align: center;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #fff;
      font-family: ${FONTS.H};
      font-size: 3.2rem;
      text-align: center;
      display: none;
    }
  `,

  Container: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      padding-top: 3rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  `,

  Box: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 36rem;
      height: 63rem;
      border-radius: 2.4rem;
      border: 1.2rem solid #B1E5E2;
      background: #f5f5f5;
      padding: 5rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      height: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; 
      padding: 0 2.5rem 3rem;
      margin: 0 0 4rem;
      width: 22rem;
      border-radius: 2.4rem;
      border: 1.2rem solid #B1E5E2;
      background: #f5f5f5;
    }
  `,

  BoxImg: styled.img`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 16rem;
      height: 13rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 8rem;
      height: 6rem;
      margin-top: 2rem;
    }
  `,

  BoxTitle: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #70BBE5;
      font-family: ${FONTS.S9};
      font-size: 3.6rem;
      margin-top: 4rem;
      text-align: center;
      width: 22rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 15rem;
      color: #70BBE5;
      text-align: center;
      font-family: ${FONTS.S9};
      font-size: 2.4rem;
      margin-top: 0.8rem;
      line-height: 3rem;
    }
  `,

  BoxContent: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #464646;
      font-family: ${FONTS.EB};
      font-size: 2.4rem;
      text-align: center;
      margin-top: 5rem;
      line-height: 3rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #464646;
      text-align: center;
      font-family: ${FONTS.EB};
      font-size: 1.4rem;
      margin-top: 3rem;
      line-height: 2rem;
    }
  `,
};

export const Arrow = {
  Wrapper: styled.div`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 100%;
      height: 10rem;
      display: flex;
      justify-content: center;
    }
  `,

  ArrowImg: styled.img`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 30rem;
      height: 30rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      width: 10rem;
      height: 10rem;
      transform: rotate(90deg) scaleX(-1);
    }
  `,
  ArrowText: styled.div<IStyle>`
    @media screen and (min-width: ${MOBILE_LAYOUT_LENGTH}) {
      color: #000;
      font-family: ${FONTS.S8};
      font-size: 4rem;
    }

    @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #000;
      text-align: center;
      font-family: ${FONTS.S8};
      font-size: 1.6rem;
      transform: ${(props) => (props.forward ? "rotate(90deg)" : "rotate(-90deg)")};
    }
  `,
};

interface IStyle {
  forward: boolean;
}
