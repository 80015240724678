import { createSlice } from "@reduxjs/toolkit";

export const SupportModalSlice = createSlice({
  name: "supportModal",

  initialState: {
    activeSupportModal: false,
    supportModalData: {
      title: "",
      created_at: "",
      category: "",
      content: "",
    },
  },

  reducers: {
    setSupportModal: (state, action) => {
      state.supportModalData = action.payload;
      state.activeSupportModal = true;
    },
    onSupportModal: (state) => {
      state.activeSupportModal = true;
    },
    offSupportModal: (state) => {
      state.activeSupportModal = false;
    },
    setSupportModalOnlyData: (state, action) => {
      state.supportModalData = action.payload;
    },
  },
});

export const { setSupportModal, onSupportModal, offSupportModal, setSupportModalOnlyData } = SupportModalSlice.actions;
export const selectActiveSupportModal = (state: { supportModal: { activeSupportModal: boolean } }) =>
  state.supportModal.activeSupportModal;
export const selectSupportModalData = (state: { supportModal: { supportModalData: IData } }) =>
  state.supportModal.supportModalData;

export default SupportModalSlice.reducer;

interface IData {
  title: string;
  created_at: string;
  category: string;
  content: string;
}
