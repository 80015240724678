import { BLACK_0, BLACK_3, MAIN_GREEN, WHITE } from "baseStyles";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  /* height: 100%; */
  overflow-y: scroll;
  width: 37.5rem;
  background: ${BLACK_0};
  padding: 3rem;
  margin-top: 20px;
  border: 2px solid gray;

  & > :nth-child(1) {
    display: flex;
    justify-content: end;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;

  & > div:nth-child(1) {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 4.2rem;
    color: #fff;
  }

  & > div:nth-child(2) {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: ${MAIN_GREEN};
  }
`;

export const LanguageSelector = styled.div`
  width: 9.6rem;
  height: 3rem;

  border: 1px solid ${BLACK_3};
  border-radius: 0.5rem;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const KOR = styled.span`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${(props) => (props.isKor ? `${BLACK_0}` : `${WHITE}`)};
  background-color: ${(props) => (props.isKor ? `${MAIN_GREEN}` : "")};
  font-weight: ${(props) => (props.isKor ? 500 : 300)};
  &:hover {
    color: ${(props) => (props.isKor ? `black` : MAIN_GREEN)};
  }
`;
export const ENG = styled.span`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${(props) => (!props.isKor ? `${BLACK_0}` : `${WHITE}`)};
  background-color: ${(props) => (!props.isKor ? `${MAIN_GREEN}` : "")};
  font-weight: ${(props) => (!props.isKor ? 500 : 300)};
  &:hover {
    color: ${(props) => (!props.isKor ? `black` : MAIN_GREEN)};
  }
`;
