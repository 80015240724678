import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  background: #000;
  z-index: 10000;
  border-radius: 0 2rem 2rem 0;
  overflow-y: auto;
`;
