import { Dispatch, SetStateAction } from "react";
import { Wrapper } from "./style";
import Com1 from "./components/Com1";
import Com2 from "./components/Com2";

const Sidebar = ({ setIsClick }: { setIsClick: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <Wrapper>
      <Com1 setIsClick={setIsClick} />
      <Com2 />
    </Wrapper>
  );
};

export default Sidebar;
