import { MAIN_GREEN } from "baseStyles";
import { MOBILE_LAYOUT_LENGTH } from "constants/UIConstants";
import styled from "styled-components";

export const Wrap = styled.div`
  position: absolute;
  justify-content: center;
  padding-top: 23rem;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 999999;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    padding-top: 15rem;
  }
`;

export const Wrapper = styled.div`
  width: 42.6rem;
  max-height: 63rem;

  background-color: black;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  position: absolute;

  & > div:nth-child(1) {
    width: 100%;
    height: 56rem;
  }
  & > div:nth-child(1) > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 33.5rem;
    height: 48rem;
    & > div:nth-child(1) {
      height: 41.2rem;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 7rem;
  background-color: #363636;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  color: #cccccc;

  & > span {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 3.2rem;
    cursor: pointer;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    height: 6.8rem;

    & > span {
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
  }
`;

export const TextBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.8rem;
  gap: 1.2rem;
  cursor: pointer;

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    gap: 1rem;
  }
`;

export const ImgBox = styled.span<IStyle>`
  width: 2.8rem;
  height: 2.8rem;
  /* background-color: black; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.bg ? MAIN_GREEN : "black")};
  & > img {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media screen and (max-width: ${MOBILE_LAYOUT_LENGTH}) {
    width: 2rem;
    height: 2rem;
    & > img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

export const LangBtn = styled.span`
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  text-align: center;
`;

interface IStyle {
  bg: boolean;
}
