import React from "react";

import { Wrapper } from "./term.style";
export const TermPersonWallet = () => {
  return (
    <Wrapper>
      <div class="head">개인정보처리방침</div>

      <div>
        비트세이프 주식회사(이하 '회사')는 고객의 개인정보 보호를 우선으로 생각하며, 「개인정보보호법」 및 관련 법령을
        엄격히 준수하고 있습니다.
      </div>
      <div>
        회사는 필요한 경우에만 최소한의 개인정보를 수집하며, 수집된 개인정보는 고지된 목적 범위 내에서만 활용하고, 해당
        범위를 초과해 이용하거나 외부에 노출하지 않습니다.
      </div>

      <div class="jang">1. 개인정보의 수집 및 이용</div>
      <div class="jo">1) 수집 및 이용 목적 목적</div>

      <div>
        회사는 아래의 목적으로 개인정보를 수집합니다. 수집된 개인정보는 아래 목적 외의 다른 용도로는 활용되지 않으며,
        활용 목적에 변동이 발생할 경우 '개인정보보호법' 제18조에 따라 필요한 절차를 밟아 진행하겠습니다.
      </div>
      <div>① 이용자 문의 및 불만 처리 등 이용자 관리</div>
      <div>② 앱 서비스 제공 및 이용내역 제공 등</div>
      <div>
        ③ 법령 및 이용 약관 위반자에 대한 조치, 부정 행위 방지, 계정 도용 및 거래 사기 예방, 고객에게 중요한 정보 전달,
        분쟁의 조정 기록 유지 등 이용자 보호 및 서비스 운영
      </div>
      <div>④ 이벤트 제공 및 마케팅 활용</div>
      <div>⑤ 보안 정책 수립을 위한 부정 활동 방지</div>

      <div class="jo">2) 수집 ∙ 이용하는 개인정보 항목 및 목적</div>
      <div>
        회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보
        보유·이용기간 내에서 서비스의 제공을 위하여 다음과 같은 정보를 수집하고 있습니다.
      </div>

      <table>
        <th>구분</th>
        <th>개인정보 수집·이용 목적</th>
        <th>수집하는 개인정보 항목</th>
        <th>개인정보 보유 및 이용기간</th>
        <tr>
          <td rowspan="3">필수</td>
          <td>민원응대 및 부정이용 방지</td>
          <td>서비스 이용 기록, 불량 혹은 비정상 이용 기록, 접속 로그, 접속 IP, 단말기 정보</td>
          <td>서비스 해지 시까지</td>
        </tr>
        <tr>
          <td rowspan="2">앱 서비스 이용</td>
          <td>
            이름, 휴대전화번호, 이메일주소, 회원코드, 추천인코드, 스케치월렛 지갑 주소, 서비스 접속 정보(접속기록,
            접속IP)
          </td>
          <td>이용목적 달성 시까지</td>
        </tr>
        <tr>
          <td>거래내역</td>
          <td>
            건당 1만원 초과 : 5년 <br />
            건당 1만원 이하 : 1년
          </td>
        </tr>
        <tr>
          <td rowspan="2">선택</td>
          <td>서비스 이용과정에서 생성</td>
          <td>쿠키</td>
          <td>동의철회 시 까지</td>
        </tr>
        <tr>
          <td>이벤트 및 마케팅</td>
          <td>[제세공과금 납부 시] 신분증 사본, 이메일, 휴대전화번호</td>
          <td>이용목적 달성 시까지</td>
        </tr>
      </table>

      <div class="jo">3) 개인정보 수집 방법</div>

      <div>
        회사는 다음과 같은 방법으로 개인정보를 수집합니다 이용자의 서비스 이용 중 추가적인 정보를 수집할 수 있으며, 이
        경우 별도의 개인정보 수집, 이용 동의를 받습니다.
      </div>

      <div>① 서비스 회원 가입 시 회원이 직접 제공</div>
      <div>② 본인확인 업무 시 회원이 직접 제공</div>
      <div>③ 오프라인 행사나 이벤트를 통한 서면 제출</div>
      <div>④ 이벤트 및 프로모션 등 서비스 제휴사 또는 개인정보 처리업무 수탁사로부터의 제공</div>
      <div>⑤ 서비스 이용 중 자동으로 생성 수집 (서비스 이용 및 처리 과정에서 자동으로 생성되는 정보에 한함)</div>
      <div>⑥ 기타 법령 준수를 위해 필요한 정보 수집</div>

      <div class="jo">4) 회사는 이용자의 기본적인 인권을 침해할 가능성이 있는 민감한 개인정보는 수집하지 않습니다.</div>

      <div class="jang">2. 개인정보 처리 제3자 제공 및 위탁</div>

      <div>
        ① 원칙적으로, 회사는 이용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 특별한 법률적 규정이 있거나 이용자의
        명시적인 동의가 있을 때만 개인정보를 제3자에게 제공합니다. 개인정보의 제3자 제공 시에는 이용자에게 법률 사항을
        사전에 명시적으로 고지하고 고객의 동의를 받는 것을 원칙으로 합니다.
      </div>

      <div class="jang">3. 정보주체의 권리, 의무 및 그 행사방법</div>

      <div>이용자는 다음과 같이 개인정보주체로서의 권한을 행사할 수 있습니다.</div>

      <div>
        ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. <br />
        하지만, 개인정보 보호법 제35조 제4항, 제36조 제1항, 제37조 제2항 등 관계 법령에서 정하는 바에 따라 이용자의
        개인정보 열람·정정·삭제·처리정지 요구 등의 권리 행사가 제한될 수 있습니다.
      </div>
      <div>- 개인정보 열람요구</div>
      <div>- 오류 등이 있을 경우 정정요구</div>
      <div>- 삭제요구</div>
      <div>- 처리정지 요구</div>

      <div>
        ② 제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편 등을 통해
        회사에 요청하실 수 있으며, 회사는 이를 받은 즉시 필요한 조치를 취하겠습니다.
      </div>
      <div>
        ③ 정보주체가 개인정보의 오류에 대한 수정 또는 삭제를 요청한 경우, 해당 조치를 완료하기 전까지 해당 정보의 활용
        또는 제공을 중단합니다.
      </div>
      <div>
        ④ 제1항에 따른 권리 행사는 정보주체의 법적 대리인이나 위임받은 대리인을 통해 행사될 수 있습니다. 이 경우
        개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.
      </div>
      <div>
        ⑤ 개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 삭제를 요청할
        수 없습니다.
      </div>

      <div class="jang">4. 개인정보의 파기</div>

      <div class="jo">
        1) 회사는 개인정보의 수집 및 이용 목적이 달성되었거나, 이용자로부터 동의 받은 보유 및 이용기간이 경과, 처리목적
        달성 등 개인정보가 불필요하게 되었을 때에는 해당 정보를 지체없이 파기합니다.
      </div>

      <div>① 정보 별 보유기간</div>

      <table>
        <th>구분</th>
        <th>관련 법률</th>
        <th>보유 기간</th>
        <tr>
          <td>전자금융거래기록</td>
          <td>전자금융거래법</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>계약 또는 청약철회 등에 관한 기록</td>
          <td rowspan="5">전자상거래 등에서의 소비자보호에 관한 법률</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>대금결제 및 재화 등의 공급에 관한 기록</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
          <td>3년</td>
        </tr>
        <tr>
          <td>표시·광고에 관한 기록</td>
          <td>6개월</td>
        </tr>
        <tr>
          <td>보상, 오입금에 관한 기록</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>제세공과금 납부 기록</td>
          <td>국세기본법</td>
          <td>5년</td>
        </tr>
        <tr>
          <td>상속업무 처리 기록</td>
          <td>상속세 및 증여세법</td>
          <td>9개월</td>
        </tr>
        <tr>
          <td>서비스 이용 관련 개인정보(로그인 기록)</td>
          <td>통신비밀보호법</td>
          <td>3개월</td>
        </tr>
      </table>

      <div>
        ② 그 외 이용자의 민원사무 처리에 관한 기록(성명, 휴대폰번호, 민원내용 등)은 회사 내부 정책에 따라 이용자의
        동의를 얻어 3년 동안 보유합니다.
      </div>

      <div class="jo">2) 파기절차</div>
      <div>
        - 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호 책임자의 승인을 받아 개인정보를
        파기합니다. 즉시 파기되는 경우 이외에는 수집∙이용 목적이 달성된 정보의 경우 별도 분리하여 내부규정 및 관련
        법령을 준수하여 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 안전하게 보관되며, 정해진
        기간이 종료되었을 때 지체 없이 파기됩니다. 이 때, 별도 분리된 정보는 이용자 각자가 동의한 목적을 초과하거나
        법률에 정한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. 다른 법률에 따라 개인정보를 파기하지 않고
        보존하는 경우에 따른 구체적 법률 근거는 본 방침의 4. 개인정보 파기 1) ① 정보 별 보유 기간에 따릅니다.
      </div>
      <div class="jo">3) 파기방법</div>
      <div>
        - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법(파일 삭제 및 디스크 등 저장매체 포맷
        등)을 사용하여 삭제합니다.
      </div>
      <div>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</div>

      <div class="jang">5. 이용자 및 법정대리인의 권리와 그 행사방법</div>

      <div class="jo">
        1) 이용자와 법정대리인, 그리고 위임받은 대리인은 언제든지 본인의 개인정보의 열람, 정정, 삭제, 처리정지를 요청할
        수 있고, 이에 대해 회사는 지체 없이 필요한 조치를 취합니다. 또한, 회사는 정정 요구가 있을 경우 그 정정 때까지
        당해 개인정보를 이용하지 않으며, 폐기 요구가 있을 경우에는 즉각 삭제하여 어떠 한 용도로도 사용할 수 없도록
        합니다. 정보주체의 법정대리인이나 위임을 받은 자는 개인정보보호 법규에서 정하는 “개인정보 처리 방법에 관한 고시”
        별지 제11호 서식에 따른 위임장을 제출하여야 합니다.
      </div>
      <div class="jo">
        2) 권리 행사를 위해 이용자와 법정대리인은 회사의 개인정보 관리책임자에게 서면, 전화, 이메일 또는 방문을 통해
        요청할 수 있습니다. 회사는 이러한 요청에 즉시 대응합니다.
      </div>
      <div class="jo">
        3) 회사는 정정 또는 삭제 요청에 따라 변경된 개인정보를 안전하게 처리하며, 해당 정보를 임의로 열람하거나 사용하지
        않습니다.
      </div>
      <div class="jo">
        4) 회사는 1)항에도 불구하고 이용자 및 법정대리인의 개인정보 열람, 정정, 삭제, 처리정지의 요구는
        「개인정보보호법」 제35조제4항, 제37조제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
      </div>
      <div class="jo">
        5) 개인정보의 정정 및 삭제요구는 다른 법령에서 그 개인정보가 수집대상으로 명시되어 있는 경우에는 그 삭제를
        요구할 수 없습니다.
      </div>
      <div class="jo">
        6) 개인정보 처리자는 이용자의 권리 요청(열람, 정정, 삭제, 처리정지 등)에 대응할 때, 요청자가 본인 혹은 정당한
        대리인인지 확인하는 절차를 진행합니다.
      </div>

      <div class="jang">6. 개인정보 안전성 확보 조치</div>

      <div>회사는 개인정보의 안전성을 확보하기 위한 다음과 같은 조치를 취하고 있습니다.</div>

      <div class="jo">1) 관리적 조치</div>

      <div>
        개인정보를 안전하게 취급하기 위한 내부관리계획을 마련하여 임직원이 이를 숙지하고 준수하도록 하고 있으며, 준수
        여부를 주기적으로 점검합니다.
      </div>
      <div>① 개인정보보호책임자 및 개인정보보호관리자 등 개인정보 관리 및 개인정보보호 업무 담당자 지정</div>
      <div>
        ② 개인정보보호 의무 등에 관해 정기적인 사내 교육과 외부 위탁교육을 통하여 법규 및 정책을 준수할 수 있도록 마련,
        내부관리계획의 수립 및 시행
      </div>
      <div>③ 개인정보취급자의 퇴사 즉시 개인정보 침해사고 예방을 위한 퇴직자 보안점검을 준수</div>

      <div class="jo">2) 기술적 조치</div>
      <div>
        개인정보를 취급할 때 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 다음과 같은 기술적 대책을 마련하여
        개인정보의 안전성을 확보하고 있습니다.
      </div>
      <div>
        ① 국내 법령이 요구하는 수준의 암호화 저장 또는 네트워크 상 개인정보를 안전하게 전송 시 암호화(SSL/TLS 등) 기술
        적용
      </div>
      <div>
        ② 백신 프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치 - 해킹 등 외부 침입에 대비하여
        침입차단시스템, 침입방지시스템, 서비스거부방지(DDOS)시스템 등을 적용
      </div>
      <div>
        ③ 각 서버 및 중요 데이터베이스의 내부정보유출방지를 위한 접근제어시스템을 운영하고, 내부 임직원 사용자 PC의
        개인정보검색, 암호화, 출력물 로깅 기록 감사 등을 적용
      </div>
      <div>
        ④ 개인정보취급자가 개인정보처리시스템에 접속한 기록의 보관∙ 관리, 개인정보의 오∙ 남용, 분실, 위조∙ 변조 등을
        방지하기 위한 정기적인 사용자 접속기록 검토 및 안전한 보관 기술 적용
      </div>

      <div class="jo">3) 물리적 조치</div>
      <div>① 전산실과 자료 보관실 등을 특별 관리구역으로 지정하여 출입을 엄격하게 통제</div>
      <div>② 개인정보 저장 및 보관 장소에 CCTV, 출입통제시스템 등을 설치하여 보안을 강화</div>

      <div class="jang">7. 개인정보에 관한 민원서비스</div>

      <div class="jo">
        1) 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및
        개인정보보호책임자를 지정하고 있습니다. 이용자는 서비스를 이용하면서 발생한 모든 개인정보 보호 관련 문의,
        불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 아래 기재된 곳으로
        연락 시 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
      </div>

      <table>
        <tr>
          <th rowspan="2">개인정보보호책임자</th>

          <td>이름</td>
          <td>강청</td>
        </tr>
        <tr>
          <td>연락처</td>
          <td>02-6949-2817</td>
        </tr>
      </table>

      <div class="jo">2) 기타 기관</div>
      <div>
        이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로
        신고하실 수 있습니다. 회사는 이용자의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에
        대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
      </div>
      <div>① 개인정보침해 신고센터 (privacy.kisa.or.kr / 국번없이 118)</div>
      <div>② 개인정보 분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)</div>
      <div>③ 대검찰청 (www.spo.go.kr / 국번없이 1301)</div>
      <div>④ 경찰청 사이버 수사국 (cyberbureau.police.go.kr / 국번없이 182)</div>

      <div class="jang">8. 이용자 유의 사항</div>
      <div class="jo">
        1) 이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한
        부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인의 정보를 무단으로 사용하거나 허위 정보를
        입력할 경우 서비스 제한의 조치를 받을 수 있습니다.
      </div>
      <div class="jo">
        2) 이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고
        있습니다. 특히 비밀번호를 포함한 이용자의 개인정보가 유출되지 않도록 조심하시기 바랍니다.
      </div>

      <div class="jang">9. 본 개인정보 처리방침의 적용 범위</div>

      <div class="jo">1) 본 개인정보 처리방침은 회사에서 제공하는 모든 서비스에 적용됩니다.</div>
      <div class="jo">
        2) 회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있으나 제3자의 웹사이트, 자료, 상품
        및 서비스에 대한 권한이 없으므로 링크된 웹 사이트로부터 제공받은 상품 및 서비스나 자료의 유용성에 대하여 책임 및
        보증할 수 없습니다.
      </div>
      <div class="jo">
        3) 회사가 포함하고 있는 링크를 클릭하여 다른 회사의 웹 사이트로 이동할 경우 해당 사이트의 개인정보 보호정책이
        적용됩니다.
      </div>

      <div class="jang">10. 개인정보처리방침의 변경</div>

      <div>
        회사는 개인정보처리방침을 공개함으로써 이용자가 언제나 쉽게 편하게 확인할 수 있도록 하고 있습니다. <br />
        회사는 개인정보처리방침을 관련 법령 또는 내부 사정에 따라 개정할 수 있으며, 개정하는 경우 개정된 사항을 이용자가
        수시로 확인할 수 있도록 게시합니다.
      </div>
      <div>
        본 개인정보 처리방침은 회사 홈페이지에서 확인할 수 있습니다. <br />본 개인정보 처리방침은 2023년 8월 24일부터
        시행됩니다.
      </div>
    </Wrapper>
  );
};
