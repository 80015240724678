import { createSlice } from "@reduxjs/toolkit";

export const NoticeModalSlice = createSlice({
  name: "noticeModal",

  initialState: {
    activeNoticeModal: false,
    noticeAdminData: {
      id: "",
      end_at: "",
      img_path: "",
      is_available: "",
      title: "",
      url: "",
    },
  },

  reducers: {
    setAdminNoticeData: (state, actions) => {
      state.noticeAdminData = actions.payload;
    },
    onNoticeModal: (state) => {
      state.activeNoticeModal = true;
    },
    offNoticeModal: (state) => {
      state.activeNoticeModal = false;
    },
  },
});

export const { setAdminNoticeData, onNoticeModal, offNoticeModal } = NoticeModalSlice.actions;
export const selectActiveNoticeModal = (state: { noticeModal: { activeNoticeModal: boolean } }) =>
  state.noticeModal.activeNoticeModal;
export const selectAdminNoticeData = (state: { noticeModal: { noticeAdminData: IData } }) =>
  state.noticeModal.noticeAdminData;
export default NoticeModalSlice.reducer;

interface IData {
  id: string;
  end_at: string;
  img_path: string;
  is_available: string;
  title: string;
  url: string;
}
