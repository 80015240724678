import { axiosInstanceAdmin } from "api";
import Loading from "components/Loading";
import { useNavigatePage } from "hooks/useNavigatePage";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { logoutAdmin } from "redux/reducers/adminReducer";
import {
  BottomBtn,
  BottomContainer,
  ContentBox1,
  ContentBox2,
  ContentNav,
  DeleteContainer,
  DeleteTextBox,
  MainContent,
  TextBox,
  TopContainer,
  Wrapper,
} from "./style";

import { useLoginCheck } from "hooks/useLoginCheck";
import { useParams } from "react-router";
import AdminPagination from "../AdminPagination";
const ManageAdmin = () => {
  const nav = useNavigatePage();
  const dispatch = useDispatch();
  // 로그인 체크
  const loginCheck = useLoginCheck();
  useEffect(() => {
    loginCheck();
  }, []);

  // ------------------------ pagination
  const { page } = useParams();
  const [pageNum, setPageNum] = useState(0);
  useEffect(() => {
    if (page < 0) {
      nav("/admin-sketch/admins/manage/0");
      return;
    }
    setPageNum(page);
  }, [page]);

  const { data, isSuccess, isLoading, isError } = useQuery("manage_admin" + page, () =>
    axiosInstanceAdmin.get("", { params: { page, size: 10 } }),
  );

  useEffect(() => {
    if (!data) return;
    if (parseInt(page) + 1 > data.totalPages && page !== "0") {
      nav(`/admin-sketch/admins/manage/${data.totalPages - 1}`);
    }
  }, [data]);

  if (isError) {
    dispatch(logoutAdmin());
    alert("새로 고침 후 로그인을 다시 해주세요");
    nav("/admin-sketch");
  }

  // ------------------------ check Handler
  const [checked, setChecked] = useState(new Array(10).fill().map(() => [0, false]));
  const [rowId, setRowId] = useState([]);
  const allCheckRef = useRef();

  const allCheckHandler = (e) => {
    for (let i = 0; i < checked.length; i++) {
      checked[i][1] = e.target.checked ? true : false;
      checked[i][0] = rowId[i];
    }

    setChecked([...checked]);
  };

  const checkHandler = (e, idx) => {
    if (!e.target.checked) allCheckRef.current.checked = false;
    checked[idx][1] = e.target.checked ? true : false;
    checked[idx][0] = rowId[idx];
    setChecked([...checked]);
  };

  // --------------------- data query
  const queryClient = useQueryClient();
  const { mutate } = useMutation((data) => axiosInstanceAdmin.patch("/active", { data }), {
    onSuccess: () => {
      queryClient.invalidateQueries("manage_admin" + page);
      allCheckRef.current.checked = false;
      setChecked(new Array(10).fill().map(() => [0, false]));
    },
    onError: () => alert("적용 실패 - 개발자에게 문의하세요."),
  });

  const actionRef = useRef(null);
  const multiManageHandler = () => {
    const temp = [...checked]
      .filter((v) => v[1] && v !== undefined)
      .map((v) => {
        return { id: [0], isActive: actionRef.current.value };
      });

    manageHandler(...temp);
  };

  const manageHandler = (...args) => {
    console.log(args);

    if (window.confirm("확정하시겠습니까?")) mutate(args);
  };

  if (isLoading) return <Loading />;

  return (
    <Wrapper>
      {/* 상단 */}
      <TopContainer>
        {/* 맨위 이름, 검색 */}
        <ContentBox1>
          <TextBox>Admin Manage</TextBox>
          {/* <SearchBox>
            <SearchSelect>
              <option value="">이름</option>
              <option value="">ID</option>
            </SearchSelect>
            <SearchInput />
            <SearchBtn>검색</SearchBtn>
          </SearchBox> */}
        </ContentBox1>
        {/* 내용 */}
        <ContentBox2>
          <ContentNav>
            <span>
              <input type="checkbox" ref={allCheckRef} onChange={(e) => allCheckHandler(e)} />
            </span>
            <span>번호</span>
            <span>아이디</span>
            <span>이름</span>
            <span>신청날짜</span>
            <span>승인</span>
            <span>거절</span>
          </ContentNav>
          {data.content.map((admin, idx) => (
            <MainContent key={admin.id} checked={checked[idx][1]}>
              <span>
                <input type="checkbox" checked={checked[idx][1]} onChange={(e) => checkHandler(e, idx)} />
              </span>
              <span>{pageNum * 10 + idx + 1}</span>
              <span>{admin.adminId}</span>
              <span>{admin.name}</span>
              <span>{admin.created_at}</span>
              <span onClick={() => manageHandler({ id: admin.id, isActive: true })}>승인</span>
              <span onClick={() => manageHandler({ id: admin.id, isActive: false })}>거절</span>
              <div style={{ display: "none" }}>{(rowId[idx] = admin.id)}</div>
            </MainContent>
          ))}
        </ContentBox2>
      </TopContainer>
      {/* 페이지네이션 */}

      <AdminPagination baseUrl={"/admin-sketch/admins/manage/"} totalPages={data.totalPages} />
      {/* 아래 부분 */}
      <BottomContainer>
        <DeleteContainer>
          <span>선택한 항목을</span>
          <DeleteTextBox ref={actionRef}>
            <option value={true}>승인</option>
            <option value={false}>거절</option>
          </DeleteTextBox>
          <BottomBtn onClick={() => multiManageHandler()}>적용</BottomBtn>
        </DeleteContainer>
        {/* <BottomBtn>작성하기</BottomBtn> */}
      </BottomContainer>
    </Wrapper>
  );
};

export default ManageAdmin;
