import { axiosInstance } from "api";
import Loading from "components/Loading";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setNewsModal } from "redux/reducers/newsModalReducer";
import useDeleteStyle from "hooks/useDeleteStyle";
import { useParams } from "react-router";
import {
  BottomContainer,
  ContentBox,
  ImgBox1,
  TextBox,
  TextBox2,
  TopContainer,
  TopFirstBox,
  TopImgBox,
  TopSecondBox,
  Wrapper,
} from "./style";
import { useMediaQuery } from "react-responsive";
import Pagination from "components/Pagination";
import { selectLanguage } from "redux/reducers/languageReducer";
import { IMG_CDN_URL } from "constants/pathConstant";
import { dateFormat } from "components/dateFormat";
import { IContent, INews } from "./types";

const Com2 = () => {
  const dispatch = useDispatch();
  const { page } = useParams<{ page: string }>();
  const language = useSelector(selectLanguage);
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });

  const {
    data: newsData,
    isSuccess,
    isLoading,
  } = useQuery<any>(["news" + page], () => axiosInstance.get("/news?page=" + page + "&size=12"));

  const typedNewsData = newsData as INews;

  const { data: latestNewsData } = useQuery<any>(["news" + 0], () => axiosInstance.get("/news?page=0&size=3"));

  const typedLatestNewsData = latestNewsData as INews;

  const openNewsModal = (id: number, latest: boolean = false) => {
    const dataSource = latest ? latestNewsData.content : newsData.content;
    const selectedData = dataSource.find((v: IContent) => v.id === id);

    dispatch(setNewsModal(selectedData));
  };

  // HTML 태그 포맷
  const deleteStyle = useDeleteStyle();

  if (isLoading) return <Loading />;

  return (
    <Wrapper>
      {isSuccess && (
        <>
          {isPc && (
            <TopContainer>
              {/* Top 컨텐츠들 */}
              {typedLatestNewsData.content[0] &&
                typedLatestNewsData.content.slice(0, 1).map((v) => (
                  <TopFirstBox onClick={() => openNewsModal(v.id, true)} key={v.id}>
                    <TopImgBox>
                      <img
                        loading="lazy"
                        src={`${IMG_CDN_URL}/${language === "en" ? v.imgPathEng : v.imgPathKr}`}
                        alt="newsImage"
                      />
                    </TopImgBox>
                    <TextBox>
                      <span>{language === "en" ? v.titleEng : v.titleKr}</span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: deleteStyle(language === "en" ? v.contentEng : v.contentKr),
                        }}
                      ></span>
                      <div>
                        <div>{dateFormat(v.date, ".").slice(0, 10)}</div>
                        <div>|</div>
                        <div>{dateFormat(v.date, ".").slice(11, 16)}</div>
                        <div>|</div>
                        <div>{v.region}</div>
                      </div>
                    </TextBox>
                  </TopFirstBox>
                ))}
              <TopSecondBox>
                {typedLatestNewsData.content[1] &&
                  typedLatestNewsData.content.slice(1, 3).map((v) => (
                    <ContentBox key={v.id} onClick={() => openNewsModal(v.id, true)}>
                      <ImgBox1>
                        <img
                          loading="lazy"
                          src={`${IMG_CDN_URL}/${language === "en" ? v.imgPathEng : v.imgPathKr}`}
                          alt="newsImage"
                        />
                      </ImgBox1>
                      <TextBox2>
                        <span>{language === "en" ? v.titleEng : v.titleKr}</span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: deleteStyle(language === "en" ? v.contentEng : v.contentKr),
                          }}
                        ></span>
                        <span>
                          <div>{dateFormat(v.date, ".").slice(0, 10)}</div>
                          <div>|</div>
                          <div>{dateFormat(v.date, ".").slice(11, 16)}</div>
                          <div>|</div>
                          <div>{v.region}</div>
                        </span>
                      </TextBox2>
                    </ContentBox>
                  ))}
              </TopSecondBox>
            </TopContainer>
          )}
          {/* Bottom 컨텐츠들 */}
          <BottomContainer>
            {typedNewsData.content &&
              typedNewsData.content.map((v) => (
                <ContentBox key={v.id} onClick={() => openNewsModal(v.id)}>
                  <ImgBox1>
                    <img
                      loading="lazy"
                      src={`${IMG_CDN_URL}/${language === "en" ? v.imgPathEng : v.imgPathKr}`}
                      alt="newsImage"
                    />
                  </ImgBox1>
                  <TextBox2>
                    <span>{language === "en" ? v.titleEng : v.titleKr}</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: deleteStyle(language === "en" ? v.contentEng : v.contentKr),
                      }}
                    ></span>
                    <span>
                      <div>{dateFormat(v.date, ".").slice(0, 10)}</div>
                      <div>|</div>
                      <div>{dateFormat(v.date, ".").slice(11, 16)}</div>
                      <div>|</div>
                      <div>{v.region}</div>
                    </span>
                  </TextBox2>
                </ContentBox>
              ))}
          </BottomContainer>
          {/* Pagination */}

          {typedNewsData.totalPages > 1 && <Pagination totalPages={typedNewsData.totalPages} url={"/news_events/"} />}
        </>
      )}
    </Wrapper>
  );
};

export default Com2;
