import { useState } from "react";
import { Viewer, LanguageSelector, KOR, ENG } from "./PreviewNewsPc.style";
import { dateFormat } from "components/dateFormat";
const PreviewNewsPc = ({ title, date, region, imgSrc, htmlString, imgSrcKr, htmlStringKr, titleKr }) => {
  const [isKor, setIsKor] = useState(true);

  const changeLanguageHandler = () => {
    setIsKor((prev) => !prev);
  };

  return (
    <Viewer>
      <div>
        <LanguageSelector onClick={changeLanguageHandler}>
          <KOR isKor={isKor}>KOR</KOR>
          <ENG isKor={isKor}>ENG</ENG>
        </LanguageSelector>
      </div>
      <div>{isKor ? titleKr : title}</div>
      <div>
        <div>{dateFormat(date, ".", 0).substring(0, 10)}</div>
        <div>|</div>
        <div>{dateFormat(date, ".", 0).substring(11, 16)}</div>
        <div>|</div>
        <div>{region}</div>
      </div>
      <div>
        <img loading="lazy" src={isKor ? imgSrcKr : imgSrc} alt="" id="preview" />
      </div>
      <div dangerouslySetInnerHTML={{ __html: isKor ? htmlStringKr : htmlString }}></div>
    </Viewer>
  );
};

export default PreviewNewsPc;
