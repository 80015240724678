import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",

  initialState: {
    isLogin: false,
    loginUser: {
      userAddress: "",
      userNickname: "",
    },
  },

  reducers: {
    loginAndSetUser: (state) => {
      state.isLogin = true;
    },
    logout: (state) => {
      state.isLogin = false;
    },
  },
});

export const { loginAndSetUser, logout } = userSlice.actions;

export const selectIsLogin = (state: { user: { isLogin: boolean } }) => state.user.isLogin;
export default userSlice.reducer;
