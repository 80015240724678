import React, { useState } from "react";
import { Container, InputContainer, LoginBtn, Wrapper } from "./style";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { loginAndSetAdmin } from "redux/reducers/adminReducer";
import { useMutation } from "react-query";
import { axiosInstance } from "api";
const Login = () => {
  const [id, setId] = useState(null);
  const [pw, setPw] = useState(null);
  const nav = useNavigate();
  const dispatch = useDispatch();

  const { mutate } = useMutation((data) => axiosInstance.post("/admins/login", data), {
    onSuccess: (data) => {
      dispatch(loginAndSetAdmin(data));
      nav("/admin-sketch");
    },
    onError: () => alert("관리자에게 문의하세요."),
  });

  const loginHandler = () => {
    const data = { adminId: id, password: pw };
    mutate(data);
  };

  const enterHandler = (e) => {
    if (e.keyCode === 13) loginHandler();
  };
  return (
    <Wrapper>
      <Container>
        <InputContainer>
          <div>ID</div>
          <input type="text" onKeyUp={(e) => enterHandler(e)} onChange={(e) => setId(e.target.value)} />
        </InputContainer>
        <InputContainer>
          <div>PW</div>
          <input type="password" onKeyUp={(e) => enterHandler(e)} onChange={(e) => setPw(e.target.value)} />
        </InputContainer>
        <LoginBtn onClick={loginHandler}>Login</LoginBtn>
        <LoginBtn onClick={() => nav("/admin-sketch/signup")}>아이디 신청</LoginBtn>
      </Container>
    </Wrapper>
  );
};

export default Login;
