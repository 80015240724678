import React from "react";
import { Wrapper } from "./term.style";

export const TermMarketingPay = () => {
  return (
    <Wrapper style={{ minHeight: "100vh" }}>
      <div class="head">마케팅 수신 동의</div>

      <div>
        주식회사 비트세이프 (이하 '회사')는 회사가 제공하는 '스케치월렛 및 스케치페이 통합 플렛폼' (이하 ‘스케치
        플랫폼’)회원들께 보다 좋은 서비스를 제공하기 위해 광고, 마케팅 정보 수신에 대 한 동의를 받고자 합니다.
      </div>
      <div>회원은 본 동의로써 ‘스케치 플랫폼’ 서비스 관련 광고, 홍보, 프로모션에 대한 정보를 수신하게 됩니다.</div>
      <div>
        아래 내용을 확인하시고 동의를 원하시면 앱 내 마케팅 수신 동의 화면의 안내에 따라 "동의"여 부를 체크해주시기
        바랍니다.
      </div>

      <table>
        <tr>
          <td>수집 목적</td>
          <td>서비스에 대한 광고, 홍보, 프로모션 제공</td>
        </tr>
        <tr>
          <td>수집 항목</td>
          <td>이름, 휴대전화번호, 이메일, 기기, UUID, PUSH 토큰</td>
        </tr>
        <tr>
          <td>보유 및 이용기간</td>
          <td>동의 철회 시 또는 회원 탈퇴 시까지</td>
        </tr>
      </table>

      <div>[동의거부 및 관련 안내사항]</div>

      <ul>
        <li>
          본 광고, 마케팅 수신 동의는 선택적 동의사항으로, 회원은 동의를 거부할 수 있습니다. 동의를 거부하더라도 ‘스케치
          플랫폼’ 서비스를 이용하는 데는 영향이 없습니다. 다만 동의를 거부할
        </li>
        <li>본 광고 마케팅 수신 동의는 ‘스케치 플랫폼’ 앱 내 “설정”을 통해 언제든지 철회할 수 있습니 다.</li>
        <li>본 동의를 유지하는 경우 2년마다 광고, 마케팅 수신 동의 상태를 알려드립니다.</li>
      </ul>
    </Wrapper>
  );
};
