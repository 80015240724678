import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { BtnWrapper, Page, PageWrapper, PaginationWrapper } from "./style";

const AdminPagination = ({ baseUrl, pagingSize = 10, totalPages }) => {
  const nav = useNavigate();
  const { page } = useParams();

  const [pageIndex, setPageIndex] = useState<number>(Math.floor(Number(page) / pagingSize));
  const [pageLength, setPageLength] = useState<number>(0);

  useEffect(() => {
    setPageLength(totalPages);
  }, [totalPages]);

  const navHandler = (to: number) => {
    nav(baseUrl + to);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 페이지 이동 ( < | > 누르는 경우)
  const pageIndexHandler = (dir: string) => {
    if (dir === "up") {
      if (pageIndex + 1 > pageLength / 10) return;
      nav(baseUrl + (pageIndex + 1) * 10);
      setPageIndex(pageIndex + 1);
    } else {
      if (pageIndex - 1 < 0) return;
      nav(baseUrl + (pageIndex - 1) * 10);
      setPageIndex(pageIndex - 1);
    }
  };

  // 끝 페이지 이동 ( << | >> 누르는 경우)
  const endPageHandler = (dir: string) => {
    if (dir === "up") {
      if (pageIndex + 1 > pageLength / 10) return;
      nav(baseUrl + Math.floor(pageLength / 10) * 10);
      setPageIndex(Math.floor(pageLength / 10));
    } else {
      if (pageIndex - 1 < 0) return;
      nav(baseUrl + 0);
      setPageIndex(0);
    }
  };

  const tempArr = new Array(pageLength).fill(0);

  return (
    <PaginationWrapper>
      <BtnWrapper pageLength={pageLength}>
        <span onClick={() => endPageHandler("down")}>{"<<"}</span>
        <span onClick={() => pageIndexHandler("down")}>{"<"}</span>
      </BtnWrapper>
      <PageWrapper>
        {tempArr
          .map((v, idx) => (
            <Page key={idx} onClick={() => navHandler(idx)} isSelected={page === idx.toString()}>
              {idx + 1}
            </Page>
          ))
          .slice(pageIndex * 10, (pageIndex + 1) * 10)}
      </PageWrapper>
      <BtnWrapper pageLength={pageLength}>
        <span onClick={() => pageIndexHandler("up")}>{">"}</span>
        <span onClick={() => endPageHandler("up")}>{">>"}</span>
      </BtnWrapper>
    </PaginationWrapper>
  );
};

export default AdminPagination;
