import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ko, nameSpace } from "./ko";
import { en } from "./en";

export const option = {
  resources: {
    en,
    ko,
  },
  lng: "en",
  fallbackLng: "ko",
  ns: nameSpace,
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(option);

export default i18n;
