const now = new Date();
const localTimezoneOffset = (now.getTimezoneOffset() / 60) * -1;

export const dateFormat = (date?: Date | string, symbol = "-", time = localTimezoneOffset) => {
  const currentDate = date ? new Date(date) : new Date();
  currentDate.setHours(currentDate.getHours() + time);

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}${symbol}${month}${symbol}${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
